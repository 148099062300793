import styled from "styled-components";
import { Button } from "@material-ui/core";

export const ButtonP = styled(Button)`
  font-family: "Roboto", sans-serif;
  background: #4F4184; /* Set the background color to #4F4184 */
  color: #ffffff; /* Set the text color to white */
  border: 0.063rem solid var(--purplePrimary);
  font-weight: 700;
  font-size: 0.875rem;
  width: ${(props) => (props.minwidth ? "9.063rem" : "7.063rem")};
  height: 2.5rem;
  margin-top: 1rem;
  margin-right: ${(props) => (props.mright ? "1rem" : "0")};
  &:hover {
    background: var(--purplePrimary);
    border-color: var(--purplePrimary);
  }
  &:active:hover:not([disabled]) {
    background: var(--purplePrimary);
    border-color: var(--purplePrimary);
  }
  &:disabled {
    background-color: #c1c5c8;
    border-color: #c1c5c8;
    opacity: 1;
  }
}
`;
