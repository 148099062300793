import {
  GET_ESTADO_CAMIONES_CLS,
  RESET_ESTADO_CAMIONES_CLS,
  SHOW_CAR_TRANSITO_CLS,
  SHOW_MAP_TRANSITO_CLS,
  SHOW_CAR_CANTERA_CLS,
  SHOW_MAP_CANTERA_CLS,
  SHOW_CAR_LAVADO_CLS,
  SHOW_MAP_LAVADO_CLS,
  SHOW_CAR_SECADO_CLS,
  SHOW_MAP_SECADO_CLS,
  SHOW_CAR_WS_CLS,
  SHOW_MAP_WS_CLS,
  SHOW_CAR_SIN_GEOTAB,
  SHOW_MAP_SIN_GEOTAB,
} from "../../constants/actionsTypes";

export const getEstadoCamionesCls = (payload) => {
  return {
    type: GET_ESTADO_CAMIONES_CLS,
    payload,
  };
};

export const resetEstadoCamionesCls = (payload) => ({
  type: RESET_ESTADO_CAMIONES_CLS,
  payload,
});

export const showCarTransitoCls = (placa) => ({
  type: SHOW_CAR_TRANSITO_CLS,
  payload: { placa },
});

export const showMapTransitoCls = () => ({
  type: SHOW_MAP_TRANSITO_CLS,
});

export const showCarCanteraCls = (placa) => ({
  type: SHOW_CAR_CANTERA_CLS,
  payload: { placa },
});

export const showMapCanteraCls = () => ({
  type: SHOW_MAP_CANTERA_CLS,
});

export const showCarLavadoCls = (placa) => ({
  type: SHOW_CAR_LAVADO_CLS,
  payload: { placa },
});

export const showMapLavadoCls = () => ({
  type: SHOW_MAP_LAVADO_CLS,
});

export const showCarSecadoCls = (placa) => ({
  type: SHOW_CAR_SECADO_CLS,
  payload: { placa },
});

export const showMapSecadoCls = () => ({
  type: SHOW_MAP_SECADO_CLS,
});

export const showCarWsCls = (placa) => ({
  type: SHOW_CAR_WS_CLS,
  payload: { placa },
});

export const showMapWsCls = () => ({
  type: SHOW_MAP_WS_CLS,
});
export const showCarSinGeotab = (placa) => ({
  type: SHOW_CAR_SIN_GEOTAB,
  payload: { placa },
});

export const showMapSinGeotab = () => ({
  type: SHOW_MAP_SIN_GEOTAB,
});
