import React from "react";
import ReactDOM from "react-dom";
import { Grid, FormHelperText } from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  StyledForm
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import { Inputs } from "../../Input/Inputs";
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { editarEtapasDia } from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  etapas_dia: yup.string().required("Este campo es requerido"),
});

export const ModalEtapasDias = ({ onClose }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: { etapas_dia: "" },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(editarEtapasDia(values));
      onClose()
    },
  });


  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={onClose}>
        <Head>
          <p>Editar etapas por día</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid item md={12}>
            <FormGroup>
              <Inputs
                type="number"
                autoComplete="off"
                name="etapas_dia"
                label="Ingrese etapas por día*"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.etapas_dia}
                error={!!formik.errors.etapas_dia}
              />
              <FormHelperText>{formik.errors.etapas_dia}</FormHelperText>
            </FormGroup>
          </Grid>
          <ButtonContainer align="center">
            <ButtonPrimary
              disabled={!formik.dirty}
              type="submit"
              textArea="Guardar"
              // onClick={(e) => handleCick(e)}
              variant="contained"
              color="primary"
              mright="true"
            />
          </ButtonContainer>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
