import React, { useState } from "react";
import { useHistory } from "react-router-dom";

/* componentes */
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import GraficoEscenarios from "../GraficoEscenarios/GraficoEscenarios";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Grid, FormHelperText, Checkbox } from "@material-ui/core";
import { Inputs } from "../Input/Inputs";
import FullPageLoader from "../../utils/FullPageLoader/FullPageLoader";
import {
  Div,
  Label,
  Container,
  Hr,
  FormGroup,
  ButtonContainer,
  GridItem,
  StyledForm,
  ScrollContainer,
} from "./Styles";
import { escenariosProyecciones } from "../../Services";
import { resetEscenarios } from "../../actions/escenariosActions";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  assignaciones: yup.array().of(
    yup.object().shape({
      nombre_planta: yup.string(),
      camiones_m100: yup
        .number("Debe ser un número")
        .integer("El valor debe ser un número entero")
        .moreThan(-1, "El valor debe ser positivo")
        .required("No puede estar vacío"),
      camiones_m3070: yup
        .number("Debe ser un número")
        .integer("El valor debe ser un número entero")
        .moreThan(-1, "El valor debe ser positivo")
        .required("No puede estar vacío"),
      desfase_planta: yup
        .number("Debe ser un número")
        .required("No puede estar vacío"),
    })
  ),
  feed_rate_per_stage_m100: yup
    .number("Debe ser un número")
    .moreThan(-1, "El valor debe ser positivo")
    .required("No puede estar vacío"),
  feed_rate_per_stage_m3070: yup
    .number("Debe ser un número")
    .moreThan(-1, "El valor debe ser positivo")
    .required("No puede estar vacío"),
  iniciar_en_pad: yup.boolean(),
  duracion_etapa: yup
    .number("Debe ser un número")
    .moreThan(-1, "El valor debe ser positivo"),
});

export const EscenariosProyeccionForm = () => {
  const { infoPad } = useSelector((state) => state);
  const plantas = useSelector((state) => state.infoPlantas.plantas);
  const { proyeccionCajas } = useSelector((state) => state.escenarios);
  const isLoading = useSelector((state) => state.loading);
  const [showGraph, setShowGraph] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();
  function cancelar() {
    history.push("/home");
  }
  const [sliderValue, setSliderValue] = useState(false);
  const assignaciones = plantas?.map((planta) => ({
    nombre_planta: planta?.nombre_planta ? planta?.nombre_planta : "",
    camiones_m100: planta?.camiones_asignados?.M100
      ? planta?.camiones_asignados?.M100
      : 0,
    camiones_m3070: planta?.camiones_asignados?.M3070
      ? planta?.camiones_asignados?.M3070
      : 0,
    desfase_planta: 0,
  }));

  const formik = useFormik({
    initialValues: {
      assignaciones,
      feed_rate_per_stage_m100: infoPad?.pad?.feed_rate_per_stage_m100
        ? infoPad?.pad?.feed_rate_per_stage_m100
        : 0,
      feed_rate_per_stage_m3070: infoPad?.pad?.feed_rate_per_stage_m3070
        ? infoPad?.pad?.feed_rate_per_stage_m3070
        : 0,
      iniciar_en_pad: sliderValue,
      duracion_etapa: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(resetEscenarios());
      dispatch(
        escenariosProyecciones({
          assignaciones: values.assignaciones,
          feed_rate_per_stage_m100: values.feed_rate_per_stage_m100,
          feed_rate_per_stage_m3070: values.feed_rate_per_stage_m3070,
          iniciar_en_pad: values.iniciar_en_pad,
          duracion_etapa:
            values.duracion_etapa === "" ? 0 : values.duracion_etapa,
        })
      );
      setShowGraph(true);
    },
  });
  return (
    <ScrollContainer>
      <Container>
        <div className="content_info">
          <StyledForm onSubmit={formik.handleSubmit}>
            <Grid container justify="center">
              <h2>
                <b>Condiciones iniciales y de planificación de PAD</b>
              </h2>
            </Grid>
            <Grid container justify="space-evenly">
              <GridItem item xs={12} md={5} sm={6}>
                <FormGroup>
                  <Label>Consumo de cajas de arena M100 por etapa</Label>
                  <Inputs
                    id="feed_rate_per_stage_m100"
                    autoComplete="off"
                    type="number"
                    name="feed_rate_per_stage_m100"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.feed_rate_per_stage_m100}
                    error={formik.errors.feed_rate_per_stage_m100}
                  />
                  <FormHelperText>
                    {formik.errors.feed_rate_per_stage_m100}
                  </FormHelperText>
                </FormGroup>
              </GridItem>
              <GridItem item xs={12} md={5} sm={6}>
                <FormGroup>
                  <Label>Consumo de cajas de arena M3070 por etapa</Label>
                  <Inputs
                    id="feed_rate_per_stage_m3070"
                    autoComplete="off"
                    type="number"
                    name="feed_rate_per_stage_m3070"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.feed_rate_per_stage_m3070}
                    error={formik.errors.feed_rate_per_stage_m3070}
                  />
                  <FormHelperText>
                    {formik.errors.feed_rate_per_stage_m3070}
                  </FormHelperText>
                </FormGroup>
              </GridItem>
              <GridItem item xs={12} md={5} sm={6}>
                <FormGroup>
                  <Inputs
                    id="duracion_etapa"
                    autoComplete="off"
                    type="number"
                    step="any"
                    name="duracion_etapa"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.duracion_etapa}
                    error={formik.errors.duracion_etapa}
                    label="Duración de etapa (horas)"
                  />
                  <FormHelperText>
                    {formik.errors.duracion_etapa}
                  </FormHelperText>
                </FormGroup>
              </GridItem>
              <GridItem item xs={12} md={5} sm={6}>
                <FormGroup>
                  <Label>
                    <Checkbox
                      type="checkbox"
                      id="iniciar_en_pad"
                      onClick={() => setSliderValue(!sliderValue)}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="iniciar_en_pad"
                      value={formik.values.iniciar_en_pad}
                      color="primary"
                    />
                    Iniciar en PAD
                  </Label>
                </FormGroup>
              </GridItem>
            </Grid>

            <Hr />
            <h2>
              <b>Asignaciones de camiones por planta y tipo de arena</b>
            </h2>
            {plantas?.map((planta, index) => (
              <Grid container md={12} sm={12} justify="center">
                <Div>{planta.nombre_planta}</Div>
                <Grid container md={12} sm={9} justify="space-evenly">
                  <GridItem item sm={3}>
                    <FormGroup>
                      <Inputs
                        autoComplete="off"
                        type="number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name={`assignaciones[${index}].camiones_m100`}
                        value={
                          formik.values?.assignaciones[index]?.camiones_m100
                        }
                        error={
                          formik.errors.assignaciones &&
                          formik.errors.assignaciones[index] &&
                          formik.errors.assignaciones[index].camiones_m100
                        }
                        label="Camiones M100"
                      />
                      <FormHelperText>
                        {formik.errors.assignaciones &&
                          formik.errors.assignaciones[index] &&
                          formik.errors.assignaciones[index].camiones_m100}
                      </FormHelperText>
                    </FormGroup>
                  </GridItem>
                  <GridItem item sm={3}>
                    <FormGroup>
                      <Inputs
                        autoComplete="off"
                        type="number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name={`assignaciones[${index}].camiones_m3070`}
                        value={
                          formik.values?.assignaciones[index]?.camiones_m3070
                        }
                        error={
                          formik.errors.assignaciones &&
                          formik.errors.assignaciones[index] &&
                          formik.errors.assignaciones[index].camiones_m3070
                        }
                        label="Camiones M3070"
                      />
                      <FormHelperText>
                        {formik.errors.assignaciones &&
                          formik.errors.assignaciones[index] &&
                          formik.errors.assignaciones[index].camiones_m3070}
                      </FormHelperText>
                    </FormGroup>
                  </GridItem>
                  <GridItem item sm={3}>
                    <FormGroup>
                      <Inputs
                        autoComplete="off"
                        type="number"
                        step="any"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name={`assignaciones[${index}].desfase_planta`}
                        value={
                          formik.values?.assignaciones[index]?.desfase_planta
                        }
                        label="Ajuste roundtrip"
                      />
                    </FormGroup>
                  </GridItem>
                </Grid>
              </Grid>
            ))}
            <Hr />
            <Grid container justify="center">
              <h2>
                <b>Cantidad total de camiones</b>
              </h2>
            </Grid>
            <Grid container justify="space-evenly">
              <GridItem item xs={12} md={5} sm={6}>
                <FormGroup>
                  <Inputs
                    id="total_camiones_m100"
                    autoComplete="off"
                    name="total_camiones_m100"
                    value={formik.values?.assignaciones?.reduce(
                      (acc, planta) => Number(acc + planta.camiones_m100),
                      0
                    )}
                    label="Camiones M100"
                  />
                </FormGroup>
              </GridItem>
              <GridItem item xs={12} md={5} sm={6}>
                <FormGroup>
                  <Inputs
                    id="total_camiones_m3070"
                    autoComplete="off"
                    name="total_camiones_m3070"
                    value={formik.values?.assignaciones?.reduce(
                      (acc, planta) => Number(acc + planta.camiones_m3070),
                      0
                    )}
                    label="Camiones M3070"
                  />
                </FormGroup>
              </GridItem>
            </Grid>

            <Grid container md={12} sm={8} justify="space-evenly">
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    type="button"
                    textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                    onClick={() => cancelar()}
                    variant="outlined"
                    color="primary"
                    minwidth="true"
                  />
                </ButtonContainer>
              </Grid>
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    type="submit"
                    textArea="Simular"
                    variant="contained"
                    color="primary"
                    mright="true"
                  />
                </ButtonContainer>
              </Grid>
            </Grid>
          </StyledForm>
        </div>
      </Container>
      {showGraph && (
        <GraficoEscenarios
          setShowGraph={setShowGraph}
          showGraph={showGraph}
          data={proyeccionCajas?.graficoProyeccion}
        />
      )}
      <FullPageLoader isLoading={isLoading} />
    </ScrollContainer>
  );
};
