import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const Container = styled.div`
  height: calc(100vh - 60px);
  background: #ffffff;
  box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.08);
  display: flow-root;
  position: relative;
  // margin: 0;
  margin-left: 75px;
  padding: 0;
  & h2 {
    font-size: 1rem;
    margin-bottom: 15px;
    font-weight: 500;
    letter-spacing: -0.5px;
  }

  @media (max-width: 768px) {
    height: calc(100vh - 60px);
    overflow: auto;
    margin-left: 0px;
  }

  .content_info {
    position: relative;
    padding: 15px;
    height: 100%;
    overflow: auto;
  }
`;

export const ItemContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  & p {
    margin: 0 0 0 8px;
    font-size: 14px;
  }
`;

export const ItemContainer2 = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: wrap;
  margin-bottom: 15px;
  padding: 0;
  &.proyeccion {
    padding: 0;
  }
  &.proyeccion2 {
    justify-content: center;
  }
  & svg {
    width: 14px;
  }
  & p {
    margin: 0 0 0 8px;
    font-size: 14px;
  }

  @media (max-width: 1250px) {
    flex-wrap: wrap;
  }

  @media (min-width: 1250px) {
    flex-wrap: nowrap;
  }
`;

export const Wrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Indicador = styled(Grid)`
  font-weight: 500;
  margin-right: 0;
  min-width: 30%;
  text-align: center;
  font-size: 14px;
`;
export const Hr = styled.hr`
  background-color: #c4c4c4;
  width: 100%;
  margin-top: ${(props) => (props.mtop ? "1.106rem" : "0")};
  margin-bottom: 1.106rem;
`;
export const State = styled.div`
  background-color: ${(props) =>
    props.greenCard
      ? "rgba(67, 214, 106, 0.2)"
      : props.yellowCard
      ? "rgba(250, 192, 0, 0.3)"
      : props.redCard
      ? "rgba(223, 0, 0, 0.2)"
      : null};
  width: calc(100%);
  margin: 0 2px;
  height: 4.125rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${(props) =>
    props.green
      ? "#138B33"
      : props.yellow
      ? "#9F7A00"
      : props.red
      ? "#A03434"
      : null};
  line-height: 16px;
  vertical-align: middle;
  font-weight: 700;
  font-size: 16px;
  & span:last-child {
    font-weight: 400;
    font-size: 14px;
  }

  @media (max-width: 1250px) {
    width: 50px;
  }
`;

export const State2 = styled.div`
  background-color: ${(props) =>
    props.greenCard
      ? "rgba(67, 214, 106, 0.2)"
      : props.yellowCard
      ? "rgba(250, 192, 0, 0.3)"
      : props.redCard
      ? "rgba(223, 0, 0, 0.2)"
      : null};
  width: 100%;
  margin: 0 2px;
  height: 4.125rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${(props) =>
    props.green
      ? "#138B33"
      : props.yellow
      ? "#9F7A00"
      : props.red
      ? "#A03434"
      : null};
  line-height: 16px;
  vertical-align: middle;
  font-weight: 700;
  font-size: 16px;
  & span:last-child {
    font-weight: 400;
    font-size: 14px;
  }

  @media (max-width: 1250px) {
    width: 150px;
  }
`;
export const ButtonGraph = styled.button`
  background-color: #ffffff;
  width: 60px;
  min-width: 50px;
  max-width: 60px;
  margin: 0 2px;
  height: 3.125rem;
  border: 1px solid #4f4184;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.green
      ? "#138B33"
      : props.yellow
      ? "#9F7A00"
      : props.red
      ? "#A03434"
      : null};
  & svg {
    width: 100%;
  }

  @media (min-width: 768px) {
    width: calc(100vh - 60px);
    overflow: auto;
  }

  @media (max-width: 1250px) {
    margin-top: 0.5rem;
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 2.188rem;
  padding: 0.625rem 0.938rem;
  background-color: #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.125rem;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: Ellipsis;
  & p {
    margin: 0;
    font-weight: 400;
    line-height: 14px;
  }
  & span {
    font-weight: 500;
  }
`;

export const TaypeSand = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  margin-right: 1.1rem;
  & p {
    margin-left: 1rem;
    font-weight: 600;
  }
`;

export const Div = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
`;

export const Value = styled.span`
  margin-right: ${(props) => (props.maxMright ? "3.2rem" : "1rem")};
  font-weight: 600;
`;
export const Table = styled.table`
  display: table;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0 0 15px 0;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  thead {
    th {
      padding: 5px 0;
    }
  }
  .pad-vacias {
    color: #a03434;
  }
  .pad-llenas {
    color: #4f4184;
  }
  tbody {
    tr {
      background: #f0f0f0;
      border-bottom: solid 2px #fff;
      td {
        padding: 6px 15px;
        font-weight: 500;
        &:first-child {
          text-align: left;
          font-weight: 400;
        }
      }
    }
  }
`;
