import {
  GET_INFO_FACTURACION_CLS,
  RESET_INFO_FACTURACION_CLS,
} from "../../constants/actionsTypes";

const initialState = {};

export const periodoFacturacionClsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INFO_FACTURACION_CLS: {
      return { ...state, ...payload };
    }
    case RESET_INFO_FACTURACION_CLS: {
      return { proveedores: [] };
    }
    default:
      return state;
  }
};
