import { GET_ETAPAS, RESET_CONSULTAR_ETAPAS, GET_FECHA_ETAPA } from '../constants/actionsTypes'

const initialState = {
  etapas: [],
  accionEtapa: {
    action: '',
    nombre_etapa: '',
    fecha_creacion: '',
    fecha_inicio_etapa_original: '',
    hora_creacion: ''
  },
}

export const consultarEtapasReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_ETAPAS: {
      return { ...state, ...payload }
    }

    case GET_FECHA_ETAPA: {
      return { ...state, accionEtapa: payload }
    }

    case RESET_CONSULTAR_ETAPAS: {
      return { ...initialState }
    }
    default:
      return state
  }
}