import {
  GET_INDICADORES_CLS,
  RESET_INDICADORES_CLS,
} from "../../constants/actionsTypes";

const initialState = {};

export const indicadoresClsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INDICADORES_CLS: {
      return { ...state, ...payload };
    }
    case RESET_INDICADORES_CLS: {
      return { infoPad: [] };
    }
    default:
      return state;
  }
};
