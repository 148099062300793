import {
  GET_PLANTAS,
  RESET_PLANTAS,
  SET_CURRENT_DATA,
} from "../constants/actionsTypes";

const initialState = {
  currentData: {
    placa: "",
    tipo_arena: "",
    destino: "",
  },
  plantas: [],
};

export const plantasReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PLANTAS: {
      return { ...state, ...payload };
    }

    case SET_CURRENT_DATA: {
      return { ...state, currentData: payload };
    }

    case RESET_PLANTAS: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
