import React from "react";
import ReactApexChart from "react-apexcharts";
import { Box } from "@material-ui/core";
import { Container, Header, Title, CloseIcon, Divide } from "./Styles.js";

const GraficoCajas = ({ showGraph, setShowGraph, data }) => {
  const optionsM100 = {
    annotations: {
      position: "back",
      yaxis: [
        {
          label: {
            text: " ",
          },
          y: 0,
          y2: 2,
          fillColor: "#A03434",
        },
        {
          label: {
            text: " ",
          },
          y: 2,
          y2: 4,
          fillColor: "#9F7A00",
        },
        {
          label: {
            text: " ",
          },
          y: 4,
          y2: 6,
          fillColor: "#138B33",
        },
      ],
    },
    chart: {
      height: 10,
      width: 800,
      type: "area",
    },
    colors: ["#5200FF"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    xaxis: {
      categories: data?.cajasM100.map((item) => item.name.toString()),
      title: {
        text: "Horas proyectadas",
        style: {
          fontWeight: "light",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 6,
      title: {
        text: "Etapas",
        style: {
          fontWeight: "light",
        },
      },
    },
  };
  const optionsM3070 = {
    annotations: {
      position: "back",
      yaxis: [
        {
          label: {
            text: " ",
          },
          y: 0,
          y2: 2,
          fillColor: "#A03434",
        },
        {
          label: {
            text: " ",
          },
          y: 2,
          y2: 4,
          fillColor: "#9F7A00",
        },
        {
          label: {
            text: " ",
          },
          y: 4,
          y2: 6,
          fillColor: "#138B33",
        },
      ],
    },
    chart: {
      height: 10,
      width: 800,
      type: "area",
    },
    colors: ["#18A0FB"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    xaxis: {
      categories: data?.cajasM3070.map((item) => item.name.toString()),
      title: {
        text: "Horas proyectadas",
        style: {
          fontWeight: "light",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 6,
      title: {
        text: "Etapas",
        style: {
          fontWeight: "light",
        },
      },
    },
  };
  const seriesM100 = [
    { name: "Etapa", data: data?.cajasM100.map((item) => item.value) },
  ];
  const seriesM3070 = [
    { name: "Etapa", data: data?.cajasM3070.map((item) => item.value) },
  ];

  return (
    <Container>
      <Header item>
        <CloseIcon onClick={() => setShowGraph(!showGraph)} />
        <Title>Proyección</Title>
      </Header>

      <Divide />

      <Box pb="10px">
        <Box pl="10px" mb="10px">
          <Title
            style={{
              fontSize: "12px",
            }}
          >
            Etapas cubiertas para arena M3070
          </Title>
        </Box>
        <Box pl="30px" display="flex" alignItems="center">
          <Box mr="10px" width="20px" height="5px" bgcolor="#18A0FB" />
          <Title
            style={{
              fontSize: "11px",
            }}
          >
            M3070
          </Title>
        </Box>
        <Box>
          <ReactApexChart
            height={300}
            options={optionsM3070}
            series={seriesM3070}
          />
        </Box>
      </Box>

      <Divide />

      <Box>
        <Box pl="10px" mb="10px">
          <Title
            style={{
              fontSize: "12px",
            }}
          >
            Etapas cubiertas para arena M100
          </Title>
        </Box>

        <Box pl="30px" display="flex" alignItems="center">
          <Box mr="10px" width="20px" height="5px" bgcolor="#5200FF" />
          <Title
            style={{
              fontSize: "11px",
            }}
          >
            M100
          </Title>
        </Box>
        <Box>
          <ReactApexChart
            height={300}
            options={optionsM100}
            series={seriesM100}
          />
        </Box>
      </Box>
      <Box pl="10px" mb="10px"></Box>
    </Container>
  );
};

export default GraficoCajas;
