import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faChevronRight,
  faChevronDown,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { ConvertDateTime } from "../../../../utils/DateTimeConverter/DateTimeConverter";
import {
  Container,
  Acordeon,
  Cabecera,
  Collapse,
  NameAcordeon,
  ShowMap,
  Contenido,
  Camiones,
  Camion,
  Detalle,
  Placa,
  Arena,
  Estado,
} from "./Styles";

/*Componentes*/
import {
  showCarCanteraCls,
  showCarSecadoCls,
  showCarLavadoCls,
  showCarTransitoCls,
  showCarWsCls,
  showCarSinGeotab,
  showMapCanteraCls,
  showMapSecadoCls,
  showMapLavadoCls,
  showMapTransitoCls,
  showMapWsCls,
  showMapSinGeotab,
} from "../../../../actions/cls/estadoCamionesClsActions";

import { sort } from "../../../../utils";

export const PanelCamiones = ({
  toggleCantera,
  toggleTransito,
  toggleSecado,
  toggleLavado,
  toggleSinGeotab,
  toggleWs,
  camionCantera,
  transito,
  secado,
  lavado,
  ws,
  sinGeotab,
}) => {
  const { camiones } = useSelector((state) => state.estadoCamionesCls);

  const dispatch = useDispatch();

  const numCamionesCantera = camiones?.camiones_en_cantera?.camiones.length;
  const numTransito = camiones?.camiones_transito?.camiones.length;
  const numSecado = camiones?.camiones_en_secado?.camiones.length;
  const numLavado = camiones?.camiones_en_lavado?.camiones.length;
  const numWS = camiones?.camiones_en_ws?.camiones.length;
  const numSinGeotab = camiones?.camiones_sin_gps?.camiones.length;

  return (
    <Container container md={3}>
      {/* En cantera */}
      <Acordeon>
        <Cabecera>
          <Collapse onClick={() => toggleCantera()}>
            {camionCantera ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </Collapse>
          <NameAcordeon>
            En cantera: <span>{numCamionesCantera}</span>
          </NameAcordeon>
          <ShowMap onClick={() => dispatch(showMapCanteraCls())}>
            {camiones?.camiones_en_cantera?.showMap ? (
              <FontAwesomeIcon icon={faEye} />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} />
            )}
          </ShowMap>
        </Cabecera>
        {camionCantera && (
          <Contenido>
            <Camiones>
              {camiones &&
                camiones?.camiones_en_cantera?.camiones
                  ?.sort(sort("placa"))
                  ?.map((camionCantera, index) => {
                    return (
                      <Camion align="true" key={index}>
                        <Detalle>
                          <Placa>{camionCantera.placa}</Placa>
                          <Arena>
                            <b>Tipo de arena</b>
                            <span>No Especificado</span>
                          </Arena>

                          <Arena>
                            <b>Última actualización</b>
                            <span>
                              {ConvertDateTime(
                                camionCantera.fechaGps,
                                camionCantera.horaGps
                              ).join(" - ")}
                            </span>
                          </Arena>
                        </Detalle>
                        <ShowMap
                          onClick={() =>
                            dispatch(showCarCanteraCls(camionCantera.placa))
                          }
                        >
                          {camionCantera.showCar ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </ShowMap>
                      </Camion>
                    );
                  })}
            </Camiones>
          </Contenido>
        )}
      </Acordeon>

      {/* En transito */}
      <Acordeon>
        <Cabecera>
          <Collapse onClick={() => toggleTransito()}>
            {transito ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </Collapse>
          <NameAcordeon>
            En tránsito: <span>{numTransito}</span>
          </NameAcordeon>
          <ShowMap onClick={() => dispatch(showMapTransitoCls())}>
            {camiones?.camiones_transito?.showMap ? (
              <FontAwesomeIcon icon={faEye} />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} />
            )}
          </ShowMap>
        </Cabecera>
        {transito && (
          <Contenido>
            <Camiones>
              {camiones &&
                camiones?.camiones_transito?.camiones
                  ?.sort(sort("placa"))
                  ?.map((transito, index) => {
                    return (
                      <Camion align="true" key={index}>
                        <Detalle>
                          <Placa>{transito.placa}</Placa>
                          <Arena>
                            <b>Tipo de arena</b>
                            <span>No Especificado</span>
                          </Arena>

                          <Arena>
                            <b>Última actualización</b>
                            <span>
                              {ConvertDateTime(
                                transito.fechaGps,
                                transito.horaGps
                              ).join(" - ")}
                            </span>
                          </Arena>
                        </Detalle>
                        <ShowMap
                          onClick={() =>
                            dispatch(showCarTransitoCls(transito.placa))
                          }
                        >
                          {transito.showCar ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </ShowMap>
                      </Camion>
                    );
                  })}
            </Camiones>
          </Contenido>
        )}
      </Acordeon>

      {/* en planta de secado */}
      <Acordeon>
        <Cabecera>
          <Collapse onClick={() => toggleSecado()}>
            {secado ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </Collapse>
          <NameAcordeon>
            En planta de secado: <span>{numSecado}</span>
          </NameAcordeon>
          <ShowMap onClick={() => dispatch(showMapSecadoCls())}>
            {camiones?.camiones_en_secado?.showMap ? (
              <FontAwesomeIcon icon={faEye} />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} />
            )}
          </ShowMap>
        </Cabecera>
        {secado && (
          <Contenido>
            <Camiones>
              {camiones &&
                camiones?.camiones_en_secado?.camiones
                  ?.sort(sort("placa"))
                  ?.map((secado, index) => {
                    return (
                      <Camion align="true" key={index}>
                        <Detalle>
                          <Placa>{secado.placa}</Placa>

                          <Arena>
                            <b>Tipo de arena</b>
                            <span>No Especificado</span>
                          </Arena>

                          <Arena>
                            <b>Última actualización</b>
                            <span>
                              {ConvertDateTime(
                                secado.fechaGps,
                                secado.horaGps
                              ).join(" - ")}
                            </span>
                          </Arena>
                        </Detalle>
                        <ShowMap
                          onClick={() =>
                            dispatch(showCarSecadoCls(secado.placa))
                          }
                        >
                          {secado.showCar ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </ShowMap>
                      </Camion>
                    );
                  })}
            </Camiones>
          </Contenido>
        )}
      </Acordeon>

      {/* En planta de lavado */}
      <Acordeon>
        <Cabecera>
          <Collapse onClick={() => toggleLavado()}>
            {lavado ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </Collapse>
          <NameAcordeon>
            {" "}
            En planta de lavado: <span> {numLavado} </span>{" "}
          </NameAcordeon>
          <ShowMap onClick={() => dispatch(showMapLavadoCls())}>
            {camiones?.camiones_en_lavado?.showMap ? (
              <FontAwesomeIcon icon={faEye} />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} />
            )}
          </ShowMap>
        </Cabecera>
        {lavado && (
          <Contenido>
            <Camiones>
              {camiones &&
                camiones?.camiones_en_lavado?.camiones
                  ?.sort(sort("placa"))
                  ?.map((lavado, index) => {
                    return (
                      <Camion align="true" key={index}>
                        <Detalle>
                          <Placa>{lavado.placa}</Placa>

                          <Arena>
                            <b>Tipo de arena</b>
                            <span>No Especificado</span>
                          </Arena>

                          <Arena>
                            <b>Última actualización</b>
                            <span>
                              {ConvertDateTime(
                                lavado.fechaGps,
                                lavado.horaGps
                              ).join(" - ")}
                            </span>
                          </Arena>
                        </Detalle>
                        <ShowMap
                          onClick={() =>
                            dispatch(showCarLavadoCls(lavado.placa))
                          }
                        >
                          {lavado.showCar ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </ShowMap>
                      </Camion>
                    );
                  })}
            </Camiones>
          </Contenido>
        )}
      </Acordeon>
      {/* En planta WS Brent */}
      <Acordeon>
        <Cabecera>
          <Collapse onClick={() => toggleWs()}>
            {ws ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </Collapse>
          <NameAcordeon>
            {" "}
            En planta WS Brent: <span> {numWS} </span>{" "}
          </NameAcordeon>
          <ShowMap onClick={() => dispatch(showMapWsCls())}>
            {camiones?.camiones_en_ws?.showMap ? (
              <FontAwesomeIcon icon={faEye} />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} />
            )}
          </ShowMap>
        </Cabecera>
        {ws && (
          <Contenido>
            <Camiones>
              {camiones &&
                camiones?.camiones_en_ws?.camiones
                  ?.sort(sort("placa"))
                  ?.map((ws, index) => {
                    return (
                      <Camion align="true" key={index}>
                        <Detalle>
                          <Placa>{ws.placa}</Placa>

                          <Arena>
                            <b>Tipo de arena</b>
                            <span>No Especificado</span>
                          </Arena>

                          <Arena>
                            <b>Última actualización</b>
                            <span>
                              {ConvertDateTime(ws.fechaGps, ws.horaGps).join(
                                " - "
                              )}
                            </span>
                          </Arena>
                        </Detalle>
                        <ShowMap
                          onClick={() => dispatch(showCarWsCls(ws.placa))}
                        >
                          {ws.showCar ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </ShowMap>
                      </Camion>
                    );
                  })}
            </Camiones>
          </Contenido>
        )}
      </Acordeon>

      {/* Sin Geotab */}
      <Acordeon marginbottom="true">
        <Cabecera>
          <Collapse onClick={() => toggleSinGeotab()}>
            {sinGeotab ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </Collapse>
          <NameAcordeon>
            Sin señal: <span> {numSinGeotab} </span>
          </NameAcordeon>
          <ShowMap onClick={() => dispatch(showMapSinGeotab())}>
            {camiones?.camiones_sin_gps?.showMap ? (
              <FontAwesomeIcon icon={faEye} />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} />
            )}
          </ShowMap>
        </Cabecera>
        {sinGeotab && (
          <Contenido>
            <Camiones>
              {camiones &&
                camiones?.camiones_sin_gps?.camiones
                  ?.sort(sort("placa"))
                  ?.map((sinGeotab, index) => {
                    return (
                      <Camion align="true" key={index}>
                        <Detalle>
                          <Placa>{sinGeotab.placa}</Placa>
                          <Estado>
                            <b>Proveedor</b>
                            <span>{sinGeotab.empresa}</span>
                          </Estado>
                          <Estado>
                            <b>Tipo de arena</b>
                            <span>{sinGeotab.material}</span>
                          </Estado>
                          <Estado>
                            <b>Conductor</b>
                            <span>{sinGeotab.chofer}</span>
                          </Estado>
                        </Detalle>
                        <ShowMap
                          onClick={() =>
                            dispatch(showCarSinGeotab(sinGeotab.placa))
                          }
                        >
                          {sinGeotab.showCar ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </ShowMap>
                      </Camion>
                    );
                  })}
            </Camiones>
          </Contenido>
        )}
      </Acordeon>
    </Container>
  );
};
