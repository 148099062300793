import React, { useEffect, useState } from 'react';
import { sort } from '../../../utils';
import {
	Container,
	Plantas,
	Planta,
	Cabecera,
	InfoPlanta,
} from './Styles';

export const PanelPlantas = ({ plantas }) => {
	const [ getPlanta, setGetPlantas ] = useState([])

	useEffect(() => {
		setGetPlantas((state) => {
			const safety_stock = plantas?.filter((planta) => {
				return planta.estado_planta !== 'inactivo'
			})

			return safety_stock
		})
	},[setGetPlantas])

	return (
		<Container container md={3}>

			<Plantas>
				{
					getPlanta
					?.sort(sort('nombre_planta'))
					?.map((planta) => (
						<Planta>
							<Cabecera>{planta.nombre_planta}</Cabecera>
							<InfoPlanta>
								<table>
									<thead>
										<tr>
											<th></th>
											<th>M100</th>
											<th>M3070</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Stock disponible</td>
											<td>{
												typeof planta.stock_disponible.M100 === 'number'
													? planta.stock_disponible.M100.toFixed(0)
													: 0
											} TM</td>
											<td>{
												typeof planta.stock_disponible.M3070 === 'number'
													? planta.stock_disponible.M3070.toFixed(0)
													: 0
											} TM</td>
										</tr>
										<tr>
											<td>Stock diario</td>
											<td>{
												typeof planta.stock_diario.M100 === 'number'
													? planta.stock_diario.M100.toFixed(0)
													: 0
												} TM</td>
											<td>{
												typeof planta.stock_diario.M3070 === 'number'
													? planta.stock_diario.M3070.toFixed(0)
													: 0
												} TM</td>
										</tr>
										{
											planta.safety_stock &&
												<tr>
													<td>Consumo total</td>
													<td>{
														planta.consumo_total?.M100.toFixed(0)
															? planta.consumo_total?.M100.toFixed(0)
															: 0
														} TM</td>
													<td>{
														planta.consumo_total?.M3070.toFixed(0)
															? planta.consumo_total?.M3070.toFixed(0)
															: 0
														} TM</td>
												</tr>
										}
									</tbody>
								</table>
							</InfoPlanta>
						</Planta>
					))
				}
			</Plantas>

		</Container>
	);

};