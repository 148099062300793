import styled from "styled-components";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";

// export const Container = styled(Grid)`
//     position: relative;
//     background-color:#EBEBEB;
//     margin:0;
//     padding: 0;
//     height:calc(100vh - 100px);
//     overflow: hidden;
// `;
export const Div = styled(Grid)`
  height: calc(100vh - 130px);
  background-color: #cbc3e3;
  margin: 0;
  padding: 15px 20px;
  overflow: auto;
  &::-webkit-scrollbar {
    background: rgba(196, 196, 196, 0.5);
    width: 6px;
    opacity: 0;
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(196, 196, 196, 0.4);
    border-radius: 0px;
    opacity: 0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(1, 49, 114, 0.3);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0);
  }
`;

export const Menu = styled.div`
  margin: 0;
  width: 100%;
  height: 40px;
  padding: 0;
`;
// export const Cabecera = styled.div`
//     display:flex;
//     width:100%;
//     border-bottom:solid 2px #333172;
//     padding:15px;
//     position:relative;
// `;
export const NameSelect = styled.span`
  font-family: "Roboto-Medium", sans-serif;
  font-size: 1.2rem;
  color: #414042;
  padding: 4px 0;
  line-height: 0;
`;
export const ToggleMenu = styled.div`
  color: #4f4184;
  font-size: 30px;
  position: absolute;
  top: -5px;
  right: 20px;
  cursor: pointer;
`;
export const Options = styled.ul`
  position: absolute;
  top: 40px;
  background: #a9a6e9;
  width: 100%;
  height: calc(100% - 40px);
  list-style: none;
  padding: 0;
  margin: 0;
  left: 0;
  transition: left 0.1s;
  &.open {
    left: 0;
    transition: left 0.1s;
  }
`;
export const Item = styled.li`
  font-family: "Roboto-Medium", sans-serif;
  font-size: 0.8rem;
  color: #414042;
  padding: 10px 15px;
  margin: 0;
`;

export const Head = styled.div`
  background-color: #4f4184;
  padding: 10px 15px;
  color: #414042;
  font-family: "Roboto-Bold", sans-serif;
  font-weight: 500;
  font-size: 1rem;
`;

export const Title = styled.li`
  font-family: "Roboto-Bold", sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  color: #414042;
  padding: 10px 15px;
  margin: 0;
`;

export const Links = styled(Link)``;

export const SubItem = styled.li`
  font-family: "Roboto-Regular", sans-serif;
  font-size: 0.8rem;
  color: #414042;
  padding: 12px 30px;
  margin: 0;
  cursor: pointer;
  transition: background 0.5s, color 0.5s;
  &:hover {
    background: #4f4184;
    color: #f0f0f0;
    transition: background 0.5s, color 0.5s;
  }
`;

export const Hr = styled.hr`
  border-bottom: solid 1px #4f4184;
  width: calc(100% - 30px);
  margin: 0 auto;
`;
export const ContentSelect = styled.div`
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
`;

export const Button = styled.button`
  width: 100%;
  border-top: transparent;
  border-left: 1px solid var(--purplePrimary);
  border-right: 1px solid var(--purplePrimary);
  border-bottom: 1px solid var(--purplePrimary);
  padding: 11px 15px;
  font-size: 0.8rem;
  font-family: "Roboto-medium", sans-serif;
  background-color: #fff;
  &:hover {
    background-color: var(--purplePrimary);
    color: #f0f0f0;
  }
`;

export const Container = styled.div`
  display: flow-root;
  background-color: #cbc3e3;
  overflow: auto;
  position: relative;
  margin: 0;
  padding: 0;
  height: calc(100vh - 60px);
  overflow: auto;
`;
export const Acordeon = styled.div`
  display: block;
  width: calc(100% - 30px);
  height: auto;
  overflow: hidden;
  margin: 15px;
  margin-bottom: ${(props) => (props.marginbottom ? "70px" : "")};
  background: #f0f0f0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
`;
export const Cabecera = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 40px;
  border-bottom: solid 1px #4f4184;
`;
export const Collapse = styled.div`
  cursor: pointer;
  width: 40px;
  text-align: center;
  line-height: 40px;
  color: #4f4184;
  &:hover {
    background-color: #f0f0f0;
    border-radius: 50%;
  }
`;
export const NameAcordeon = styled.div`
  width: calc(100% - 74px);
  text-align: left;
  line-height: 40px;

  // export const Links = styled(Link)
`;

export const Contenido = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  overflow: hidden;
`;
//     font-family:'Roboto-Regular',sans-serif;
//     font-size:.8rem;
//     color:#2F2F2F;
//     margin:0;
//     cursor:pointer;
//     text-decoration: none;
//     &:hover {
//         color:#ffffff;
//         transition: background .5s, color .5s;
//         text-decoration: none;

//     }
// `
