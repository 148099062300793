import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  Menu,
  Cabecera,
  NameSelect,
  ToggleMenu,
  Options,
  LinkItem,
  SubItem,
  Hr,
  ContentSelect,
  SubItems,
} from "./Styles";

import { PanelCamiones } from "../Camiones/PanelCamiones/PanelCamiones.js";
import { PanelPlantas } from "../Plantas/PanelPlantas/PanelPlantas.js";
import { Alert } from "../Alertas/Alert/Alert";
import { getAlertsCls } from "../../../Services";
import {
  currentDescargar,
  setCurrentTags,
} from "../../../actions/cls/alertasClsActions";

export const OptionsRight = ({
  Role,
  toggleCantera,
  toggleTransito,
  toggleSecado,
  toggleLavado,
  toggleWs,
  toggleSinGeotab,
  camionCantera,
  transito,
  secado,
  lavado,
  ws,
  sinGeotab,
  openMenuToggle,
  openAlertToggle,
  openCamionesToggle,
  openPlantasToggle,
  toEditarPlanta,
  showCamiones,
  showAlert,
  showPlantas,
  showMenu,
  plantas,
  setShowModalPeriodoFacturacion,
}) => {
  const { currentDescarga } = useSelector((state) => state.alertasCls);
  const [open] = useState();
  const [urlAlerts, setUrlAlerts] = useState("");
  const dispatch = useDispatch();
  const closeMenuPeriodoFacturacion = () => {
    setShowModalPeriodoFacturacion(true);
  };

  useEffect(() => {
    if (
      Role === "SupplyChain" ||
      Role === "SupervAluvional" ||
      Role === "CoordAluvional"
    ) {
      getAlertsCls()
        .then((res) => {
          setUrlAlerts(res.data.url);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
    return () => {
      dispatch(currentDescargar(""));
    };
  }, [setUrlAlerts, currentDescarga]);

  const handleCurrent = (action) => dispatch(setCurrentTags(action));

  return (
    <div className={open ? "blackColor" : ""}>
      <Container container xs={12} md={3} className={open ? "open768" : ""}>
        <Menu>
          <Cabecera>
            <NameSelect onClick={() => handleCurrent("todos")}></NameSelect>
            {(Role === "CoordAluvional" ||
              Role === "SupplyChain" ||
              Role === "SupervAluvional" ||
              Role === "Pedrolga") &&
              showAlert && (
                <NameSelect onClick={() => handleCurrent("todos")}>
                  Alertas
                </NameSelect>
              )}
            {showCamiones && <NameSelect>Camiones</NameSelect>}
            {showPlantas && <NameSelect>Plantas</NameSelect>}
            <ToggleMenu onClick={() => openMenuToggle()}>
              {showMenu ? (
                <FontAwesomeIcon icon={faTimes} />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </ToggleMenu>
          </Cabecera>
        </Menu>

        <Options className={showMenu ? "open" : ""}>
          {(Role === "CoordAluvional" ||
            Role === "SupplyChain" ||
            Role === "SupervAluvional" ||
            Role === "Pedrolga") && (
            <>
              <LinkItem head onClick={() => openAlertToggle()}>
                Alertas
              </LinkItem>
              <Hr />
            </>
          )}

          {(Role === "CoordAluvional" ||
            Role === "SupplyChain" ||
            Role === "SupervAluvional" ||
            Role === "Weber" ||
            Role === "Pedrolga") && (
            <>
              <LinkItem onClick={() => openCamionesToggle()}>Camiones</LinkItem>
              <Hr />
            </>
          )}
          {(Role === "CoordAluvional" ||
            Role === "SupplyChain" ||
            Role === "SupervAluvional") && (
            <>
              <LinkItem onClick={() => openPlantasToggle()}>Plantas</LinkItem>
              <SubItem onClick={() => toEditarPlanta()}>Editar planta</SubItem>
              <Hr />
            </>
          )}

          {(Role === "CoordAluvional" ||
            Role === "SupplyChain" ||
            Role === "SupervAluvional") && (
            <>
              <LinkItem>Reportes</LinkItem>
              <SubItems>
                <a
                  href={urlAlerts}
                  onClick={() => dispatch(currentDescargar("alertas"))}
                >
                  Descargar historial de alertas
                </a>
              </SubItems>
            </>
          )}
          {(Role === "CoordAluvional" ||
            Role === "SupplyChain" ||
            Role === "Pedrolga" ||
            Role === "Weber") && (
            <>
              <LinkItem>Reportes</LinkItem>
              <SubItem onClick={() => closeMenuPeriodoFacturacion()}>
                Descargar facturación por proveedor
              </SubItem>
            </>
          )}
        </Options>

        {showCamiones && (
          <ContentSelect>
            <PanelCamiones
              toggleCantera={toggleCantera}
              toggleTransito={toggleTransito}
              toggleSecado={toggleSecado}
              toggleLavado={toggleLavado}
              toggleWs={toggleWs}
              toggleSinGeotab={toggleSinGeotab}
              camionCantera={camionCantera}
              transito={transito}
              secado={secado}
              lavado={lavado}
              ws={ws}
              sinGeotab={sinGeotab}
            />
          </ContentSelect>
        )}
        {(Role === "CoordAluvional" ||
          Role === "SupplyChain" ||
          Role === "SupervAluvional" ||
          Role === "Pedrolga") &&
          showAlert && (
            <ContentSelect>
              <Alert />
            </ContentSelect>
          )}
        {showPlantas && (
          <ContentSelect>
            <PanelPlantas plantas={plantas} />
          </ContentSelect>
        )}
      </Container>
    </div>
  );
};
