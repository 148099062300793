import { SET_RESPONSE, RESET_RESPONSE } from '../constants/actionsTypes';

// const initialState = {}

// export const responseReducer = (state = initialState, action) => {
//   const { type, payload } = action;
//   switch (type) {
//     case SET_RESPONSE:
//       return { ...state, errorData: payload };
//     case RESET_RESPONSE:
//       return { errorData: initialState };
//     default:
//       return state;
//   }
// };

const initialState = {
  status: '',
  data: {},
  type: '',
  message: '',
  show: false,
  redirect: ''
};
export const responseReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_RESPONSE:
      return { ...state, ...payload };
    case RESET_RESPONSE:
      return { ...state, ...initialState };
    default:
      return state;
  }
};