import { GET_LOGIN, RESET_LOGIN, SET_LOGOUT } from "../constants/actionsTypes";

export const getUser = (payload) => {
  // payload.Role = "Brent";
  return {
    type: GET_LOGIN,
    payload,
  };
};
export const resetUser = (payload) => ({
  type: RESET_LOGIN,
  payload,
});

export const logout = (payload) => ({
  type: SET_LOGOUT,
  payload,
});
