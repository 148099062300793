import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { Head, ContainerUser, Div, IconContainer } from "./Styles";
import { logout, resetUser } from "../../actions/loginActions";
import { resetResponse } from "../../actions/responseActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { resetInfoPlantas } from "../../actions/infoPlantasActions";
import { resetAlertas } from "../../actions/alertasActions";
import { resetEtapas } from "../../actions/consultarEtapasActions";
import { resetRecomendador } from "../../actions/recomendadorActions";
import { resetDeshabilitarCamion } from "../../actions/deshabilitarCamionActions";
import { resetIndicadores } from "../../actions/indicadoresActions";
import { resetEstadoCamiones } from "../../actions/estadoCamionesActions";
import { resetPlantas } from "../../actions/plantasActions";
import { resetCamionesInactivos } from "../../actions/camionesInactivosActions";

// import { ButtonContainer } from "./Styles";
// import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import { resetEstadoRT } from "../../actions/estadortActions";
// import { estadoRT } from "../../Services";

const Header = ({ setShowModalEstadoRTForm }) => {
  const { Name, Role } = useSelector((state) => state.dataUser.user);
  const dataEtapa = useSelector((state) => state.consultarEtapas);
  // const estadoRtStatus = useSelector((state) => state.estadoRT.status);
  // const [buttonColor, setButtonColor] = useState("#34a853");
  // const [fontColor, setFontColor] = useState("#138B33");

  let location = useLocation();
  let history = useHistory();
  const dispatch = useDispatch();

  // const handleClick = () => {
  //   if (buttonColor === "#f20000") {
  //     setShowModalEstadoRTForm(true);
  //   }
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(resetEstadoRT());
  //     dispatch(estadoRT());
  //   }, 60);
  // }, []);

  // useEffect(() => {
  //   // console.log("estadoRtStatus:", estadoRtStatus);
  //   if (estadoRtStatus === 400) {
  //     setButtonColor("#f20000");
  //     // setFontColor("#A03434");
  //   } else {
  //     setButtonColor("#34a853");
  //     // setFontColor("#138B33");
  //   }
  // }, [estadoRtStatus]);

  const nombresHeader = () => {
    switch (location.pathname) {
      case "/editarTrayecto":
        return (
          <>
            <h1>Modificar Trayecto</h1>
          </>
        );
      case "/editarTrayectoForm":
        return (
          <>
            <h1>Modificar Trayecto</h1>
          </>
        );

      case `/editarTrayectoForm/${location.pathname.split("/")[2]}`:
        return (
          <>
            <h1>Modificar Trayecto</h1>
          </>
        );

      case "/home":
        return (
          <>
            <h1 className="tituloApp">Última milla</h1>
          </>
        );
      case "/ingresarEtapa":
        return (
          <>
            {Role === "Brent" && (
              <h1>{dataEtapa.accionEtapa.action} una etapa</h1>
            )}
          </>
        );
      case "/editarEtapas":
        return (
          <>
            <h1>Editar una etapa</h1>
          </>
        );
      case "/editaPlanta":
        return (
          <>
            <h1>Editar planta</h1>
          </>
        );
      case "/asignarPlanta":
        return (
          <>
            <h1>Asignar plantas</h1>
          </>
        );
      case "/habilitarPlanta":
        return (
          <>
            <h1>Habilitar nueva planta</h1>
          </>
        );
      case "/escenariosProyeccion":
        return (
          <>
            <h1>Escenarios de proyección</h1>
          </>
        );
      default:
        return <></>;
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetUser());
    dispatch(resetInfoPlantas());
    dispatch(resetResponse());
    dispatch(resetAlertas());
    dispatch(resetEtapas());
    dispatch(resetRecomendador());
    dispatch(resetDeshabilitarCamion());
    dispatch(resetIndicadores());
    dispatch(resetEstadoCamiones());
    dispatch(resetPlantas());
    dispatch(resetCamionesInactivos());
    dispatch(resetEstadoRT());
    history.push("/");
  };

  return (
    <Head>
      {nombresHeader()}
      {/* <button
        style={{ backgroundColor: buttonColor, color: "white" }}
        onClick={handleClick}
      >
        Estado Modelo
      </button> */}
      <ContainerUser>
        <Div>
          <p>{Name}</p>
          <button onClick={() => handleLogout()}>Cerrar sesión</button>
        </Div>
        <IconContainer>
          <FontAwesomeIcon icon={faUser} />
        </IconContainer>
      </ContainerUser>
    </Head>
  );
};

export default Header;
