import React from 'react'
import ReactDOM from 'react-dom';


import { Background, ModalWrapper } from './Styles'

const ModalRecomendacion = ({ onClose, children }) => {
  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper
        onClose={onClose}
      >
        {children}
      </ModalWrapper>
    </Background>,
    document.getElementById('root')
  )
}

export default ModalRecomendacion
