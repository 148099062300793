import styled from "styled-components";
import { FormControl, Grid } from "@material-ui/core";
import { Form } from "react-bootstrap";

export const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1000;
  top: 0;
  bottom: 0;
`;

export const ModalWrapper = styled.div`
  background: #ffffff;
  border: 0.0625rem solid #e5e5e5;
  box-shadow: 0rem 1.25rem 2.5rem -0.75rem rgba(196, 196, 196, 0.35);
  box-sizing: border-box;
  padding: 1.25rem 1.875rem;
  width: 38.313rem;
  z-index: 10;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.75rem;
  & p {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  & svg {
    font-size: 1.5rem;
    color: #4f4184;
  }
`;

export const FormGroup = styled(FormControl)`
  & input {
    border-style: none;
    margin-top: 0.5rem;
    padding-bottom: 1rem;
  }
`;

export const StyledForm = styled(Form)`
  width: 100%;
  margin-left: 75px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
`;
export const GridItem = styled(Grid)`
  display: flex;
  justify-content: "flex-start";
  align-items: center;
`;
export const Hr = styled.hr`
  background-color: #c4c4c4;
  width: 100%;
  margin-top: "1.106rem"
  margin-bottom: 1.106rem;
`;
export const Container = styled.div`
  height: calc(100vh - 60px);
  background: #ffffff;
  box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.08);
  display: flow-root;
  position: relative;
  margin-left: 75px;
  margin: 0;
  padding: 0;

  & h2 {
    font-size: 1rem;
    margin-bottom: 15px;
    font-weight: 500;
    letter-spacing: -0.5px;
  }

  @media (max-width: 768px) {
    height: calc(100vh - 60px);
    overflow: auto;
  }

  .content_info {
    position: relative;
    padding: 15px;
    width: 85%;
  }
`;

export const Label = styled.label`
  font-size: 0.75rem;
  font-weight: 600;
  color: #818589;
`;

export const CustomCheckbox = styled.input`
  /* Hide the default checkbox */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  /* Set the custom checkbox size */
  width: 16px;
  height: 16px;
  /* Define the custom checkbox appearance */
  border: 2px solid #4f4184;
  border-radius: 3px;
  margin-right: 4px;
  // cursor: pointer;
  /* Change the background color when the checkbox is checked */
  &:checked {
    background-color: #4f4184;
  }
`;

export const ScrollContainer = styled.div`
  height: calc(100vh - 60px);
  overflow-y: auto;
  margin-left: 75px;
  margin: 0;
  padding: 0;
`;
