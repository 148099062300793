import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { Box, Toltip, SpanTitle } from "./Styles";
import { Container } from "react-bootstrap";
import { Box as Boxx } from "@material-ui/core";

/* Imagenes SVG */
import { ReactComponent as LogoCantera } from "../../../assets/img/Cantera.svg";
import { ReactComponent as LogoPlantaCarga } from "../../../assets/img/LogoPlantaCarga.svg";
import { ReactComponent as LogoPlantaSecado } from "../../../assets/img/PlantaSecado.svg";
import { ReactComponent as LogoCamionLleno } from "../../../assets/img/LogoCamionLleno.svg";
import { ReactComponent as LogoAlertaMedia } from "../../../assets/img/alertaMedia.svg";
import { ReactComponent as LogoAlertaAlta } from "../../../assets/img/alertaAlta.svg";
import { ReactComponent as LogoPlantaWS } from "../../../assets/img/PlantaWS.svg";

import {
  Tooltip as TooltipEmpty,
  TooltipPlanta,
} from "../../Camiones/TooltipCamiones/TooltipCamiones";

function Map() {
  const [isOpenCanteras, setIsOpenCanteras] = useState(null);
  const [isOpenPlantaLavado, setIsPlantaLavado] = useState(null);
  const [isOpenPlantaSecado, setIsOpenPlantaSecado] = useState(null);
  const [isOpenCamionesCanteras, setIsOpenCamionesCanteras] = useState(null);
  const [isOpenCamionesTransito, setIsOpenCamionesTransito] = useState(null);
  const [isOpenCamionesLavado, setIsOpenCamionesLavado] = useState(null);
  const [isOpenCamionesSecado, setIsOpenCamionesSecado] = useState(null);
  const [isOpenPlantaWS, setIsOpenPlantaWS] = useState(null);

  const { plantas, camiones } = useSelector((state) => state.estadoCamionesCls);

  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setIsOpenCanteras(null);
        setIsPlantaLavado(null);
        setIsOpenPlantaSecado(null);
        setIsOpenCamionesCanteras(null);
        setIsOpenCamionesTransito(null);
        setIsOpenCamionesLavado(null);
        setIsOpenCamionesSecado(null);
        setIsOpenPlantaWS(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  const MAP_SETTINGS = {
    DEFAULT_MAP_OPTIONS: {
      scrollwheel: true,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
    },
    DIRECTIONS_OPTIONS: { suppressMarkers: true, preserveViewport: true },
  };

  return (
    <GoogleMap
      defaultZoom={9}
      defaultCenter={{ lat: -39.04, lng: -67.827222 }}
      defaultOptions={MAP_SETTINGS.DEFAULT_MAP_OPTIONS}
      className="google-map-home"
    >
      {/* Canteras */}
      {plantas?.map((planta, index) => {
        return (
          planta.proceso === "extraccion_arena" && (
            <Marker
              key={index}
              position={{ lat: planta.lat, lng: planta.lng }}
              onClick={() => {
                setIsOpenCanteras(planta);
              }}
              icon={{
                url: require(`../../../assets/img/Cantera${planta.alerta.prioridad}.png`)
                  .default,
                scaledSize: new window.google.maps.Size(32, 32),
              }}
            />
          )
        );
      })}
      {isOpenCanteras && (
        <InfoWindow
          onCloseClick={() => {
            setIsOpenCanteras(null);
          }}
          position={{ lat: isOpenCanteras.lat, lng: isOpenCanteras.lng }}
        >
          <Toltip>
            <TooltipPlanta {...isOpenCanteras} />
          </Toltip>
        </InfoWindow>
      )}
      {/* Planta de lavado */}
      {plantas?.map((planta, index) => {
        return (
          planta.proceso === "lavado" && (
            <Marker
              key={index}
              position={{ lat: planta.lat, lng: planta.lng }}
              onClick={() => {
                setIsPlantaLavado(planta);
              }}
              icon={{
                url: require(`../../../assets/img/Planta${planta.alerta.prioridad}.png`)
                  .default,
                scaledSize: new window.google.maps.Size(32, 32),
              }}
            />
          )
        );
      })}
      {isOpenPlantaLavado && (
        <InfoWindow
          onCloseClick={() => {
            setIsPlantaLavado(null);
          }}
          position={{
            lat: isOpenPlantaLavado.lat,
            lng: isOpenPlantaLavado.lng,
          }}
        >
          <Toltip>
            <TooltipPlanta {...isOpenPlantaLavado} />
          </Toltip>
        </InfoWindow>
      )}
      {/* Planta de secado */}
      {plantas?.map((planta, index) => {
        return (
          planta.proceso === "secado" && (
            <Marker
              key={index}
              position={{ lat: planta.lat, lng: planta.lng }}
              onClick={() => {
                setIsOpenPlantaSecado(planta);
              }}
              icon={{
                url: require(`../../../assets/img/PlantaSecado${planta.alerta.prioridad}.png`)
                  .default,
                scaledSize: new window.google.maps.Size(32, 32),
              }}
            />
          )
        );
      })}
      {isOpenPlantaSecado && (
        <InfoWindow
          onCloseClick={() => {
            setIsOpenPlantaSecado(null);
          }}
          position={{
            lat: isOpenPlantaSecado.lat,
            lng: isOpenPlantaSecado.lng,
          }}
        >
          <Toltip>
            <TooltipPlanta {...isOpenPlantaSecado} />
          </Toltip>
        </InfoWindow>
      )}
      {/* Planta WS Brent */}
      {plantas?.map((planta, index) => {
        return (
          planta.proceso === "bypass" && (
            <Marker
              key={index}
              position={{ lat: planta.lat, lng: planta.lng }}
              onClick={() => {
                setIsOpenPlantaWS(planta);
              }}
              icon={{
                url: require(`../../../assets/img/PlantaWS${planta.alerta.prioridad}.png`)
                  .default,
                scaledSize: new window.google.maps.Size(32, 32),
              }}
            />
          )
        );
      })}
      {isOpenPlantaWS && (
        <InfoWindow
          onCloseClick={() => {
            setIsOpenPlantaSecado(null);
          }}
          position={{
            lat: isOpenPlantaWS.lat,
            lng: isOpenPlantaWS.lng,
          }}
        >
          <Toltip>
            <TooltipPlanta {...isOpenPlantaWS} />
          </Toltip>
        </InfoWindow>
      )}
      {/* camiones en canteras*/}
      {camiones?.camiones_en_cantera.camiones?.map((camionCantera, index) => {
        return camionCantera.showCar ? (
          <Marker
            key={index}
            position={{ lat: camionCantera.lat, lng: camionCantera.lng }}
            onClick={() => {
              setIsOpenCamionesCanteras(camionCantera);
            }}
            icon={{
              url: require(`../../../assets/img/CamionLleno${camionCantera.alerta.prioridad}.png`)
                .default,
              scaledSize: new window.google.maps.Size(37, 37),
            }}
          />
        ) : null;
      })}

      {isOpenCamionesCanteras && (
        <InfoWindow
          onCloseClick={() => {
            setIsOpenCamionesCanteras(null);
          }}
          position={{
            lat: isOpenCamionesCanteras.lat,
            lng: isOpenCamionesCanteras.lng,
          }}
        >
          <Toltip>
            <TooltipEmpty {...isOpenCamionesCanteras} />
          </Toltip>
        </InfoWindow>
      )}

      {/* camiones en transito*/}
      {camiones?.camiones_transito?.camiones.map((camionTransito, index) =>
        camionTransito.showCar ? (
          <Marker
            key={index}
            position={{ lat: camionTransito.lat, lng: camionTransito.lng }}
            onClick={() => {
              setIsOpenCamionesTransito(camionTransito);
            }}
            icon={{
              url: require(`../../../assets/img/CamionLleno${camionTransito.alerta.prioridad}.png`)
                .default,
              scaledSize: new window.google.maps.Size(37, 37),
            }}
          />
        ) : null
      )}
      {isOpenCamionesTransito && (
        <InfoWindow
          onCloseClick={() => {
            setIsOpenCamionesTransito(null);
          }}
          position={{
            lat: isOpenCamionesTransito.lat,
            lng: isOpenCamionesTransito.lng,
          }}
        >
          <Toltip>
            <TooltipEmpty {...isOpenCamionesTransito} />
          </Toltip>
        </InfoWindow>
      )}

      {/* camiones en planta de lavado*/}
      {camiones?.camiones_en_lavado?.camiones.map((camionLavado, index) =>
        camionLavado.showCar ? (
          <Marker
            key={index}
            position={{
              lat: camionLavado.lat,
              lng: camionLavado.lng,
            }}
            onClick={() => {
              setIsOpenCamionesLavado(camionLavado);
            }}
            icon={{
              url: require(`../../../assets/img/CamionLleno${camionLavado.alerta.prioridad}.png`)
                .default,
              scaledSize: new window.google.maps.Size(37, 37),
            }}
          />
        ) : null
      )}
      {isOpenCamionesLavado && (
        <InfoWindow
          onCloseClick={() => {
            setIsOpenCamionesLavado(null);
          }}
          position={{
            lat: isOpenCamionesLavado.lat,
            lng: isOpenCamionesLavado.lng,
          }}
        >
          <Toltip>
            <TooltipEmpty {...isOpenCamionesLavado} />
          </Toltip>
        </InfoWindow>
      )}

      {/* camiones en planta de secado*/}
      {camiones?.camiones_en_secado?.camiones.map((camionSecado, index) =>
        camionSecado.showCar ? (
          <Marker
            key={index}
            position={{ lat: camionSecado.lat, lng: camionSecado.lng }}
            onClick={() => {
              setIsOpenCamionesSecado(camionSecado);
            }}
            icon={{
              url: require(`../../../assets/img/CamionLleno${camionSecado.alerta.prioridad}.png`)
                .default,
              scaledSize: new window.google.maps.Size(37, 37),
            }}
          />
        ) : null
      )}
      {isOpenCamionesSecado && (
        <InfoWindow
          onCloseClick={() => {
            setIsOpenCamionesSecado(null);
          }}
          position={{
            lat: isOpenCamionesSecado.lat,
            lng: isOpenCamionesSecado.lng,
          }}
        >
          <Toltip>
            <TooltipEmpty {...isOpenCamionesSecado} />
          </Toltip>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}

export const Map2 = () => {
  const { Role } = useSelector((state) => state?.dataUser?.user);
  const keyMapGoogle = useSelector((state) => state.keyMapGoogle.status);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Cuando la clave de Google Maps esté disponible, marca el estado como no cargando
    if (keyMapGoogle) {
      setLoading(false);
    }
  }, [keyMapGoogle]);
  const WrappedMap = withScriptjs(withGoogleMap(Map));

  return (
    <div
      style={{ width: "90%", height: "calc(100vh - 68px)", margin: "0 auto" }}
    >
      <Container id="--ssss" style={{ margin: "10px 0px", padding: "0px" }}>
        <Boxx display="flex" alignItems="center">
          <Boxx display="flex" alignItems="center" mr={4}>
            <Boxx mr="5px">
              {" "}
              <LogoCantera />{" "}
            </Boxx>
            <SpanTitle> Cantera </SpanTitle>
          </Boxx>

          {(Role === "CoordAluvional" ||
            Role === "SupplyChain" ||
            Role === "SupervAluvional" ||
            Role === "Pedrolga") && (
            <>
              <Boxx display="flex" alignItems="center" mr={5}>
                <Boxx mr="5px">
                  {" "}
                  <LogoPlantaSecado />{" "}
                </Boxx>
                <SpanTitle> Planta de secado </SpanTitle>
              </Boxx>
              <Boxx display="flex" alignItems="center" mr={5}>
                <Boxx mr="5px">
                  {" "}
                  <LogoPlantaCarga />{" "}
                </Boxx>
                <SpanTitle> Planta de lavado </SpanTitle>
              </Boxx>
              <Boxx display="flex" alignItems="center" mr={5}>
                <Boxx mr="5px">
                  {" "}
                  <LogoPlantaWS />{" "}
                </Boxx>
                <SpanTitle> Planta WS Brent </SpanTitle>
              </Boxx>
            </>
          )}
          <Boxx display="flex" alignItems="center" mr={5}>
            <Boxx mr="5px">
              {" "}
              <LogoCamionLleno />{" "}
            </Boxx>
            <SpanTitle> Camión en tránsito </SpanTitle>
          </Boxx>
          <Boxx display="flex" alignItems="center" mr={5}>
            <Boxx mr="5px">
              {" "}
              <LogoAlertaMedia />{" "}
            </Boxx>
            <SpanTitle> Alerta media </SpanTitle>
          </Boxx>
          <Boxx display="flex" alignItems="center" mr={5}>
            <Boxx mr="5px">
              {" "}
              <LogoAlertaAlta />{" "}
            </Boxx>
            <SpanTitle> Alerta alta </SpanTitle>
          </Boxx>
        </Boxx>
      </Container>
      {loading ? (
        <div>Cargando el mapa...</div>
      ) : (
        <WrappedMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places&key=${keyMapGoogle}`}
          loadingElement={
            <Box className="grid-google-map-home" style={{ height: `100%` }} />
          }
          containerElement={
            <Box
              className="grid-google-map-home"
              style={{ height: `80vh`, border: "1px solid black" }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      )}
    </div>
  );
};
