import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Grid,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  Input,
} from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  GridItem,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
// import { addHours } from "../../../utils/DateTimeConverter/DateTimeConverter";
import {
  getPeriodoFacturacion,
  postPeriodoFacturacion,
} from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  proveedor: yup.string().required("El proveedor es requerido"),
  tramo: yup.string().required("El tramo es requerido"),
  fecha_inicio: yup.string().required("La fecha de inicio es requerida"),
  fecha_fin: yup.string().required("La fecha de fin es requerida"),
});

export const ModalFacturacion = ({ onClose }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPeriodoFacturacion());
  }, [dispatch]);

  // const hoyArgentina = addHours(-3, new Date()).toISOString();
  // const { Name, Role, Email } = useSelector((state) => state.dataUser.user);
  const { proveedores } = useSelector((state) => state.periodoFacturacionCls);

  const formik = useFormik({
    initialValues: {
      proveedor: "",
      tramo: "",
      fecha_inicio: "dd/mm/aaaa",
      fecha_fin: "dd/mm/aaaa",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      console.log(values);
      dispatch(
        postPeriodoFacturacion({
          proveedor: values.proveedor,
          tramo: values.tramo,
          fecha_inicio: values.fecha_inicio,
          fecha_fin: values.fecha_fin,
          // user_name: Name,
          // user_email: Email,
          // user_rol: Role,
          // datetime: hoyArgentina,
        })
      );
      onClose();
    },
  });

  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Descarga de datos para facturación</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="proveedor">Proveedor</InputLabel>
                <Select
                  id="proveedor"
                  name="proveedor"
                  label="Proveedor"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.proveedor}
                  error={formik.errors.proveedor && formik.touched.proveedor}
                >
                  <MenuItem value="">Seleccione un proveedor</MenuItem>
                  {proveedores?.map((proveedor) => (
                    <MenuItem key={proveedor.nombre} value={proveedor.nombre}>
                      {proveedor.nombre}
                    </MenuItem>
                  ))}
                </Select>
                {formik.errors.proveedor && formik.touched.proveedor && (
                  <FormHelperText
                    error={formik.errors.proveedor && formik.touched.proveedor}
                  >
                    {formik.errors?.proveedor}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="tramo">Tramo</InputLabel>
                <Select
                  id="tramo"
                  name="tramo"
                  label="Tramo"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tramo}
                  error={formik.errors.tramo && formik.touched.tramo}
                >
                  <MenuItem value="">Seleccione un tramo</MenuItem>
                  {formik.values.proveedor &&
                    proveedores
                      .find((prov) => prov.nombre === formik.values.proveedor)
                      .tramo.map((tramo) => (
                        <MenuItem key={tramo} value={tramo}>
                          {tramo}
                        </MenuItem>
                      ))}
                </Select>
                {formik.errors.tramo && formik.touched.tramo && (
                  <FormHelperText
                    error={formik.errors.tramo && formik.touched.tramo}
                  >
                    {formik.errors?.tramo}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="fecha_inicio">
                  Periodo a facturar - Inicio
                </InputLabel>
                <Input
                  id="fecha_inicio"
                  name="fecha_inicio"
                  label="Período a Facturar - Inicio"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fecha_inicio}
                  error={
                    formik.errors.fecha_inicio && formik.touched.fecha_inicio
                  }
                />

                {formik.errors.fecha_inicio && formik.touched.fecha_inicio && (
                  <FormHelperText
                    error={
                      formik.errors.fecha_inicio && formik.touched.fecha_inicio
                    }
                  >
                    {formik.errors?.fecha_inicio}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <InputLabel htmlFor="fecha_fin">
                  Periodo a facturar - Fin
                </InputLabel>
                <Input
                  id="fecha_fin"
                  name="fecha_fin"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fecha_fin}
                  error={formik.errors.fecha_fin && formik.touched.fecha_fin}
                />

                {formik.errors.fecha_fin && formik.touched.fecha_fin && (
                  <FormHelperText error>
                    {formik.errors?.fecha_fin}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>
          </Grid>
          <Grid container md={12} sm={8} justify="center">
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Descargar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
