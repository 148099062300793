import {
  GET_INDICADORES,
  RESET_INDICADORES,
  GET_PROYECCIONES,
} from "../constants/actionsTypes";

export const getIndicadores = (payload) => {
  return {
    type: GET_INDICADORES,
    payload,
  };
};

export const getProyecciones = (data) => {
  const estado = data?.proyeccion_cajas_horas?.estado.map((item, i) => {
    return Object.assign({}, item);
  });

  const semaforo = data?.proyeccion_cajas_horas?.semaforo.map((item) => {
    return Object.assign({}, item);
  });

  const cajasM100 = data?.proyeccion_cajas_horas?.grafico_proyeccion.map(
    (item, index) => {
      return Object.assign(
        {},
        {
          name: Number(`${estado[index].tiempo}`),
          value: item.m100,
        }
      );
    }
  );

  const cajasM3070 = data?.proyeccion_cajas_horas?.grafico_proyeccion.map(
    (item, index) => {
      return Object.assign(
        {},
        {
          name: Number(`${estado[index].tiempo}`),
          value: item.m3070,
        }
      );
    }
  );

  return {
    type: GET_PROYECCIONES,
    payload: Object.assign(
      {},
      {
        semaforo,
        estado,
        graficoProyeccion: {
          cajasM100,
          cajasM3070,
        },
      }
    ),
  };
};

export const resetIndicadores = (payload) => ({
  type: RESET_INDICADORES,
  payload,
});
