import React from "react";
import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

import {
  Head,
  ErrorMessage,
  ButtonContainer,
  Background,
  ModalWrapper
} from "./Styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";
import useAlert from "../../../Hooks/useAlert";
import { useHistory } from "react-router-dom";
import { resetResponse } from "../../../actions/responseActions";

export const ModificarTrayectoError = () => {
  const { response } = useAlert(5000)
  const dispatch = useDispatch();
  let history = useHistory();

  const redirectEditarTrayecto = () => history.push('/editarTrayecto')

  return ReactDOM.createPortal(
    response.show && (
      <Background>
        <ModalWrapper onClose={() => ''}>
          <Head>
            <p>Modificar Trayecto</p>
          </Head>
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span>No ha sido posible cambiar el trayecto</span>
          </ErrorMessage>
          <ButtonContainer container align="center">
            <Grid item md={12}>
              <ButtonPrimary
                type="button"
                textArea="Aceptar" /* Acà se puede validar el tipo de boton */
                onClick={() => {
                  dispatch(resetResponse())
                  redirectEditarTrayecto()
                }}
                variant="contained"
                color="primary"
                minwidth="true"
              />
            </Grid>
          </ButtonContainer>
        </ModalWrapper>
      </Background>
    ),
    document.getElementById("root")
  );
};
