import styled from "styled-components";
import { Button, FormControl, Grid } from "@material-ui/core";
import { Form } from "react-bootstrap";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #ffffff;
  overflow: auto;
`;

export const Head = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0.813rem auto 25px auto;
  padding: 0 1rem 0 1rem;
`;

export const CustomCheckbox = styled.input`
  /* Hide the default checkbox */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  /* Set the custom checkbox size */
  width: 16px;
  height: 16px;
  /* Define the custom checkbox appearance */
  border: 2px solid #4f4184;
  border-radius: 3px;
  margin-right: 8px;
  // cursor: pointer;
  /* Change the background color when the checkbox is checked */
  &:checked {
    background-color: #4f4184;
  }
`;

export const Buton = styled(Button)`
  font-family: "Roboto", sans-serif;
  background: none;
  border: 0.063rem solid #4f4184;
  border-radius: 5px;
  font-weight: 700;
  font-size: 0.875rem;
  width: ${(props) => (props.width ? "11.188rem" : "6.25rem")};
  height: 2.5rem;
  &:active:hover:not([disabled]) {
    background: #4f4184;
    border-color: #4f4184;
  }
  &:disabled {
    background-color: #4f4184;
    border-color: #4f4184;
    opacity: 1;
  }
`;

export const StyledForm = styled(Form)`
  width: 37.5rem;
  /* height: 90%; */
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 1.313rem 1.875rem;
  margin: 0 auto 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: auto;
  @media (max-width: 630px) {
    width: 90%;
  }
`;

// export const StyledForm = styled(Form)`
// `;

export const Title = styled.p`
  font-family: "Roboto";
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1.563rem;
`;

export const Subtitle = styled.p`
  font-family: "Roboto";
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const FormGroup = styled(FormControl)`
  width: ${(props) => (props.width ? "95%" : "100%")};
  height: 4.375rem;
  margin: 0 10px;
`;

export const Divider = styled.hr`
  color: #c4c4c4;
  width: 100%;
  margin-top: 0.5rem;
`;

export const GridItem = styled(Grid)`
  display: flex;
  justify-content: ${(props) => (props.justify ? "flex-start" : "flex-end")};
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 0.625rem auto 0.625rem auto;
`;
