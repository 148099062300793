import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Container,
  Head,
  Buton,
  StyledForm,
  Div,
  Title,
  FormGroup,
  GridItem,
  ButtonContainer,
} from "./Styles";
import {
  Grid,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

/* Iconos */
import { ReactComponent as HomeIcon } from "../../../assets/img/homeIcon.svg";

/* componentes */
import { Inputs } from "../../Input/Inputs";
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";
// import { ModalPlantaApprove } from "../ModalPlantaApprove/ModalPlantaApprove";

/* dependencias */
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { editarPlanta } from "../../../Services";
import { useEffect } from "react";
import { sort } from "../../../utils";
import { addHours } from "../../../utils/DateTimeConverter/DateTimeConverter";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  nombre_planta: yup.string().required("Este campo es requerido"),
  estado: yup.string().required("Este campo es requerido"),
  camiones_M100: yup.number(),
  camiones_M3070: yup.number(),
  contrato_M100: yup.object({
    toneladas_contratadas: yup.number(),
    toneladas_diarias: yup.number(),
  }),
  contrato_M3070: yup.object({
    toneladas_contratadas: yup.number(),
    toneladas_diarias: yup.number(),
  }),
  tiempo_promedio_viaje: yup.number(),
});

export const EditarPlantaForm = () => {
  const plantas = useSelector((state) => state.infoPlantas.plantas);
  let history = useHistory();

  return (
    <Container>
      <Grid item md={12} sm={12}>
        <Head>
          <Buton
            variant="outlined"
            color="primary"
            textArea="Home"
            onClick={() => history.push("/home")}
          >
            <HomeIcon />
          </Buton>
        </Head>
      </Grid>

      <GetFormik plantas={plantas} />
    </Container>
  );
};

function GetFormik({ plantas }) {
  const [selectPlanta, setSelectPlanta] = useState(null);
  let history = useHistory();
  const dispatch = useDispatch();
  const hoyArgentina = addHours(-3, new Date()).toISOString();
  const { Name, Role, Email } = useSelector((state) => state.dataUser.user);
  function cancelar() {
    history.push("/home");
  }
  const initialValues = {
    nombre_planta: "",
    estado: "",
    camiones_M100: "",
    camiones_M3070: "",
    contrato_M100: {
      toneladas_contratadas: "",
      toneladas_diarias: "",
    },
    contrato_M3070: {
      toneladas_contratadas: "",
      toneladas_diarias: "",
    },
    tiempo_promedio_viaje: "",
  };

  return (
    <Formik
      initialValues={selectPlanta || initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) =>
        dispatch(
          editarPlanta({
            nombre_planta: values.nombre_planta,
            estado: values.estado,
            camiones_M100: values.camiones_M100,
            camiones_M3070: values.camiones_M3070,
            contrato_M100: {
              toneladas_contratadas: values.contrato_M100.toneladas_contratadas,
              toneladas_diarias: values.contrato_M100.toneladas_diarias,
            },
            contrato_M3070: {
              toneladas_contratadas:
                values.contrato_M3070.toneladas_contratadas,
              toneladas_diarias: values.contrato_M3070.toneladas_diarias,
            },
            tiempo_promedio_viaje: values.tiempo_promedio_viaje,
            user_name: Name,
            user_email: Email,
            user_rol: Role,
            datetime: hoyArgentina,
          })
        )
      }
      enableReinitialize
    >
      {(props) => (
        <VeiwFormik
          props={props}
          setSelectPlanta={setSelectPlanta}
          plantas={plantas}
          cancelar={cancelar}
        />
      )}
    </Formik>
  );
}

function VeiwFormik({ props, setSelectPlanta, plantas, cancelar }) {
  const [validated, setValidated] = useState(true);

  useEffect(() => {
    if (
      (props.values.nombre_planta &&
        props.values.estado &&
        ((props.values.camiones_M100 && props.values.camiones_M3070) ||
          (props.values.contrato_M100.toneladas_contratadas &&
            props.values.contrato_M100.toneladas_diarias) ||
          (props.values.contrato_M3070.toneladas_diarias &&
            props.values.contrato_M3070.toneladas_contratadas))) ||
      props.values.tiempo_promedio_viaje
    ) {
      setValidated(false);
    } else setValidated(true);
  }, [setValidated, props]);

  return (
    <Div onSubmit={props.handleSubmit}>
      {/* <ModalPlantaApprove /> */}
      <Grid item xs={12} md={12} sm={12}>
        <Title>Editar planta</Title>
      </Grid>
      <StyledForm>
        <Grid container>
          <Grid item xs={12} md={12} sm={12} style={{ margin: 0 }}>
            <FormGroup>
              <InputLabel id="demo-simple-select-label">
                Seleccionar planta*
              </InputLabel>
              <Select
                required
                labelId="planta"
                id="nombre_planta"
                name="nombre_planta"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.nombre_planta}
                error={props.errors.nombre_planta}
                defaultValue=""
              >
                <MenuItem value="">Selecciona una planta</MenuItem>
                {plantas &&
                  plantas?.sort(sort("nombre_planta")).map((planta, index) => (
                    <MenuItem
                      key={index}
                      onClick={() =>
                        setSelectPlanta({
                          camiones_M100: planta.camiones_asignados.M100,
                          camiones_M3070: planta.camiones_asignados.M3070,
                          ...planta,
                        })
                      }
                      value={`${planta.nombre_planta}`}
                    >
                      {planta.nombre_planta}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>{props.errors.nombre_planta}</FormHelperText>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={12} sm={12} style={{ margin: 0 }}>
            <FormGroup>
              <InputLabel id="demo-simple-select-label">
                Estado de planta*
              </InputLabel>
              <Select
                required
                labelId="estado"
                id="estado"
                name="estado"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.estado}
                error={!!props.errors.estado}
                defaultValue=""
              >
                <MenuItem value="">Selecciona un estado</MenuItem>
                <MenuItem value={"activo"}>Activo</MenuItem>
                <MenuItem value={"inactivo"}>Inactivo</MenuItem>
              </Select>
              <FormHelperText>{props.errors.estado}</FormHelperText>
            </FormGroup>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} sm={12}>
          <Title>Camiones asignados</Title>
        </Grid>
        <Grid container>
          <GridItem justify item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                required
                autoComplete="off"
                name="camiones_M100"
                id="camiones_M100"
                label="Camiones con arena M100"
                type="number"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.camiones_M100}
              />
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                required
                autoComplete="off"
                name="camiones_M3070"
                id="camiones_M3070"
                label="Camiones con arena M3070"
                type="number"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.camiones_M3070}
              />
            </FormGroup>
          </GridItem>
        </Grid>
        <Grid container>
          <GridItem justify item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                required
                autoComplete="off"
                name="tiempo_promedio_viaje"
                id="tiempo_promedio_viaje"
                label="Roundtrip promedio"
                type="number"
                step="0.01"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.tiempo_promedio_viaje}
              />
            </FormGroup>
          </GridItem>
        </Grid>
        <Grid item xs={12} md={12} sm={12}>
          <Title>Contrato M100</Title>
        </Grid>
        <Grid container>
          <GridItem justify item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                required
                autoComplete="off"
                name="contrato_M100.toneladas_contratadas"
                id="contrato_M100.toneladas_contratadas"
                label="Toneladas contratadas"
                type="number"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.contrato_M100.toneladas_contratadas}
                // error={!!props.errors.contrato_M100.toneladas_contratadas}
              />
              {/* <FormHelperText>{props.errors.contrato_M100}</FormHelperText> */}
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                required
                autoComplete="off"
                name="contrato_M100.toneladas_diarias"
                id="contrato_M100.toneladas_diarias"
                label="Toneladas diarias"
                type="number"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.contrato_M100.toneladas_diarias}
                // error={!!props.errors.contrato_M100.toneladas_diarias}
              />
              {/* <FormHelperText>{props.errors.contrato_M100.toneladas_diarias}</FormHelperText> */}
            </FormGroup>
          </GridItem>
        </Grid>
        <Grid item xs={12} md={12} sm={12}>
          <Title>Contrato M3070</Title>
        </Grid>
        <Grid container>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                required
                autoComplete="off"
                name="contrato_M3070.toneladas_contratadas"
                id="contrato_M3070.toneladas_contratadas"
                label="Toneladas contratadas"
                type="number"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.contrato_M3070.toneladas_contratadas}
                // error={!!props.errors.contratoM3070.toneladas_contratadas}
              />
              {/* <FormHelperText>{props.errors.contratoM3070.toneladas_contratadas}</FormHelperText> */}
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                required
                autoComplete="off"
                name="contrato_M3070.toneladas_diarias"
                id="contrato_M3070.toneladas_diarias"
                label="Toneladas diarias"
                type="number"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.contrato_M3070?.toneladas_diarias}
                // error={!!props.errors.contrato_M3070.toneladas_diarias}
              />
              {/* <FormHelperText>{props.errors.contrato_M3070.toneladas_diarias}</FormHelperText> */}
            </FormGroup>
          </GridItem>
        </Grid>

        <ButtonContainer container align="center">
          <ButtonPrimary
            type="button"
            textArea="Cancelar" /* Acà se puede validar el tipo de boton */
            onClick={cancelar}
            variant="outlined"
            color="primary"
            minwidth="true"
          />
          <ButtonPrimary
            type="submit"
            textArea="Guardar" /* Acà se puede validar el tipo de boton */
            variant="contained"
            color="primary"
            minwidth="true"
            disabled={validated}
            // onClick={(event) => handleClick(event)}
          />
        </ButtonContainer>
      </StyledForm>
    </Div>
  );
}
