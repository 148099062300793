import {
  GET_ALERTAS,
  RESET_ALERTAS,
  CURRENT_DESCARGAR,
  SET_CURRENT_TAGS
} from '../constants/actionsTypes';

export const getAlertas = (payload) => {
  const alertas = Object.keys(payload?.alertas)

  if (alertas.length > 0) {
    payload?.alertas?.alertasAltas.map((item) => Object.assign(item, {...item, name:'Altas', tipoAlerta: item.tipo_alerta}))
    payload?.alertas?.alertasMedias.map((item) => Object.assign(item, {...item, name:'Medias', tipoAlerta: item.tipo_alerta}))
    payload?.alertas?.alertasBajas.map((item) => Object.assign(item, {...item, name:'Bajas', tipoAlerta: item.tipo_alerta}))
  }

  return {
    type: GET_ALERTAS,
    payload: payload?.alertas
  };
};

export const resetAlertas = (payload) => (
  {
    type: RESET_ALERTAS,
    payload
  }
)

export const currentDescargar = (payload) => (
  {
    type: CURRENT_DESCARGAR,
    payload
  }
)

export const setCurrentTags = (payload) => (
  {
    type: SET_CURRENT_TAGS,
    payload
  }
)