export const usersRoles = {
  Brent: "Brent",
  SupplyChain: "SupplyChain",
  CoordBrent: "CoordBrent",
  SupervAluvional: "SupervAluvional",
  CoordAluvional: "CoordAluvional",
  Pedrolga: "Pedrolga",
  Weber: "Weber",
};

export const AuthRoutes = {
  home: "/home",
  editarTrayecto: "/editarTrayecto",
  editarTrayectoForm: "/editarTrayectoForm/:id",
  ingresarEtapa: "/ingresarEtapa",
  editarEtapas: "/editarEtapas",
  editaPlanta: "/editaPlanta",
  habilitarPlanta: "/habilitarPlanta",
  asignarPlanta: "/asignarPlanta",
  escenariosProyeccion: "/escenariosProyeccion",
  aluvional: "/aluvional",
  editaPlantaCls: "/editaPlantaCls",
  notFound: "/not-found",
};

export const NonAuthRoutes = {
  login: "/aluvional",
};
