import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Grid, InputLabel, FormHelperText, TextField, Select, MenuItem } from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  Span,
  Div,
  GridItem,
  StyledForm
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { resetInfoPad } from "../../actions/infoPadActions";

import { infoPad, notificarLLegadaPad } from "../../Services";

/* Validacion de inputs con Formik */
/* const validationSchema = yup.object({
  placa: yup.string().required("Este campo es requerido"),
  estadoCajas: yup.string().required("Este campo es requerido"),
}); */

export const ModalCajasDisponiblesPad = ({ onClose }) => {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(true)
  const  { camiones_inactivos }= useSelector((state) => state.camionesInactivos);

  const formik = useFormik({
    initialValues: {
      placa: "",
      estadoCamion: "",
    },
  
    // validationSchema: validationSchema,
  
    onSubmit: (values) => {
      dispatch(notificarLLegadaPad({
        placa: values.placa,
        estado_camion: values.estadoCamion.toLowerCase() === 'transito llenas'
          ? 'transito_llenas'
          : 'transito_vacio'
      }));
      onClose()
    },
  });

  useEffect(() => {
    dispatch(infoPad())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = () => {
    onClose()
    dispatch(resetInfoPad())
  }

  useEffect(() => {
    if (formik.values.estadoCamion && formik.values.placa) {
      setValidated(false)
    } else setValidated(true)
  }, [formik, setValidated])

  return ReactDOM.createPortal(
    <Background> 
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Cajas disponibles en pad</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
          <p>
            Seleccione la placa del camión inhabilitado, junto con el estado
            de las cajas que ingresarán al pad.
          </p>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="demo-simple-select-label">Seleccione una placa*</InputLabel>
                <Select
                  labelId="Seleccione una placa*"
                  id="camion_placa"
                  name="placa"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.placa}
                  error={formik.errors.placa}
                >
                  <MenuItem value="">Seleccione un placa*</MenuItem>
                  {
                    camiones_inactivos && 
                    camiones_inactivos?.map((item, index) => (
                      <MenuItem key={index} value={item.placa}> {item.placa} </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{formik.errors.placa}</FormHelperText>
              </FormGroup>
            </GridItem>
            
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="demo-simple-select-label">Estado de cajas*</InputLabel>
                <Select
                  labelId="Estado de cajas"
                  id="estado_cajas"
                  name="estadoCamion"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.estadoCamion}
                  error={formik.errors.estadoCamion}
                >
                  <MenuItem value="">Seleccione un tipo de arena</MenuItem>
                  <MenuItem value={"Transito llanas"}>Transito llenas</MenuItem>
                  <MenuItem value={"Transito vacías"}>Transito vacías</MenuItem>
                </Select>
                <FormHelperText>{formik.errors.estadoCamion}</FormHelperText>
              </FormGroup>
            </GridItem>

            <Grid container md={12} sm={8} justify="center">
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    type="button"
                    textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                    onClick={() => handleClose()}
                    variant="outlined"
                    color="primary"
                    minwidth="true"
                  />
                </ButtonContainer>
              </Grid>
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    disabled={validated}
                    type="submit"
                    textArea="Guardar"
                    variant="contained"
                    color="primary"
                    mright="true"
                  />
                </ButtonContainer>
              </Grid>
            </Grid>
          </Grid>
        </StyledForm>

      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};

/**
 *  <GridItem item md={6}>
      <FormGroup>
        <TextField
          type="number"
          id="cantidad_cajas"
          name="cantidad_cajas"
          label="Cantidad de cajas*"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.cantidad_cajas}
          error={!!formik.errors.cantidad_cajas}
        />
        <FormHelperText>{formik.errors.cantidad_cajas}</FormHelperText>
      </FormGroup>
    </GridItem>

     <Div>
          <Span>Total de cajas: {Info_de_indicadores.total_cajas_pad}</Span>
          <Span>Cajas llenas: {Info_de_indicadores?.cajas_disponibles}</Span>
        </Div>
 */