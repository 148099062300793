import {
  GET_ALERTAS_CLS,
  RESET_ALERTAS_CLS,
  CURRENT_DESCARGAR_CLS,
  SET_CURRENT_TAGS_CLS,
} from "../../constants/actionsTypes";

export const getAlertasCls = (payload) => {
  const alertas = Object.keys(payload?.alertas);

  if (alertas.length > 0) {
    payload?.alertas?.alertasAltas.map((item) =>
      Object.assign(item, {
        ...item,
        name: "Altas",
        tipoAlerta: item.tipo_alerta,
      })
    );
    payload?.alertas?.alertasMedias.map((item) =>
      Object.assign(item, {
        ...item,
        name: "Medias",
        tipoAlerta: item.tipo_alerta,
      })
    );
    payload?.alertas?.alertasBajas.map((item) =>
      Object.assign(item, {
        ...item,
        name: "Bajas",
        tipoAlerta: item.tipo_alerta,
      })
    );
  }

  return {
    type: GET_ALERTAS_CLS,
    payload: payload?.alertas,
  };
};

export const resetAlertas = (payload) => ({
  type: RESET_ALERTAS_CLS,
  payload,
});

export const currentDescargar = (payload) => ({
  type: CURRENT_DESCARGAR_CLS,
  payload,
});

export const setCurrentTags = (payload) => ({
  type: SET_CURRENT_TAGS_CLS,
  payload,
});
