import styled from "styled-components";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Acordeon = styled.button`
  background-color: #fff;
  color: #444;
  cursor: pointer;
  padding: 9px 11px;
  width: 100%;
  margin-bottom: 15px;
  border: none;
  border-bottom: ${(props) =>
    props.border ? "1px solid #EF4C4C" : "1px solid #414042"};
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
`;
export const Panel = styled.div`
  /* padding: 13px 19px; */
  background-color: white;
  transition: max-height 0.2s ease-out;
  /* display: none; */
`;
export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(50px - 9px);
`;

export const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin: 0 8px 0 0;
`;

export const TitlePriority = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => (props.colorTitle ? "#EF4C4C" : "#414042")};
  margin: 0 8px 0 0;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  margin-right: 11px;
  width: 23px;
  height: 23px;
  color: ${(props) => (props.color ? "#EF4C4C" : "#414042")};
  & svg {
    font-size: 23px;
    transform: ${(props) => (props.active ? "rotate(180deg)" : "rotate(0deg)")};
    transition: transform 0.5s linear;
  }
`;
export const IconArrow = styled(FontAwesomeIcon)``;

export const Divider = styled.hr`
  width: 100%;
  background: ${(props) => (props.color ? "#EF4C4C" : "#414042")};
  margin: 5px 0;
`;

export const PanelHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px calc(19px - 11px);
  & p {
    margin: 0;
    font-weight: 500;
  }
  & span {
    font-weight: 300;
  }
`;

export const PanelText = styled.div`
  padding: 0 calc(19px - 11px);
  margin-bottom: calc(14px - 7px);
  & p {
    margin: 0;
    font-weight: 500;
  }
  & span {
    font-weight: 300;
  }
`;
