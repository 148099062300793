import styled from "styled-components";

export const Background = styled.div`
  background: rgba(0, 0, 0, 0.4);
  display: block;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 8;
  top: 0;
  left: 0;
`;

export const ModalWrapper = styled.div`
  background: #ffffff;
  border-radius: 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
  display: table;
  margin: 0 auto;
  width: 90%;
  max-width: 400px;
  height: auto;
  overflow: hidden;
  padding: 25px;
  z-index: 10;
  position: relative;
  top: 20%;
  & p {
    font-weight: 400;
    font-size: 12px;
    color: #454545;
    margin-top: ${(props) => (props.mtop ? "18px" : "0")};
    margin-bottom: 0.75rem;
  }
`;

export const HeadModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  h2 {
    color: #414042;
    font-weight: 700;
    font-size: 20px;
    margin: 0;
  }
  svg {
    font-size: 30px;
    color: #4f4184;
    cursor: pointer;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
