import { GET_CAMIONES_INACTIVOS, RESET_CAMIONES_INACTIVOS } from '../constants/actionsTypes'

const initialState = []

export const camionesInactivosReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_CAMIONES_INACTIVOS: {
      return { ...state, ...payload }
    }
    case RESET_CAMIONES_INACTIVOS:
      return { camionesInactivos: initialState }
    default:
      return state
  }
}