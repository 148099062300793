import { GET_LOGIN, SET_LOGOUT, RESET_LOGIN } from "../constants/actionsTypes";

const initialState = {
  user: {
    Name: "",
    Role: "",
    Email: "",
    Token: "",
    Type: "",
  },
};

export const loginReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_LOGIN: {
      window.sessionStorage.setItem("Token", payload.Token);
      return { ...state, user: payload };
    }
    case RESET_LOGIN: {
      return initialState;
    }
    case SET_LOGOUT: {
      window.sessionStorage.clear();
      return initialState;
    }
    default:
      return state;
  }
};
