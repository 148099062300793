import React from 'react'
import { useHistory } from 'react-router'
import ButtonPrimary from '../Components/ButtonPrimary/ButtonPrimary'

export const NotFound = () => {
  const history = useHistory()

  const onBackClick = () => {
    const isGetAuth = !!window.sessionStorage.getItem('Token')
    if(isGetAuth) {
      history.push('/home')
    } else {
      history.push('/')
    }
  }

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', 
      flexDirection: 'column',
      }}>
      <h1>Dale atrás</h1>
      <ButtonPrimary
        textArea="Atras"
        variant="contained"
        color="primary"
        mright="true"
        onClick={() => onBackClick()}
      />
    </div>
  )
}
