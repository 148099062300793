import React from 'react';
import { Route, Redirect } from 'react-router-dom';



/* Componente que valida la sesion de usuario mediante el token enviado desde del backend */
/*
 * @param  {Component} component: Recibe el componente
 * @param  {} ...rest: parametros de router-dom que vienen del App.js
 */
export const PrivateRoutes = ({ component: Component, path, exact = false, requiredRoles, children }) => {
  // const getAuth = useSelector(state => state.dataUser.user ? state.dataUser.user.Token : null)
  const isGetAuth = !!sessionStorage.getItem('Token')
  const user = JSON.parse(sessionStorage.getItem('state'))
  const rol = user?.dataUser?.user ? user?.dataUser?.user?.Role : ''
  const userHasRequiredRole = requiredRoles.includes(rol)
  return (
    <>
      <Route
        exact={exact}
        path={path}
        render={(props) =>
          isGetAuth && userHasRequiredRole ? (
            <Component {...props} />
          ) : (
            <Redirect to={{
              pathname: userHasRequiredRole 
                ? 'not-found' 
                : '/',
              state: {
                requestePath: path
              }
            }} />
          )}
      >{children}</Route>
    </>
  );
};
