import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

/* Componentes */
import { Map2 } from "../Components/Aluvional/Map/Map2";
import Indicadores from "../Components/Aluvional/Indicadores/Indicadores";
import Header from "../Components/Aluvional/Header/Header";
import HeaderPad from "../Components/Aluvional/HeaderPad/HeaderPad";
import { ModalPlanDespacho } from "../Components/Aluvional/ModalPlanDespacho/ModalPlanDespacho";
import { ModalFacturacion } from "../Components/Aluvional/ModalFacturacion/ModalFacturacion";
import { OptionsRight } from "../Components/Aluvional/OptionsRight/OptionsRight";
import { Sidebar } from "../Components/Sidebar/Sidebar";
// import { ModalEstadoRTForm } from "../Components/ModalEstadoRTForm/ModalEstadoRTForm";
import FullPageLoader from "../utils/FullPageLoader/FullPageLoader";
import ModalApproveCls from "../utils/ModalApproveCls/ModalApproveCls";
import { __updateIndicadoresCls } from "../Services/update";

import {
  estadoCamionesCls,
  indicadoresCls,
  alertasCls,
  getPlantasCls,
  getKeyGoogleMaps,
  // estadoRT,
} from "../Services";

const Container = styled(Grid)`
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  @media (max-width: 768px) {
    overflow: auto;
    display: block;
  }
`;
const HomeContainer = styled(Grid)`
  position: relative;
`;

export const Aluvional = () => {
  const indicadoresCLS = useSelector((state) => state.indicadoresCls);
  const plantas = useSelector((state) => state.infoPlantasCls.plantas);
  const isLoading = useSelector((state) => state.loading);
  const { Role } = useSelector((state) => state.dataUser.user);

  // const [showModalEstadoRTForm, setShowModalEstadoRTForm] = useState(false);
  const [showModalPlanDespacho, setShowModalPlanDespacho] = useState(false);

  const [showModalPeriodoFacturacion, setShowModalPeriodoFacturacion] =
    useState(false);

  const [camionCantera, setCamionCantera] = useState(true);
  const [transito, setTransito] = useState(true);
  const [secado, setSecado] = useState(true);
  const [lavado, setLavado] = useState(true);
  const [ws, setWs] = useState(true);
  const [sinGeotab, setSinGeotab] = useState(true);
  const [showAlert, setShowAlert] = useState(true);

  const [showCamiones, setShowCamiones] = useState(false);

  const [showPlantas, setShowPlantas] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getKeyGoogleMaps());
    dispatch(indicadoresCls());
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(estadoCamionesCls());
    }, 2000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(alertasCls());
      if (Role !== "Pedrolga") {
        dispatch(getPlantasCls());
      }
    }, 2500);
  }, []);

  // const openModalToggleEstadoRTForm = () => {
  //   setShowModalEstadoRTForm(!showModalEstadoRTForm);
  // };

  /*  */
  const openModalTogglePlanDespacho = () => {
    setShowModalPlanDespacho(!showModalPlanDespacho);
  };

  const openModalTogglePeriodoFacturacion = () => {
    setShowModalPeriodoFacturacion(!showModalPeriodoFacturacion);
  };
  const toggleCantera = () => {
    setCamionCantera(!camionCantera);
  };
  const toggleTransito = () => {
    setTransito(!transito);
  };
  const toggleSecado = () => {
    setSecado(!secado);
  };
  const toggleLavado = () => {
    setLavado(!lavado);
  };
  const toggleWs = () => {
    setWs(!ws);
  };
  const toggleSinGeotab = () => {
    setSinGeotab(!sinGeotab);
  };
  /* Options Right */
  const openMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  const openAlertToggle = () => {
    setShowAlert(true);
    setShowMenu(false);
    setShowCamiones(false);
    setShowPlantas(false);
  };

  const openCamionesToggle = () => {
    setShowCamiones(true);
    setShowMenu(false);
    setShowAlert(false);
    setShowPlantas(false);
  };

  const openPlantasToggle = () => {
    setShowPlantas(true);
    setShowMenu(false);
    setShowAlert(false);
    setShowCamiones(false);
  };

  const toEditarPlanta = () => {
    history.push("/editaPlantaCls");
  };

  useEffect(() => {
    const idUpdateData = setInterval(() => {
      dispatch(__updateIndicadoresCls());
      dispatch(estadoCamionesCls());
      // dispatch(estadoRT());
      dispatch(alertasCls());
      dispatch(getPlantasCls());
    }, 180000);

    return () => {
      clearInterval(idUpdateData);
    };
  }, []);

  return (
    <HomeContainer container>
      <Grid item xs={12} md={3} sm={3}>
        <Sidebar />
        <HeaderPad setShowModalPlanDespacho={setShowModalPlanDespacho} />
      </Grid>
      <Grid item xs={12} md={9} sm={9}>
        <Header
        // setShowModalEstadoRTForm={setShowModalEstadoRTForm}
        />
      </Grid>
      <Container>
        <Grid item xs={12} md={3}>
          <Indicadores indicadoresCls={indicadoresCLS} />
        </Grid>
        <Grid item xs={12} md={6} className="grid-map2">
          <Map2 />
        </Grid>
        <Grid item xs={12} md={3}>
          <OptionsRight
            Role={Role}
            toggleCantera={toggleCantera}
            toggleTransito={toggleTransito}
            toggleSecado={toggleSecado}
            toggleLavado={toggleLavado}
            toggleWs={toggleWs}
            toggleSinGeotab={toggleSinGeotab}
            camionCantera={camionCantera}
            transito={transito}
            secado={secado}
            lavado={lavado}
            ws={ws}
            sinGeotab={sinGeotab}
            openMenuToggle={openMenuToggle}
            openAlertToggle={openAlertToggle}
            openCamionesToggle={openCamionesToggle}
            openPlantasToggle={openPlantasToggle}
            toEditarPlanta={toEditarPlanta}
            showCamiones={showCamiones}
            showAlert={showAlert}
            showPlantas={showPlantas}
            showMenu={showMenu}
            plantas={plantas}
            setShowModalPeriodoFacturacion={setShowModalPeriodoFacturacion}
          />
        </Grid>
        <ModalApproveCls />
        <FullPageLoader isLoading={isLoading} />
      </Container>

      {showModalPlanDespacho && (
        <ModalPlanDespacho onClose={openModalTogglePlanDespacho} />
      )}
      {showModalPeriodoFacturacion && (
        <ModalFacturacion onClose={openModalTogglePeriodoFacturacion} />
      )}

      {/* {showModalEstadoRTForm && (
        <ModalEstadoRTForm onClose={openModalToggleEstadoRTForm} />
      )} */}
    </HomeContainer>
  );
};
