import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  FormHelperText,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  StyledForm,
} from "./Style";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { deshabilitarCamionPost } from "../../../Services";
import { resetDeshabilitarCamion } from "../../../actions/deshabilitarCamionActions";
import { sort } from "../../../utils";
import { Inputs } from "../../Input/Inputs";
import { addHours } from "../../../utils/DateTimeConverter/DateTimeConverter";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  placa: yup.string().required("Este campo es requerido"),
  motivo: yup.string().required("Este campo es requerido"),
  motivo_cambio_otros: yup.string().required("Este campo es requerido"),
});

export const ModalDeshabilitarCamion = ({ onClose }) => {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(true);
  const camiones = useSelector((state) => state.deshabilitarCamion.camiones);
  const hoyArgentina = addHours(-3, new Date()).toISOString();
  const { Name, Role, Email } = useSelector((state) => state.dataUser.user);
  const motives = [
    "Problema en Planta de Despacho",
    "Problema en Ruta (corte de ruta)",
    "Potencial desbalance (sistema recomendador fallando)",
    "Orden de Coordinación VOG (compromiso comercial/operativo con Plantas)",
    "Otros",
  ];

  const formik = useFormik({
    initialValues: {
      placa: "",
      motivo: "",
      motivo_cambio_otros: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        deshabilitarCamionPost({
          placa: values.placa,
          motivo:
            formik.values.motivo.toLowerCase() === "otros"
              ? formik.values.motivo_cambio_otros
                ? formik.values.motivo_cambio_otros
                : "Otros"
              : formik.values.motivo,
          user_name: Name,
          user_email: Email,
          user_rol: Role,
          datetime: hoyArgentina,
        })
      );
      onClose();
    },
  });

  useEffect(() => {
    if (formik.values.motivo.toLowerCase() !== "otros") {
      formik.values.motivo_cambio_otros = "";
    }
  }, [formik]);

  const handleClose = () => {
    dispatch(resetDeshabilitarCamion());
    onClose();
  };

  useEffect(() => {
    if (formik.values.placa && formik.values.motivo) {
      setValidated(false);
    } else setValidated(true);
  }, [setValidated, formik]);

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Deshabilitar camión</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid item md={12}>
            <FormGroup>
              <InputLabel id="demo-simple-select-label">
                Seleccionar camión*
              </InputLabel>
              <Select
                labelId="Destino"
                tipo="text"
                id="placa"
                name="placa"
                label="Selecciona camión*"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.placa}
                error={!!formik.errors.placa}
              >
                <MenuItem value="">Selecciona un camión</MenuItem>
                {camiones?.sort(sort("plate"))?.map((camion, index) => (
                  <MenuItem key={index} value={`${camion.plate}`}>
                    {camion.plate}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.placa}</FormHelperText>
            </FormGroup>
          </Grid>
          <Grid item md={12}>
            <FormGroup>
              <InputLabel id="demo-simple-select-label">Motivo*</InputLabel>
              <Select
                labelId="Destino"
                id="motivo"
                name="motivo"
                label="Motivo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.motivo}
                error={!!formik.errors.motivo}
              >
                <MenuItem value="">Selecciona un motivo</MenuItem>
                {motives?.map((item, index) => (
                  <MenuItem key={index} value={`${item}`}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.motivo}</FormHelperText>
            </FormGroup>
          </Grid>

          {formik?.values?.motivo?.toLowerCase() === "otros" && (
            <FormGroup>
              <Inputs
                label="Otros motivos"
                name="motivo_cambio_otros"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.motivo_cambio_otros}
                error={!!formik.errors.motivo_cambio_otros}
              />
              <FormHelperText>
                {formik.errors.motivo_cambio_otros}
              </FormHelperText>
            </FormGroup>
          )}
          <ButtonContainer align="center">
            <Grid>
              <ButtonPrimary
                textArea="Cancelar"
                /* onClick={} */
                variant="outlined"
                color="primary"
                mright="true"
                onClick={() => handleClose()}
              />
            </Grid>
            <Grid>
              <ButtonPrimary
                disabled={validated}
                type="submit"
                textArea="Guardar"
                variant="contained"
                color="primary"
                mright="true"
              />
            </Grid>
          </ButtonContainer>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
