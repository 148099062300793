export const ConvertDateTime = (date, time) => {
  if (date === undefined || time === undefined) {
    return ["", ""];
  }

  const [year, month, day] = date.split("-");
  const [hour, minutes, seconds] = time.split(":");

  const dateUTC = new Date(year, month - 1, day, hour, minutes, seconds);

  return [
    dateUTC.toLocaleDateString({ timeZone: "America/Argentina/Buenos_Aires" }),
    dateUTC.toLocaleTimeString({ timeZone: "America/Argentina/Buenos_Aires" }),
  ];
};

export const addHours = (numOfHours, date) => {
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

  return date;
};
