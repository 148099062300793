import axios from "axios";
import { getConsultarEtapa } from "../actions/consultarEtapasActions";
import { getEstadoCamiones } from "../actions/estadoCamionesActions";
import { getIndicadores, getProyecciones } from "../actions/indicadoresActions";
import { getProyeccionesEscenarios } from "../actions/escenariosActions";
import { getUser } from "../actions/loginActions";
import { getRecomendador } from "../actions/recomendadorActions";
import { getAlertas } from "../actions/alertasActions";
import { getInfoPlantas } from "../actions/infoPlantasActions";
import {
  getDeshabilitarCamion,
  resetDeshabilitarCamion,
} from "../actions/deshabilitarCamionActions";
import { setLoading } from "../actions/loadingActions";

import { URL_API } from "../constants/apis";

import { validateError } from "./validateError";
import { validateSuccess } from "./validateSucces";
import { getPlantas } from "../actions/plantasActions";
import { getPlantasHistoricas } from "../actions/plantasHistoricasActions";
import { getCamionesInactivos } from "../actions/camionesInactivosActions";
import { getPlantasInactivos } from "../actions/plantasInactivosActions";
import { getInfoPad } from "../actions/infoPadActions";
import { blob } from "d3";
import { getEstadoRT } from "../actions/estadortActions";
import { getCamionesHistoricos } from "../actions/camionesHistoricosActions";
import { getIndicadoresCls } from "../actions/cls/indicadoresClsActions";
import { getPlanDespachoCls } from "../actions/cls/planDespachoClsActions";
import { getEstadoCamionesCls } from "../actions/cls/estadoCamionesClsActions";
import { getAlertasCls } from "../actions/cls/alertasClsActions";
import { getInfoPlantasCls } from "../actions/cls/infoPlantasClsActions";
import { getPeriodoFacturacionCls } from "../actions/cls/periodoFacturacionClsActions";
import { getKey } from "../actions/googleMapsActions";

let TOKEN = "";
let axiosAuth = "";

/* Inicializa la configuracion para peticiones con header authorization */
const initAxiosAuth = () => {
  TOKEN = window.sessionStorage.getItem("Token");
  axiosAuth = axios.create({
    withCredentials: false,
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
};

/* Valida que exita el token para iniciar la configuracion del axios */
if (window.sessionStorage.getItem("Token")) {
  initAxiosAuth();
}

/* Inicio de sesion */
const qs = require("qs");
export const login = (values) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios
      .post(
        `${URL_API}login`,
        qs.stringify({
          ...values,
        }),
        {
          "content-type": "application/x-www-form-urlencoded",
        }
      )
      .then(function (response) {
        const { data } = response;
        dispatch(getUser(data));
        dispatch(initAxiosAuth());
      })
      .catch(function (error) {
        dispatch(validateError(error));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

export const getKeyGoogleMaps = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}googlemaps`)
      .then(function (response) {
        const { data } = response;
        dispatch(getKey(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/"));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};
/* Este servicio trae la data de los indicadores 
  pd: aun falta que back mande informacion para pintar en la UI
*/
export const indicadores = (horas) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}indicadores?horas=${horas}`)
      .then(function (response) {
        const { data } = response;
        dispatch(getIndicadores(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/"));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

export const proyecciones = (horas) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}proyecciones`)
      .then(function (response) {
        const { data } = response;
        dispatch(getProyecciones(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/"));
      })
      .finally(function () {
        dispatch(setLoading(false));
      });
  };
};

export const escenariosProyecciones = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    axiosAuth
      .post(`${URL_API}escenarios_proyecciones`, { ...data })
      .then(function (response) {
        const { data } = response;

        dispatch(getProyeccionesEscenarios(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/"));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};
/* Este servicio trae todo el estado de los camiones, para pintarlos en el mapa y en el panel de camiones */
export const estadoCamiones = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}estadocamiones`)
      .then(function (response) {
        const { data } = response;
        dispatch(getEstadoCamiones(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/home"));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Este Servicio recibe una placa y te arroja una recomendacion */
export const recomendadorGet = (placa) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}recomendador?placa=${placa}`)
      .then(function (response) {
        const { data } = response;
        dispatch(getRecomendador(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/home"));
      })
      .finally(function () {
        dispatch(setLoading(false));
      });
  };
};

/* Este servicio hace una recomendación  */
/**
 * @param  {} data => es el json que espera el back.
 */
export const recomendadorPost = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}recomendador`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, "/home", "recomendacion"));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/home"));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Este servicio modifica un trayecto para una placa en especifico */
/**
 * @param  {} data => es la estructura que neceista el backend para enviar la peticion
 */
export const modificarTrayecto = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}modificartrayecto`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, "", "trayectoApp"));
      })
      .catch(function (error) {
        dispatch(
          validateSuccess(
            {
              status: 200,
              data: {},
              type: "",
              message: "",
              show: false,
              redirect: "",
            },
            "",
            "trayectoErr"
          )
        );
      })
      .finally(function () {
        dispatch(setLoading(false));
      });
  };
};

/* Este servicio consulta las etapas que hay disponibles y las pinta en editar etapas (hay un problema al ingresar a esa pagina un error de autenticacion, revisalo con back) */
export const consultarEtapas = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}consultaretapas`)
      .then(function (response) {
        const { data } = response;
        dispatch(getConsultarEtapa(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/home"));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Este servicio crea nuevas etapas  */
/**
 * @param  {} data => la estructura que necesita back para poder ingresar una etapa
 */
/* export const ingresarEtapas = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}ingresaretapa`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, '/home'));
        // dispatch(postModificarTrayecto(data))
      })
      .catch(function (error) {
        dispatch(validateError(error, '/home'));
      })
      .finally(function () {
        dispatch(setLoading(false));
      });
  };
}; */

/* Este servicio edita las etapas  */
/**
 * @param  {} data => la estructura que necesita el back para editar una etapa
 */
export const editarEtapas = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .put(`${URL_API}editaretapas`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, ""));
        // dispatch(postModificarTrayecto(data))
      })
      .catch(function (error) {
        dispatch(validateError(error, ""));
      })
      .finally(function () {
        dispatch(setLoading(false));
      });
  };
};

/* Este servicio habilita un camion  */
/**
 * @param  {} data => es el numero de la placa para habilitar el camion
 */
export const habilitarCamion = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}habilitarcamion`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, "/home", "habilitarCamion"));
        // dispatch(postModificarTrayecto(data))
      })
      .catch(function (error) {
        dispatch(validateError(error, "/home"));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Este servicio deshabilita un camion  */
/**
 * @param  {} data => es la placa y el motivo por el cual deshabilitan un camion
 */
export const deshabilitarCamionPost = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}deshabilitarcamionPost`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, "/home", "deshabilitarCamion"));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/home"));
      })
      .finally(function () {
        dispatch(setLoading(false));
        dispatch(resetDeshabilitarCamion());
        // always executed
      });
  };
};

/* Este servicio trae la lista de los camiones que estan deshabilitados para habilitarlos */
export const camionesInactivos = () => {
  return (dispatch) => {
    axiosAuth
      .get(`${URL_API}camionesinactivos`)
      .then(function (response) {
        const { data } = response;
        dispatch(getCamionesInactivos(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/home"));
      });
  };
};

/* Este servicio trae la lista de los camiones que estan habilitados para su deshabilitacion */
export const deshabilitarCamionGet = () => {
  return (dispatch) => {
    axiosAuth
      .get(`${URL_API}deshabilitarcamionget`)
      .then(function (response) {
        const { data } = response;
        dispatch(getDeshabilitarCamion(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/home"));
      });
  };
};

export const camionesHistoricosGet = () => {
  return (dispatch) => {
    axiosAuth
      .get(`${URL_API}historicaltrucks`)
      .then(function (response) {
        const { data } = response;
        dispatch(getCamionesHistoricos(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/home"));
      });
  };
};

export const camionesHistoricosPost = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}selecttrucks`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, "/home"));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/home"));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Este servicio agrega cajas al pad  */
/**
 * @param  {} data => la estructura que necesita back para agregar las cajas
 */
export const agregarCajasPad = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}agregarcajaspad`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, "/home"));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/home"));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Este servicio edita las etapas por dia  */
/**
 * @param  {} values => estructura que necesita el back para editar las etapas
 */
export const editarEtapasDia = (values) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}editaretapaspordia`, { ...values })
      .then(function (response) {
        dispatch(validateSuccess(response, "/home"));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/home"));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Este servicio trae la lista de los camiones que estan deshabilitados para habilitarlos */
export const plantasInactivos = () => {
  return (dispatch) => {
    axiosAuth
      .get(`${URL_API}plantasinactivos`)
      .then(function (response) {
        const { data } = response;
        dispatch(getPlantasInactivos(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/home"));
      });
  };
};

/* Este servicio trae la informacion de las plantas para luego editarlas  */
export const plantaGet = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}planta`)
      .then(function (response) {
        const { data } = response;
        dispatch(getPlantas(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/home"));
      })
      .finally(function () {
        dispatch(setLoading(false));
      });
  };
};

/* Este servicio habilita una planta  */
/**
 * @param  {} data => estructura que necesita back para habilitar una planta
 */
export const habilitarPlanta = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}habilitarplanta`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, ""));
      })
      .catch(function (error) {
        dispatch(validateError(error, ""));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Este servicio edita una planta  */
/**
 * @param  {} data => Estructuar que necesita back para editar una planta
 */
export const editarPlanta = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    axiosAuth
      .post(`${URL_API}editarplanta`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, ""));
        // dispatch(postModificarTrayecto(data))
      })
      .catch(function (error) {
        dispatch(validateError(error, ""));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

export const plantasHistoricasGet = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}historicalquarry`)
      .then(function (response) {
        const { data } = response;
        dispatch(getPlantasHistoricas(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/home"));
      })
      .finally(function () {
        dispatch(setLoading(false));
      });
  };
};

export const postPlantasHistoricas = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}selectquarry`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, ""));
      })
      .catch(function (error) {
        dispatch(validateError(error, ""));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};
/* Este servicio trae las alertas para luego mostrarlas en el panel alertas 
  Aun no està conectado este servicio con back
*/
export const alertas = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}alertas`)
      .then(function (response) {
        const { data } = response;
        dispatch(getAlertas(data));
      })
      .catch(function (error) {
        dispatch(validateError(error));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Este servicio trae la informacion de las plantas que estan activas */

export const infoplantas = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}infoplantas`)
      .then(function (response) {
        const { data } = response;
        dispatch(getInfoPlantas(data));
      })
      .catch(function (error) {
        dispatch(validateError(error));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Este servicio trae la informacion de las plantas que estan activas */
export const infoPad = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}infopad`)
      .then(function (response) {
        const { data } = response;
        dispatch(getInfoPad(data));
      })
      .catch(function (error) {
        dispatch(validateError(error));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

export const estadoRT = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}estadort`)
      .then(function (response) {
        dispatch(getEstadoRT(response.status));
      })
      .catch(function (error) {
        dispatch(getEstadoRT(error.response.status));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};
export const EstadoRTPost = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}reiniciarmodelo`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, ""));
      })
      .catch(function (error) {
        dispatch(validateError(error, ""));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};
export const notificarLLegadaPad = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}notificarllegadapad`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, ""));
      })
      .catch(function (error) {
        dispatch(validateError(error, ""));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

export const actualizarCajasPad = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .put(`${URL_API}cajaspad`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, ""));
      })
      .catch(function (error) {
        dispatch(validateError(error, ""));
      })
      .finally(function () {
        dispatch(setLoading(false));
      });
  };
};

export const habilitarPad = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}altapad`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, ""));
      })
      .catch(function (error) {
        dispatch(validateError(error, ""));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};
export const FinalizarPad = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}finalizarpad`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, ""));
      })
      .catch(function (error) {
        dispatch(validateError(error, ""));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

export const EditarPad = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}editarpad`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, ""));
      })
      .catch(function (error) {
        dispatch(validateError(error, ""));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

export const Reentrenamiento = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}reentrenarmodelo`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, ""));
      })
      .catch(function (error) {
        dispatch(validateError(error, ""));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Aluvional */

export const indicadoresCls = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}cls/indicadores`)
      .then(function (response) {
        const { data } = response;
        dispatch(getIndicadoresCls(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/"));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

export const planDespachoGet = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}cls/planDespachoGet`)
      .then(function (response) {
        const { data } = response;
        dispatch(getPlanDespachoCls(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/"));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

export const planDespachoPost = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}cls/planDespachoPost`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, ""));
      })
      .catch(function (error) {
        dispatch(validateError(error, ""));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

export const estadoCamionesCls = (horas) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}cls/estadocamionesCLS`)
      .then(function (response) {
        const { data } = response;
        dispatch(getEstadoCamionesCls(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/"));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

export const alertasCls = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}cls/alertas`)
      .then(function (response) {
        const { data } = response;
        dispatch(getAlertasCls(data));
      })
      .catch(function (error) {
        dispatch(validateError(error));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

export const editarPlantaCls = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}cls/editarPlantaCLSpost`, { ...data })
      .then(function (response) {
        dispatch(validateSuccess(response, ""));
      })
      .catch(function (error) {
        dispatch(validateError(error, ""));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

export const getPlantasCls = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}cls/editarPlantaCLSget`)
      .then(function (response) {
        const { data } = response;
        dispatch(getInfoPlantasCls(data));
      })
      .catch(function (error) {
        dispatch(validateError(error));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

export const getPeriodoFacturacion = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}cls/periodoFacturacionGET`)
      .then(function (response) {
        const { data } = response;
        dispatch(getPeriodoFacturacionCls(data));
      })
      .catch(function (error) {
        dispatch(validateError(error));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

export const postPeriodoFacturacion = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}cls/periodoFacturacionPOST`, { ...data })
      .then(function (response) {
        const fileUrl = response.data.url;
        const anchor = document.createElement("a");
        anchor.href = fileUrl;
        anchor.download = "facturacion_proveedor";
        anchor.click();
      })
      .catch(function (error) {
        dispatch(validateError(error));
      })
      .finally(function () {
        dispatch(setLoading(false));

        // always executed
      });
  };
};

export const postReporteCamionesDetenidos = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}periodoDetencion`, { ...data })
      .then(function (response) {
        const fileUrl = response.data.url;
        const anchor = document.createElement("a");
        anchor.href = fileUrl;
        anchor.download = "camiones_inactivos";
        anchor.click();
      })
      .catch(function (error) {
        dispatch(validateError(error));
      })
      .finally(function () {
        dispatch(setLoading(false));

        // always executed
      });
  };
};

/* Por el momento estos tres servicios solo devuelven el mismo archivo excel */
/* 
  Este servicio solo vive por 15 minutos 
*/

export const getHistoricoCamiones = () => {
  return axiosAuth({
    url: `${URL_API}descargarhistoricocamiones`,
    method: "GET",
    responseType: blob,
  });
};

export const getAlerts = () => {
  return axiosAuth({
    url: `${URL_API}descargaralertas`,
    method: "GET",
    responseType: blob,
  });
};

export const getAlertsCls = () => {
  return axiosAuth({
    url: `${URL_API}cls/descargaralertas`,
    method: "GET",
    responseType: blob,
  });
};

export const getEtapas = () => {
  return axiosAuth({
    url: `${URL_API}descargarhistorialetapas`,
    method: "GET",
    responseType: blob,
  });
};

export const getFacturacion = () => {
  return axiosAuth({
    url: `${URL_API}cls/descargarReporteFacturado`,
    method: "GET",
    responseType: blob,
  });
};
