import React from "react";
import { sort } from "../../../../utils";
import { Container, Plantas, Planta, Cabecera, InfoPlanta } from "./Styles";

export const PanelPlantas = ({ plantas }) => {
  return (
    <Container container md={3}>
      <Plantas>
        {plantas?.sort(sort("nombre"))?.map((planta) => (
          <Planta key={planta.nombre}>
            <Cabecera>{planta.nombre}</Cabecera>
            <InfoPlanta>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Tn.</th>
                  </tr>
                </thead>
                <tbody>
                  {planta.tn_contratadas != null &&
                    planta.tn_contratadas !== "" &&
                    planta.tn_contratadas !== 0 && (
                      <tr>
                        <td>Toneladas contratadas</td>
                        <td>{planta.tn_contratadas}</td>
                      </tr>
                    )}
                  {planta.tn_acumuladas != null &&
                    planta.tn_acumuladas !== "" && (
                      <tr>
                        <td>Toneladas ingresadas</td>
                        <td>{planta.tn_acumuladas}</td>
                      </tr>
                    )}
                  {planta.tn_crudas_ingresadas != null &&
                    planta.tn_crudas_ingresadas !== "" && (
                      <tr>
                        <td>Toneladas crudas ingresadas</td>
                        <td>{planta.tn_crudas_ingresadas}</td>
                      </tr>
                    )}
                  {planta.tn_procesadas != null &&
                    planta.tn_procesadas !== "" && (
                      <tr>
                        <td>Toneladas despachadas</td>
                        <td>{planta.tn_procesadas}</td>
                      </tr>
                    )}
                  {planta.material?.map((material, index) => (
                    <tr key={index}>
                      <td>{material.tipo_arena}</td>
                      <td>{material.toneladas}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </InfoPlanta>
          </Planta>
        ))}
      </Plantas>
    </Container>
  );
};
