
export const getHora = (hora, index1, index2,) => {
  const result = hora?.split('T')[index1]?.split('.')[index2]

   return result
}

export const getFecha = (hora, index1) => {
  const result = hora?.toString()?.split('.')[index1].split('T').join(' - ') 

   return result
}

export function sort (key) {
  return function (x, y) {
    if (!isNaN(parseInt(x[key])) || !isNaN(parseInt(y[key]))) {
      if (parseInt(x[key]) > parseInt(y[key])) return -1
      if (parseInt(x[key]) < parseInt(y[key])) return 1
      return 0
    } else {
      const a = x[key].toUpperCase()
      const b = y[key].toUpperCase()
      return a === b ? 0 : a > b ? 1 : -1
    }
  }
}

export const formatHoras__ = (n) => {
  return `0${n / 60 ^ 0}`.slice(-2) + ':' + ('0' + n % 60).slice(-2)
}

export const formatHoras = (mins) => {
  let h = Math.floor(mins / 60);
  let m = mins % 60;

  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : m;
  
  return `${h}:${m}`;
}
