import {
  GET_INFO_PLANTAS_CLS,
  RESET_INFO_PLANTAS_CLS,
} from "../../constants/actionsTypes";

const initialState = {};

export const infoPlantasClsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INFO_PLANTAS_CLS: {
      return { ...state, ...payload };
    }
    case RESET_INFO_PLANTAS_CLS: {
      return { plantas: [] };
    }
    default:
      return state;
  }
};
