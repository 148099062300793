import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

/* Componentes */

import Indicadores from "../Components/Aluvional/Indicadores/Indicadores";
import Header from "../Components/Aluvional/Header/Header";
import HeaderPad from "../Components/Aluvional/HeaderPad/HeaderPad";
import { ModalPlanDespacho } from "../Components/Aluvional/ModalPlanDespacho/ModalPlanDespacho";
import { Sidebar } from "../Components/Sidebar/Sidebar";
import { EditarPlantaForm } from "../Components/Aluvional/Plantas/EditarPlantaForm/EditarPlantaForm";
import FullPageLoader from "../utils/FullPageLoader/FullPageLoader";
import ModalApproveCls from "../utils/ModalApproveCls/ModalApproveCls";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  @media (max-width: 768px) {
    overflow: auto;
    display: block;
  }
`;
const Home = styled(Grid)`
  position: relative;
`;

export const EditaPlantaCls = () => {
  const [showModalPlanDespacho, setShowModalPlanDespacho] = useState(false);
  const indicadoresCLS = useSelector((state) => state.indicadoresCls);
  // const [showModalEstadoRTForm, setShowModalEstadoRTForm] = useState(false);
  const isLoading = useSelector((state) => state.loading);
  const openModalTogglePlanDespacho = () => {
    setShowModalPlanDespacho(!showModalPlanDespacho);
  };
  // const openModalToggleEstadoRTForm = () => {
  //   setShowModalEstadoRTForm(!showModalEstadoRTForm);
  // };

  return (
    <Home container>
      <Grid item xs={12} md={3} sm={3}>
        <Sidebar />
        <HeaderPad setShowModalPlanDespacho={setShowModalPlanDespacho} />
      </Grid>
      <Grid item xs={12} md={9} sm={9}>
        <Header />
      </Grid>
      <Container>
        <Grid item xs={12} md={3}>
          <Indicadores indicadoresCls={indicadoresCLS} />
        </Grid>
        <Grid item xs={12} md={9} sm={12}>
          <EditarPlantaForm />
        </Grid>
      </Container>
      <ModalApproveCls />
      <FullPageLoader isLoading={isLoading} />
      {showModalPlanDespacho && (
        <ModalPlanDespacho onClose={openModalTogglePlanDespacho} />
      )}
      {/* {showModalEstadoRTForm && (
        <showModalEstadoRTForm onClose={openModalToggleEstadoRTForm} />
      )} */}
    </Home>
  );
};
