import React from "react";
import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  GridItem,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../Input/Inputs";

import { actualizarCajasPad } from "../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  cajasLLenasM100: yup.number(),
  cajasVaciasM100: yup.number(),
  cajasLLenasM3070: yup.number(),
  cajasVaciasM3070: yup.number(),
});

export const ModalModificarCajasPad = ({ onClose }) => {
  const dispatch = useDispatch();
  const { infoPad } = useSelector((state) => state);

  const formik = useFormik({
    initialValues: {
      cajasLLenasM100: infoPad?.pad?.box_full_m100
        ? infoPad?.pad?.box_full_m100
        : 0,
      cajasVaciasM100: infoPad?.pad?.box_empty_m100
        ? infoPad?.pad?.box_empty_m100
        : 0,
      cajasLLenasM3070: infoPad?.pad?.box_full_m3070
        ? infoPad?.pad?.box_full_m3070
        : 0,
      cajasVaciasM3070: infoPad?.pad?.box_empty_m3070
        ? infoPad?.pad?.box_empty_m3070
        : 0,
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      dispatch(
        actualizarCajasPad({
          full_box_m100: values.cajasLLenasM100,
          empty_box_m100: values.cajasVaciasM100,
          full_box_m3070: values.cajasLLenasM3070,
          empty_box_m3070: values.cajasVaciasM3070,
        })
      );

      onClose();
    },
  });

  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Modificar cajas en pad</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <p>Ingresar las cajas para modificar cajas en pad</p>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="cajasLLenasM100"
                  autoComplete="off"
                  type="number"
                  name="cajasLLenasM100"
                  label="Cajas llenas M100"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cajasLLenasM100}
                  // error={formik.errors?.cajasLLenasM100}
                />
              </FormGroup>
            </GridItem>

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="cajasVaciasM100"
                  autoComplete="off"
                  type="number"
                  name="cajasVaciasM100"
                  label="Cajas vacías M100"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cajasVaciasM100}
                  // error={formik.errors?.cajasVaciasM100}
                />
              </FormGroup>
            </GridItem>

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="cajasLLenaM3070"
                  autoComplete="off"
                  type="number"
                  name="cajasLLenasM3070"
                  label="Cajas llenas M3070"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cajasLLenasM3070}
                  // error={formik.errors?.cajasLLenasM3070}
                />
              </FormGroup>
            </GridItem>

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="cajasVaciasM3070"
                  autoComplete="off"
                  type="number"
                  name="cajasVaciasM3070"
                  label="Cajas vacías M3070"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cajasVaciasM3070}
                  // error={formik.errors?.cajasLLenasM3070}
                />
              </FormGroup>
            </GridItem>

            <Grid container md={12} sm={8} justify="center">
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    type="button"
                    textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                    onClick={() => handleClose()}
                    variant="outlined"
                    color="primary"
                    minwidth="true"
                  />
                </ButtonContainer>
              </Grid>
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    // disabled={validated}
                    type="submit"
                    textArea="Guardar"
                    variant="contained"
                    color="primary"
                    mright="true"
                  />
                </ButtonContainer>
              </Grid>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};

/**
 *  <GridItem item md={6}>
      <FormGroup>
        <TextField
          type="number"
          id="cantidad_cajas"
          name="cantidad_cajas"
          label="Cantidad de cajas*"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.cantidad_cajas}
          error={!!formik.errors.cantidad_cajas}
        />
        <FormHelperText>{formik.errors.cantidad_cajas}</FormHelperText>
      </FormGroup>
    </GridItem>

     <Div>
          <Span>Total de cajas: {Info_de_indicadores.total_cajas_pad}</Span>
          <Span>Cajas llenas: {Info_de_indicadores?.cajas_disponibles}</Span>
        </Div>
 */
