import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

import {
  Container,
  Head,
  Buton,
  TableContainer,
  ButtonEdit,
  Paragraph,
  Div,
  IconContainer,
} from "./Styles";
import {
  Grid,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  IconButton,
  TablePagination,
} from "@material-ui/core";

// import FirstPageIcon from '@material-ui/icons/FirstPage';
// import LastPageIcon from '@material-ui/icons/LastPage';

/* Icons */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as HomeIcon } from "../../../assets/img/homeIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/img/editIcon.svg";
import { useSelector } from "react-redux";
import { setCurrenData } from "../../../actions/plantasActions";
import { sort } from "../../../utils";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <IconContainer>
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </IconContainer>
        ) : (
          <IconContainer>
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </IconContainer>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <IconContainer>
            <FontAwesomeIcon icon={faChevronRight} />
          </IconContainer>
        ) : (
          <IconContainer>
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconContainer>
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <IconContainer>
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconContainer>
        ) : (
          <IconContainer>
            <FontAwesomeIcon icon={faChevronRight} />
          </IconContainer>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <IconContainer>
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </IconContainer>
        ) : (
          <IconContainer>
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </IconContainer>
        )}
      </IconButton>
    </div>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#C8C8C8",
    color: "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Frozen yoghurt", 15, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 23, 9.0, 37, 4.3),
  createData("Eclair", 26, 16.0, 24, 6.0),
  createData("Cupcake", 30, 3.7, 67, 4.3),
  createData("Gingerbread", 35, 16.0, 49, 3.9),
];

export const ModificarTrayecto = () => {
  const [pag, setPag] = useState(0);
  const [rowsPerPag, setRowsPerPag] = useState(5);
  const dispatch = useDispatch();

  const emptyRows =
    rowsPerPag - Math.min(rowsPerPag, rows.length - pag * rowsPerPag);

  const handleChangePage = (event, newPage) => {
    setPag(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPag(parseInt(event.target.value, 10));
    setPag(0);
  };

  let history = useHistory();

  function home() {
    history.push("/home");
  }

  const camionesVacios = useSelector(
    (state) =>
      state?.estadoCamiones?.camiones?.camiones_transito_vacio?.camiones
  );

  return (
    <Container>
      <Grid item md={12} sm={12}>
        <Head>
          <Buton
            variant="outlined"
            color="primary"
            textArea="Home"
            onClick={home}
          >
            <HomeIcon />
          </Buton>
        </Head>
      </Grid>
      <TableContainer component={Paper}>
        {/* <Grid item md={12} sm={12}>
          <Title>Modificar Trayecto</Title>
        </Grid> */}
        <Grid item md={12} sm={12} style={{ paddingTop: "20px" }}>
          <Paragraph>
            A continuación, encontrará los vehículos que se encuentran en
            tránsito vacíos, sobre los cuales podrá modificar el destino o tipo
            de arena a cargar
          </Paragraph>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Camion</StyledTableCell>
              <StyledTableCell align="center">Destino</StyledTableCell>
              <StyledTableCell align="center">Tipo de arena</StyledTableCell>
              <StyledTableCell align="center">Opciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {camionesVacios &&
              camionesVacios?.sort(sort("placa"))?.map((camion, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {camion.placa}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {camion.destino}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {camion.tipo_arena.toUpperCase()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <ButtonEdit
                      to={`/editarTrayectoForm/:${camion.placa}`}
                      onClick={() => {
                        dispatch(
                          setCurrenData({
                            placa: camion.placa,
                            tipo_arena: camion.tipo_arena,
                            destino: camion.destino,
                          })
                        );
                      }}
                    >
                      <EditIcon />
                    </ButtonEdit>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, { label: "All", value: -1 }]}
                colSpan={12}
                count={rows.length}
                rowsPerPage={rowsPerPag}
                page={pag}
                SelectProps={{
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <Grid item md={12}>
          <Div>
            <Buton width variant="outlined" color="primary" onClick={home}>
              Cancelar
            </Buton>
          </Div>
        </Grid>
      </TableContainer>
    </Container>
  );
};
