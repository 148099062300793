import React from "react";
import ReactDOM from "react-dom";
import { resetResponse } from "../../../actions/responseActions";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import {
  Head,
  Div,
  ButtonContainer,
  Divider,
  ApproveMessage,
  Background,
  ModalWrapper
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as CarIcon } from "../../../assets/img/truck.svg";
import { ReactComponent as DestinoIcon } from "../../../assets/img/destino.svg";
import { ReactComponent as Clock } from "../../../assets/img/clockRecomended.svg";

import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";
import useAlert from "../../../Hooks/useAlert";
import { useDispatch } from "react-redux";

export const ModificarTrayectoApprove = () => {
  const { response } = useAlert(5000)
  const dispatch = useDispatch();
  let history = useHistory();

  const redirectHome = () => history.push('/home')

  return ReactDOM.createPortal(
    response.show && (
      <Background>
        <ModalWrapper onClose={() => ''}>
        <Head>
          <p>Modificar Trayecto</p> 
        </Head>
        <ApproveMessage>
          <FontAwesomeIcon icon={faCheckCircle} />
          <span>
            Ha modificado satisfactoriamente el trayecto, los cambios se verán
            relfejados en los próximos minutos.
          </span>
        </ApproveMessage>
        <Div container>
          <Grid item style={{ marginRight: "0.688rem" }}>
            <CarIcon />
          </Grid>
          <Grid item>
            <p>Placa</p>
            <span>{response?.data?.recomendacion?.placa}</span>
          </Grid>
        </Div>
        <Divider />
        <Div container>
          <Grid item style={{ marginRight: "0.688rem" }}>
            <DestinoIcon />
          </Grid>
          <Grid item>
            <p>Destino</p>
            <span>{response?.data?.recomendacion?.destino}</span>
          </Grid>
        </Div>
        <Divider />
        <Div container>
          <Grid item style={{ marginRight: "0.688rem" }}>
            <Clock />
          </Grid>
          <Grid item>
            <p>Tipo de arena</p>
            <span>{response?.data?.recomendacion?.tipo_arena.toUpperCase()}</span>
          </Grid>
        </Div>
        <Divider />

        <ButtonContainer container align="center">
          <Grid item md={12}>
            <ButtonPrimary
              type="button"
              textArea="Aceptar" /* Acà se puede validar el tipo de boton */
              onClick={() => {
                dispatch(resetResponse())
                redirectHome()
                window.location.reload()
              }}
              variant="contained"
              color="primary"
              minwidth="true"
            />
          </Grid>
        </ButtonContainer>
        </ModalWrapper>
      </Background>
    ),
    document.getElementById("root")
  );
};
