import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const Container = styled.div`
  height: calc(100vh - 60px);
  background: #ffffff;
  box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.08);
  display: flex; /* Change this to flex */
  flex-direction: column; /* Ensure it's a column layout */
  position: relative;
  margin: 0;
  margin-left: 75px;
  padding: 0;

  & h2 {
    font-size: 1rem;
    margin-bottom: 15px;
    font-weight: 500;
    letter-spacing: -0.5px;
  }

  @media (max-width: 768px) {
    height: calc(100vh - 60px);
    overflow: auto;
    margin-left: 0px;
  }

  .content_info {
    flex-grow: 1; /* Allow this section to grow and take available height */
    position: relative;
    padding: 10px;
    overflow: auto;
  }
`;

export const ItemContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: wrap;
  margin-bottom: 15px;
  overflow: hidden;
  padding: 0;

  & p {
    margin: 0 0 0 8px;
    font-size: 14px;
  }
  @media (max-width: 1250px) {
    flex-wrap: wrap;
  }

  @media (min-width: 1250px) {
    flex-wrap: nowrap;
  }
`;

export const State = styled.div`
  background-color: ${(props) =>
    props.greenCard
      ? "rgba(67, 214, 106, 0.2)"
      : props.yellowCard
      ? "rgba(250, 192, 0, 0.3)"
      : props.redCard
      ? "rgba(223, 0, 0, 0.2)"
      : props.greyCard
      ? "rgba(240, 240, 240, 0.94)"
      : null};
  margin: 0 1px;
  height: 6rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${(props) =>
    props.green
      ? "#138B33"
      : props.yellow
      ? "#9F7A00"
      : props.red
      ? "#A03434"
      : props.grey
      ? "#414042"
      : null};
  line-height: 16px;
  vertical-align: middle;
  font-weight: 700;
  font-size: 16px;
  & span:last-child {
    font-weight: 400;
    font-size: 14px;
  }
`;

export const ItemContainer2 = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow wrapping to the next row */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 0;

  > div {
    flex-basis: calc(
      20% - 10px
    ); /* 25% width for each div with a margin of 10px */
    margin-bottom: 10px; /* Adjust as needed */
  }

  &.proyeccion {
    padding: 0;
  }

  & svg {
    width: 14px;
  }

  & p {
    margin: 0 0 0 8px;
    font-size: 14px;
  }
`;

export const Indicador = styled(Grid)`
  font-weight: 500;
  margin-right: 0;
  text-align: right; /* Align the indicator text to the right */
  font-size: 14px;
`;

export const Wrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Hr = styled.hr`
  background-color: #c4c4c4;
  width: 100%;
  margin-top: ${(props) => (props.mtop ? "1.106rem" : "0")};
  margin-bottom: 1.106rem;
`;

export const Table = styled.table`
  display: table;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0 0 15px 0;
  text-align: right;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  thead {
    th {
      padding: 5px 0;
      &.arena {
        text-align: center;
      }
    }
  }
  tbody {
    tr {
      background: #f0f0f0;
      border-bottom: solid 2px #fff;
      td {
        padding: 6px 15px;
        &:first-child {
          text-align: left;
        }
        &.arena {
          text-align: center;
        }
      }
    }
  }
`;
