import { GET_PLANTAS_INACTIVOS, RESET_PLANTAS_INACTIVOS } from '../constants/actionsTypes';

export const getPlantasInactivos = (payload) => {
  return {
    type: GET_PLANTAS_INACTIVOS,
    payload
  };
};


export const resetPlantasInactivos = (payload) => (
  {
    type: RESET_PLANTAS_INACTIVOS,
    payload
  }
)