import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

/* Rutas privadas */
import { PrivateRoutes } from "./Components/PrivateRoutes/PrivateRoutes";

/* Containers */
import { Login } from "./Containers/Login";
import { Home } from "./Containers/Home";
import { EditarTrayecto } from "./Containers/EditarTrayecto";
import { EditarTrayectoForm } from "./Containers/EditarTrayectoForm";
import { EditaPlanta } from "./Containers/EditaPlanta";
import { HabilitarPlanta } from "./Containers/HabilitarPlanta";
import { AsignarPlanta } from "./Containers/AsignarPlanta";
import { IngresarEtapa } from "./Containers/IngresarEtapa";
import { EditarEtapas } from "./Containers/EditarEtapas";
import { Aluvional } from "./Containers/Aluvional";
import { AuthRoutes, usersRoles } from "./utils/AuthRoles";
import { NotFound } from "./utils/NotFound";
import LineChart from "./utils/LineCharts/LineChart";
import { EscenariosProyeccion } from "./Containers/EscenariosProyeccion";
import { EditaPlantaCls } from "./Containers/EditaPlantaCls";

function App() {
  return (
    <main>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <PrivateRoutes
            exact
            path={AuthRoutes.home}
            component={Home}
            requiredRoles={[
              usersRoles.Brent,
              usersRoles.SupplyChain,
              usersRoles.CoordBrent,
            ]}
          />
          <PrivateRoutes
            exact
            path={AuthRoutes.editarTrayecto}
            component={EditarTrayecto}
            requiredRoles={[usersRoles.Brent]}
          />
          <PrivateRoutes
            exact
            path={AuthRoutes.editarTrayectoForm}
            component={EditarTrayectoForm}
            requiredRoles={[usersRoles.Brent]}
          />
          <PrivateRoutes
            exact
            path={AuthRoutes.ingresarEtapa}
            component={IngresarEtapa}
            requiredRoles={[usersRoles.Brent]}
          />
          <PrivateRoutes
            exact
            path={AuthRoutes.editarEtapas}
            component={EditarEtapas}
            requiredRoles={[usersRoles.Brent]}
          />
          <PrivateRoutes
            exact
            path={AuthRoutes.editaPlanta}
            component={EditaPlanta}
            requiredRoles={[usersRoles.SupplyChain]}
          />
          <PrivateRoutes
            exact
            path="/grafico"
            component={LineChart}
            requiredRoles={[
              usersRoles.SupplyChain,
              usersRoles.Brent,
              usersRoles.CoordBrent,
            ]}
          />
          <PrivateRoutes
            exact
            path={AuthRoutes.habilitarPlanta}
            component={HabilitarPlanta}
            requiredRoles={[usersRoles.SupplyChain]}
          />
          <PrivateRoutes
            exact
            path={AuthRoutes.asignarPlanta}
            component={AsignarPlanta}
            requiredRoles={[usersRoles.SupplyChain]}
          />
          <PrivateRoutes
            exact
            path={AuthRoutes.escenariosProyeccion}
            component={EscenariosProyeccion}
            requiredRoles={[
              usersRoles.SupplyChain,
              usersRoles.CoordBrent,
              usersRoles.Brent,
            ]}
          />

          <PrivateRoutes
            exact
            path="/"
            requiredRoles={[
              usersRoles.SupplyChain,
              usersRoles.Brent,
              usersRoles.CoordBrent,
            ]}
          >
            <Redirect to={AuthRoutes.home} />
          </PrivateRoutes>
          <PrivateRoutes
            exact
            path={AuthRoutes.aluvional}
            component={Aluvional}
            requiredRoles={[
              usersRoles.SupplyChain,
              usersRoles.SupervAluvional,
              usersRoles.CoordAluvional,
              usersRoles.Pedrolga,
              usersRoles.Weber,
            ]}
          ></PrivateRoutes>
          <PrivateRoutes
            exact
            path={AuthRoutes.editaPlantaCls}
            component={EditaPlantaCls}
            requiredRoles={[
              usersRoles.SupplyChain,
              usersRoles.SupervAluvional,
              usersRoles.CoordAluvional,
            ]}
          ></PrivateRoutes>
          <Route component={NotFound} />
        </Switch>
      </Router>
    </main>
  );
}

export default App;
