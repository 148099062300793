import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

/* Componentes */
import { Map2 } from "../Components/Map/Map2";
import Header from "../Components/Header/Header";
import HeaderPad from "../Components/HeaderPad/HeaderPad";
import { ModalEtapasDias } from "../Components/Etapas/ModalEtapasDia/ModalEtapasDias";
import { ModalCajasDisponiblesPad } from "../Components/ModalCajasDisponiblesPad/ModalCajasDisponiblesPad";
import { ModalAltaPad } from "../Components/ModalAltaPad/ModalAltaPad";
import { ModalEditarPad } from "../Components/ModalEditarPad/ModalEditarPad";
import { ModalFinalizarPad } from "../Components/ModalFinalizarPad/ModalFinalizarPad";
import { OptionsRight } from "../Components/OptionsRight/OptionsRight";
import { ModalDeshabilitarCamion } from "../Components/Camiones/ModalDeshabilitarCamion/ModalDeshabilitarCamion";
import { ModalHabilitarCamion } from "../Components/Camiones/ModalHabilitarCamion/ModalHabilitarCamion";
import { ModalHabilitarNuevoCamion } from "../Components/Camiones/ModalHabilitarNuevoCamion/ModalHabilitarNuevoCamion";
import { Recomended } from "../Components/Camiones/Recomendacion/Recomended/Recomended";
import { Edit } from "../Components/Camiones/Recomendacion/Edit/Edit";
import { CargarCajas } from "../Components/Camiones/Recomendacion/CargarCajas/CargarCajas";
import { EscenariosProyeccionForm } from "../Components/EscenariosProyeccionForm/EscenariosProyeccionForm";
import { resetRecomendador } from "../actions/recomendadorActions";
import FullPageLoader from "../utils/FullPageLoader/FullPageLoader";
import { HabilitarCamionApprove } from "../Components/Camiones/HabilitarCamionApprove/HabilitarCamionApprove";
import { DeshabilitarCamionApprove } from "../Components/Camiones/DeshabilitarCamionApprove/DeshabilitarCamionApprove";
import { ConfirmacioRecomendar } from "../Components/Camiones/Recomendacion/ConfirmacioRecomendar/ConfirmacioRecomendar";
import ModalApprove from "../utils/ModalApprove/ModalApprove";
import { __updateIndicadores } from "../Services/update";
import { ModalModificarCajasPad } from "../Components/ModalModificarCajasPad/ModalModificarCajasPad";
import { ModalReentrenamiento } from "../Components/ModalReentrenamiento/ModalReentrenamiento";
import { Sidebar } from "../Components/Sidebar/Sidebar";

import {
  estadoCamiones,
  indicadores,
  infoplantas,
  plantaGet,
  alertas,
  proyecciones,
  camionesInactivos,
  infoPad,
  estadoRT,
  plantasHistoricasGet,
  camionesHistoricosGet,
} from "../Services";
import { getCamionesHistoricos } from "../actions/camionesHistoricosActions";

const Container = styled(Grid)`
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  @media (max-width: 768px) {
    overflow: auto;
    display: block;
  }
`;
const Home = styled(Grid)`
  position: relative;
`;

export const EscenariosProyeccion = () => {
  const { Role } = useSelector((state) => state.dataUser.user);
  const response = useSelector((state) => state.response);
  const recomendador = useSelector((state) => state.recomendador);
  const estadoCamionesData = useSelector((state) => state.estadoCamiones);
  const plantas = useSelector((state) => state.infoPlantas.plantas);
  const isLoading = useSelector((state) => state.loading);

  const [showModalEtapasDia, setShowModalEtapasDias] = useState(false);
  const [showModalCajasPad, setShowModalCajasPad] = useState(false);
  const [showModalModificarCajasPad, setShowModalModificarCajasPad] =
    useState(false);
  const [showModalAltaPad, setShowModalAltaPad] = useState(false);
  const [showModalEditarPad, setShowModalEditarPad] = useState(false);
  const [showModalFinalizarPad, setShowModalFinalizarPad] = useState(false);
  const [showModalReentrenamiento, setShowModalReentrenamiento] =
    useState(false);
  const [showModalEstadoRTForm, setShowModalEstadoRTForm] = useState(false);
  const [deshabilitarCamion, setDeshabilitarCamion] = useState(false);
  const [habilitarCamion, setHabilitarCamion] = useState(false);
  const [habilitarNuevoCamion, setHabilitarNuevoCamion] = useState(false);
  const [showGraph, setShowGraph] = useState(false);

  const [CamionPad, setCamionPad] = useState(true);
  const [transitoVacio, setTransitoVacio] = useState(true);
  const [cargaPlanta, setCargaPlanta] = useState(true);
  const [transitoLleno, setTransitoLleno] = useState(true);
  const [sinGeotab, setSinGeotab] = useState(true);
  const [showAlert, setShowAlert] = useState(() => {
    if (Role === "SupplyChain") return true;
    if (Role === "CoordBrent") return true;
    if (Role === "Brent") return false;
  });
  const [showCamiones, setShowCamiones] = useState(() => {
    if (Role === "SupplyChain") return false;
    if (Role === "CoordBrent") return false;
    if (Role === "Brent") return true;
  });
  const [showPlantas, setShowPlantas] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const [formValue, setFormValue] = useState({});
  const [showModalRecomended, setShowModalRecomended] = useState(false);

  const [steps, setSteps] = useState({
    currentState: "STEP_1",
  });

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(indicadores("5"));
  }, []);

  useEffect(() => {
    setTimeout(() => dispatch(proyecciones()), 1200);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(estadoCamiones());
      dispatch(plantaGet());
    }, 2000);
  }, []);

  useEffect(() => {
    if (Role === "SupplyChain" || Role === "CoordBrent") {
      setTimeout(() => {
        dispatch(alertas());
        dispatch(infoplantas());
        dispatch(plantasHistoricasGet());
        dispatch(getCamionesHistoricos());
        dispatch(camionesInactivos());
      }, 2500);
    }
  }, []);

  const stepsRecomended = (data = {}) => {
    switch (steps.currentState) {
      case "STEP_1":
        return (
          <Recomended
            setSteps={setSteps}
            steps={steps}
            onClose={openModalToggleRecomended}
            data={{ ...recomendador?.recomendacion, ...data }}
          />
        );
      case "STEP_2":
        return (
          <Edit
            Role={Role}
            setFormValue={setFormValue}
            setSteps={setSteps}
            steps={steps}
            onClose={openModalToggleRecomended}
            data={{
              ...recomendador?.recomendacion,
              ...{ ...data, motivo_cambio: "" },
            }}
          />
        );
      case "STEP_3":
        return (
          <CargarCajas
            formValue={formValue}
            type={steps.currentState === "STEP_3" ? "caja" : "editar"}
            setSteps={setSteps}
            steps={steps}
            onClose={openModalToggleRecomended}
            data={{ ...recomendador?.recomendacion, ...data }}
          />
        );
      default:
        return null;
    }
  };

  const openModalToggleRecomended = () => {
    setSteps({ currentState: "STEP_1" });
    setShowModalRecomended(!showModalRecomended);
    dispatch(resetRecomendador());
  };

  /*  */
  const openModalToggleEtapasDia = () => {
    setShowModalEtapasDias(!showModalEtapasDia);
  };

  const openModalToggleCajasPad = () => {
    setShowModalCajasPad(!showModalCajasPad);
  };

  const openModalToggleModificarCajasPad = () => {
    setShowModalModificarCajasPad(!showModalModificarCajasPad);
  };

  const openModalToggleAltaPad = () => {
    setShowModalAltaPad(!showModalAltaPad);
  };
  const openModalToggleEditarPad = () => {
    setShowModalEditarPad(!showModalEditarPad);
  };
  const openModalToggleFinalizarPad = () => {
    setShowModalFinalizarPad(!showModalFinalizarPad);
  };
  const openModalToggleReentrenamiento = () => {
    setShowModalReentrenamiento(!showModalReentrenamiento);
  };
  const openModalToggleEstadoRTForm = () => {
    setShowModalEstadoRTForm(!showModalEstadoRTForm);
  };
  const openModalToggleDeshabilitarCamion = () => {
    setDeshabilitarCamion(!deshabilitarCamion);
  };
  const openModalToggleHabilitarNuevoCamion = () => {
    setHabilitarNuevoCamion(!habilitarNuevoCamion);
  };

  const openModalToggleHabilitarCamion = () => {
    setHabilitarCamion(!habilitarCamion);
  };

  /*  */
  const togglePad = () => {
    setCamionPad(!CamionPad);
  };
  const toggleTransitoVacio = () => {
    setTransitoVacio(!transitoVacio);
  };
  const toggleCarga = () => {
    setCargaPlanta(!cargaPlanta);
  };
  const toggleTransitoLleno = () => {
    setTransitoLleno(!transitoLleno);
  };
  const toggleSinGeotab = () => {
    setSinGeotab(!sinGeotab);
  };

  /* Options Right */
  const openMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  const openAlertToggle = () => {
    setShowAlert(true);
    setShowMenu(false);
    setShowCamiones(false);
    setShowPlantas(false);
  };

  const openCamionesToggle = () => {
    setShowCamiones(true);
    setShowMenu(false);
    setShowAlert(false);
    setShowPlantas(false);
  };

  const openPlantasToggle = () => {
    setShowPlantas(true);
    setShowMenu(false);
    setShowAlert(false);
    setShowCamiones(false);
  };

  const toHabilitarPlanta = () => {
    history.push("/habilitarPlanta");
  };
  const toEditarPlanta = () => {
    history.push("/editaPlanta");
  };
  const toEscenariosProyeccion = () => {
    history.push("/escenariosProyeccion");
  };
  const toAsignarPlanta = () => {
    history.push("/asignarPlanta");
  };

  useEffect(() => {
    const idUpdateData = setInterval(() => {
      dispatch(__updateIndicadores("5"));
      dispatch(proyecciones());
      dispatch(estadoCamiones());
      dispatch(plantaGet());
      dispatch(estadoRT());

      if (Role === "SupplyChain") {
        dispatch(alertas());
        dispatch(infoplantas());
        dispatch(plantasHistoricasGet());
        dispatch(camionesHistoricosGet());
        dispatch(infoPad());
      }
    }, 180000);

    return () => {
      clearInterval(idUpdateData);
    };
  }, []);

  return (
    <Home container>
      <Grid item xs={12} md={3} sm={3}>
        <Sidebar />
        <HeaderPad
          setShowModalEtapasDias={setShowModalEtapasDias}
          setShowModalModificarCajasPad={setShowModalModificarCajasPad}
          setShowModalCajasPad={setShowModalCajasPad}
          setShowModalAltaPad={setShowModalAltaPad}
          setShowModalEditarPad={setShowModalEditarPad}
          setShowModalFinalizarPad={setShowModalFinalizarPad}
          setShowModalReentrenamiento={setShowModalReentrenamiento}
          toEscenariosProyeccion={toEscenariosProyeccion}
        />
      </Grid>
      <Grid item xs={12} md={9} sm={9}>
        <Header />
      </Grid>
      <Container>
        <Grid item xs={12} md={3}>
          <EscenariosProyeccionForm
            showGraph={showGraph}
            setShowGraph={setShowGraph}
          />
        </Grid>

        <Grid item xs={12} md={6} className="grid-map2">
          <Map2 />
        </Grid>
        <Grid item xs={12} md={3}>
          <OptionsRight
            setHabilitarCamion={setHabilitarCamion}
            setDeshabilitarCamion={setDeshabilitarCamion}
            openModalToggleDeshabilitarCamion={
              openModalToggleDeshabilitarCamion
            }
            openModalToggleHabilitarCamion={openModalToggleHabilitarCamion}
            togglePad={togglePad}
            toggleTransitoVacio={toggleTransitoVacio}
            toggleCarga={toggleCarga}
            toggleTransitoLleno={toggleTransitoLleno}
            toggleSinGeotab={toggleSinGeotab}
            sinGeotab={sinGeotab}
            CamionPad={CamionPad}
            transitoVacio={transitoVacio}
            cargaPlanta={cargaPlanta}
            transitoLleno={transitoLleno}
            formValue={formValue}
            setFormValue={setFormValue}
            showModalRecomended={showModalRecomended}
            setShowModalRecomended={setShowModalRecomended}
            openModalToggleRecomended={openModalToggleRecomended}
            stepsRecomended={stepsRecomended}
            estadoCamionesData={estadoCamionesData}
            Role={Role}
            openMenuToggle={openMenuToggle}
            openAlertToggle={openAlertToggle}
            openCamionesToggle={openCamionesToggle}
            openPlantasToggle={openPlantasToggle}
            toAsignarPlanta={toAsignarPlanta}
            toHabilitarPlanta={toHabilitarPlanta}
            toEditarPlanta={toEditarPlanta}
            showCamiones={showCamiones}
            showAlert={showAlert}
            showPlantas={showPlantas}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            plantas={plantas}
          />
        </Grid>

        <ModalApprove />
        {response.type === "recomendacion" && <ConfirmacioRecomendar />}

        {response.type === "habilitarCamion" && <HabilitarCamionApprove />}

        {response.type === "deshabilitarCamion" && (
          <DeshabilitarCamionApprove />
        )}

        <FullPageLoader isLoading={isLoading} />
      </Container>

      {showModalEtapasDia && (
        <ModalEtapasDias onClose={openModalToggleEtapasDia} />
      )}

      {showModalCajasPad && (
        <ModalCajasDisponiblesPad onClose={openModalToggleCajasPad} />
      )}

      {showModalModificarCajasPad && (
        <ModalModificarCajasPad onClose={openModalToggleModificarCajasPad} />
      )}
      {showModalAltaPad && <ModalAltaPad onClose={openModalToggleAltaPad} />}

      {showModalEditarPad && (
        <ModalEditarPad onClose={openModalToggleEditarPad} />
      )}
      {showModalFinalizarPad && (
        <ModalFinalizarPad onClose={openModalToggleFinalizarPad} />
      )}
      {showModalReentrenamiento && (
        <ModalReentrenamiento onClose={openModalToggleReentrenamiento} />
      )}

      {deshabilitarCamion && (
        <ModalDeshabilitarCamion onClose={openModalToggleDeshabilitarCamion} />
      )}

      {habilitarCamion && (
        <ModalHabilitarCamion onClose={openModalToggleHabilitarCamion} />
      )}
      {habilitarNuevoCamion && (
        <ModalHabilitarNuevoCamion
          onClose={openModalToggleHabilitarNuevoCamion}
        />
      )}
      {showModalEstadoRTForm && (
        <showModalEstadoRTForm onClose={openModalToggleEstadoRTForm} />
      )}
    </Home>
  );
};
