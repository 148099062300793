import axios from "axios";
// import { getEstadoCamiones } from '../../actions/estadoCamionesActions';
import { getIndicadores } from "../../actions/indicadoresActions";

import { getIndicadoresCls } from "../../actions/cls/indicadoresClsActions";

// import { getAlertas } from '../../actions/alertasActions';
import { URL_API } from "../../constants/apis";
import { validateError } from "../validateError";

export const __updateIndicadores = (horas) => {
  const TOKEN = window.sessionStorage.getItem("Token");

  return (dispatch) => {
    axios({
      method: "get",
      url: `${URL_API}indicadores?horas=${horas}`,
      headers: { Authorization: `Bearer ${TOKEN}` },
    })
      .then(function (response) {
        const { data } = response;
        dispatch(getIndicadores(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/"));
      });
  };
};

export const __updateIndicadoresCls = () => {
  const TOKEN = window.sessionStorage.getItem("Token");
  return (dispatch) => {
    axios({
      method: "get",
      url: `${URL_API}cls/indicadores`,
      headers: { Authorization: `Bearer ${TOKEN}` },
    })
      .then(function (response) {
        const { data } = response;
        dispatch(getIndicadoresCls(data));
      })
      .catch(function (error) {
        dispatch(validateError(error, "/"));
      });
  };
};
