import React from "react";
import { toggleSidebar } from "../../actions/toggleSidebarActions";
import {
  SidebarWrapper,
  StyledNav,
  StyledButton,
  LogoVista,
  VerticalTitle,
  Title,
} from "./Styles";
import { Nav } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../assets/img/logo_vista.png";
import {
  faTruck,
  faIndustry,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

export const Sidebar = () => {
  const isExpanded = useSelector((state) => state.sidebar?.isExpanded);
  const { Role } = useSelector((state) => state.dataUser.user);
  let history = useHistory();
  const dispatch = useDispatch();
  const home = () => history.push("/home");
  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  return (
    <SidebarWrapper isExpanded={isExpanded}>
      <StyledNav className="flex-column">
        {(Role === "SupplyChain" ||
          Role === "CoordBrent" ||
          Role === "Brent") && (
          <Nav.Link href="/home">
            <FontAwesomeIcon size="2x" icon={faTruck} onClick={home} />
            {isExpanded && <h5>Última Milla</h5>}
          </Nav.Link>
        )}
        {(Role === "SupplyChain" ||
          Role === "SupervAluvional" ||
          Role === "Weber" ||
          Role === "Pedrolga" ||
          Role === "CoordAluvional") && (
          <Nav.Link href="/aluvional">
            <FontAwesomeIcon size="2x" icon={faIndustry} />
            {isExpanded && <h5>Aluvional</h5>}
          </Nav.Link>
        )}
      </StyledNav>
      {isExpanded ? (
        <Title>Optilog</Title>
      ) : (
        <VerticalTitle>Optilog</VerticalTitle>
      )}

      <LogoVista src={Logo} alt="logo" />
      <StyledButton
        className="btn btn-primary shadow-none "
        id="styled-button"
        onClick={handleToggleSidebar}
      >
        {isExpanded ? (
          <FontAwesomeIcon size="2x" icon={faChevronLeft} color="#4F4184" />
        ) : (
          <FontAwesomeIcon size="2x" icon={faChevronRight} color="#4F4184" />
        )}
      </StyledButton>
    </SidebarWrapper>
  );
};
