import { GET_RECOMENDADOR, RESET_RECOMENDADOR } from '../constants/actionsTypes';

export const getRecomendador = (payload) => {
  return {
    type: GET_RECOMENDADOR,
    payload
  };
};


export const resetRecomendador = () => (
  {
    type: RESET_RECOMENDADOR
  }
)