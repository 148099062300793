/* eslint-disable no-extend-native */
import {
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import {
  StyledForm,
  Head,
  FormGroup,
  ButtonContainer,
  Div,
  IconContainer,
  Divider,
} from "./Styles";

/* Iconos */
import { ReactComponent as CarIcon } from "../../../../assets/img/truck.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Components */
import ButtonPrimary from "../../../ButtonPrimary/ButtonPrimary";
import { recomendadorPost } from "../../../../Services";
import { Inputs } from "../../../Input/Inputs";
import { addHours } from "../../../../utils/DateTimeConverter/DateTimeConverter";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  destino: yup.string(),
  hora: yup.string(),
  tipo_arena: yup.string(),
  motivo_cambio: yup.string(),
});

const hoyArgentina = addHours(-3, new Date());

export const Recomended = ({
  onClose,
  data,
  Role,
  setShowModalRecomended,
  setFormValue,
}) => {
  const plantas = useSelector((state) => state.plantas.plantas);
  const [validated, setValidated] = useState(true);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      destino: "",
      tipo_arena: "",
      codigo_1: "",
      codigo_2: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      dispatch(
        recomendadorPost({
          placa: data.placa,
          tipo_arena: formik.values.tipo_arena.toLowerCase(),
          destino: formik.values.destino,
          motivo_cambio: "Ninguno",
          // hora_salida: `${data?.fecha}T${data?.hora}`,
          hora_salida: hoyArgentina.toISOString(),
          cajas: `${formik.values.codigo_1}-${formik.values.codigo_2}`,
          estado_recomendacion: true,
        })
      );
      onClose();
    },
  });

  useEffect(() => {
    if (
      formik.values.tipo_arena ||
      formik.values.destino ||
      formik.values.motivo_cambio
    ) {
      setValidated(false);
    } else setValidated(true);
  }, [setValidated, formik]);

  return (
    <>
      <Head>
        <p>Editar recomendación</p>
        <IconContainer>
          <FontAwesomeIcon icon={faTimes} onClick={onClose} />
        </IconContainer>
      </Head>
      <Divider />

      <Div container>
        <Grid item style={{ marginRight: "0.688rem" }}>
          <CarIcon />
        </Grid>
        <Grid item>
          <p>Placa</p>
          <span>{data?.placa ? data.placa : "---"}</span>
        </Grid>
      </Div>
      <Divider />
      <StyledForm onSubmit={formik.handleSubmit}>
        <FormGroup>
          <InputLabel id="demo-simple-select-label">Destino</InputLabel>
          <Select
            labelId="Destino"
            id="destino"
            name="destino"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.destino}
            error={!!formik.errors.destino}
          >
            <MenuItem value="">Selecciona un destino</MenuItem>
            {plantas &&
              plantas.map((planta, index) => (
                <MenuItem key={index} value={`${planta.nombre_planta}`}>
                  {planta.nombre_planta}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{formik.errors.destino}</FormHelperText>
        </FormGroup>
        <FormGroup>
          <InputLabel id="demo-simple-select-label">Tipo de arena</InputLabel>
          <Select
            labelId="Tipo de arena"
            id="tipo_arena"
            name="tipo_arena"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.tipo_arena}
            error={!!formik.errors.tipo_arena}
            defaultValue={data?.tipo_arena}
          >
            <MenuItem value="">Selecciona un tipo de arena</MenuItem>
            <MenuItem value={"M100"}>M100</MenuItem>
            <MenuItem value={"M3070"}>M3070</MenuItem>
          </Select>
          <FormHelperText>{formik.errors.tipo_arena}</FormHelperText>
        </FormGroup>
        <Grid item md={12}>
          <FormGroup>
            <Inputs
              required
              autoComplete="off"
              name="codigo_1"
              label="Ingrese el codigo de la caja 1"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.codigo_1}
              error={!!formik.errors.codigo_1}
            />
            <FormHelperText>{formik.errors.codigo_1}</FormHelperText>
          </FormGroup>
        </Grid>
        <Grid item md={12}>
          <FormGroup>
            <Inputs
              required
              autoComplete="off"
              name="codigo_2"
              label="Ingrese el codigo de la caja 2"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.codigo_2}
              error={!!formik.errors.codigo_2}
            />
            <FormHelperText>{formik.errors.codigo_2}</FormHelperText>
          </FormGroup>
        </Grid>
        <ButtonContainer container align="center">
          <Grid item md={6}>
            <ButtonPrimary
              textArea="Cancelar"
              variant="outlined"
              color="primary"
              minwidth="true"
              mright="true"
              onClick={onClose}
            />
          </Grid>
          {/* Toca validar el formulario completo jeje aun falta */}
          <Grid item md={6}>
            <ButtonPrimary
              type="submit"
              textArea="Asignar viaje"
              minwidth="false"
              disabled={validated}
              variant="contained"
              color="primary"
            />
          </Grid>
        </ButtonContainer>
      </StyledForm>
    </>
  );
};
