import styled from "styled-components";

export const Container = styled.div`
  display: flow-root;
  background-color: #c1c5c8;
  overflow: auto;
  position: relative;
  margin: 0;
  padding: 0;
  height: calc(100vh - 60px);
  overflow: auto;
`;
export const Acordeon = styled.div`
  display: block;
  width: calc(100% - 30px);
  height: auto;
  overflow: hidden;
  margin: 15px;
  margin-bottom: ${(props) => (props.marginbottom ? "70px" : "")};
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
`;
export const Cabecera = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 40px;
  border-bottom: solid 1px #4f4184;
`;
export const Collapse = styled.div`
  cursor: pointer;
  width: 40px;
  text-align: center;
  line-height: 40px;
  color: #4f4184;
  &:hover {
    background-color: #f0f0f0;
    border-radius: 50%;
  }
`;
export const NameAcordeon = styled.div`
  width: calc(100% - 74px);
  text-align: left;
  line-height: 40px;
`;
export const ShowMap = styled.div`
  height: 40px;
  width: 40px;
  cursor: pointer;
  text-align: center;
  color: #4f4184;
  display: flex;
  align-items: center;
  justify-content: center;
  display: ${(props) => (props.show ? "none" : "")};
  &:hover {
    background-color: #f0f0f0;
    border-radius: 50%;
  }
`;
export const Contenido = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  overflow: hidden;
`;
export const Camiones = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0;
  width: 100%;
`;
export const Camion = styled.li`
  display: flex;
  align-items: ${(props) => (props.align ? "center" : "")};
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0;
  width: 100%;
  min-height: 40px;
  border-bottom: solid 1px #c1c5c8;
  &.SC {
    p {
      width: calc(100% - 174px);
    }
  }
  @media only screen and (min-width: 1024px) {
    &.SC {
      p {
        width: calc(100% - 90px);
      }
    }
  }
`;
export const Name = styled.p`
  display: block;
  width: calc(100% - 130px);
  padding: 8px 25px;
  line-height: 1rem;
  margin: 0;
  font-size: 1rem;
  color: #414042;
  @media (min-width: 1024px) {
    width: calc(100% - 100px);
  }
`;
export const Recomendacion = styled.button`
  border: none;
  color: #ffffff;
  border-radius: 0;
  line-height: 40px;
  background: #4f4184;
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 100;
  font-size: 0.9rem;
  /* padding: 0 20px; */
  width: 174px;
  @media (min-width: 1024px) {
    width: 90px;
  }
  @media (max-width: 1920px) {
    width: 125px;
  }
`;
export const EnPad = styled.span`
  border: none;
  color: #414042;
  line-height: 14px;
  background: #cbcbcb;
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 100;
  font-size: 0.7rem;
  padding: 8px 0;
  text-align: center;
  width: 174px;
  @media (min-width: 1024px) {
    width: 90px;
  }
  @media (max-width: 1920px) {
    width: 174px;
  }
`;
export const Detalle = styled.div`
  display: flow-root;
  width: calc(100% - 50px);
  margin: 15px 16px 15px 0;
  padding: 0 25px;
  border-right: solid #414042 1px;
  > p {
    color: #414042;
    font-family: "Roboto-Regular", sans-serif;
    margin: 0;
    padding: 2px 0;
    display: table;
    width: 100%;
    border-bottom: solid 1px #414042;
    :last-child {
      border-bottom: none;
    }
    b,
    span {
      width: 50%;
      display: table-cell;
    }
  }
`;
export const Placa = styled.p`
  font-size: 0.9rem;
`;
export const Destino = styled.p`
  font-size: 0.7rem;
`;
export const LlegadaPad = styled.p`
  font-size: 0.7rem;
`;
export const Arena = styled.p`
  font-size: 0.7rem;
`;
export const Estado = styled.p`
  font-size: 0.7rem;
`;
