import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import Indicadores from "../Components/Indicadores/Indicadores";
import Header from "../Components/Header/Header";
import HeaderPad from "../Components/HeaderPad/HeaderPad";

import { ModificarTrayectoForm } from "../Components/Camiones/ModificarTrayectoForm/ModificarTrayectoForm";
import { ModificarTrayectoApprove } from "../Components/Camiones/ModificarTrayectoApprove/ModificarTrayectoApprove";
import { ModificarTrayectoError } from "../Components/Camiones/ModificarTrayectoError/ModificarTrayectoError";
import { Sidebar } from "../Components/Sidebar/Sidebar";

import FullPageLoader from "../utils/FullPageLoader/FullPageLoader";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  @media (max-width: 768px) {
    overflow: auto;
    display: block;
  }
`;
const Home = styled(Grid)`
  position: relative;
`;

export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ModalWrapper = styled.div`
  background: #ffffff;
  border-radius: 0.3125rem;
  border: 0.0625rem solid #e5e5e5;
  box-shadow: 0rem 1.25rem 2.5rem -0.75rem rgba(196, 196, 196, 0.35);
  box-sizing: border-box;
  margin-top: 11.4375rem;
  padding: 1.25rem 1.875rem;
  width: 31.25rem;
  z-index: 10;
`;

export const EditarTrayectoForm = () => {
  const [showForm, setShowForm] = useState(false);
  const [approve, setApprove] = useState();
  const isLoading = useSelector((state) => state.loading);

  const { Info_de_indicadores } = useSelector((state) => state.indicadores);
  const response = useSelector((state) => state.response);
  const { Role } = useSelector((state) => state.dataUser.user);
  const [showGraph, setShowGraph] = useState(false);

  return (
    <Home container>
      <Grid item xs={12} md={3} sm={3}>
        <Sidebar />
        <HeaderPad />
      </Grid>
      <Grid item xs={12} md={9}>
        <Header />
      </Grid>
      <Container>
        <Grid item xs={12} md={3}>
          <Indicadores
            showGraph={showGraph}
            setShowGraph={setShowGraph}
            Role={Role}
            Info_de_indicadores={Info_de_indicadores}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <ModificarTrayectoForm
            setApprove={setApprove}
            setShowForm={setShowForm}
            showForm={showForm}
            approve={approve}
          />
        </Grid>
      </Container>
      {response.type === "trayectoApp" && <ModificarTrayectoApprove />}

      {response.type === "trayectoErr" && <ModificarTrayectoError />}
      <FullPageLoader isLoading={isLoading} />
    </Home>
  );
};
