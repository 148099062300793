import { GET_PLANTAS_HISTORICAS } from "../constants/actionsTypes";

const initialState = [];

export const plantasHistoricasReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PLANTAS_HISTORICAS: {
      // console.log(
      //   "Valores recibidos en la acción SET_PLANTAS:",
      //   action.payload
      // );
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};
