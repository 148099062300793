import styled from "styled-components"
import { Grid } from '@material-ui/core';

export const ContainToltip = styled(Grid)`
  display: flex;
  padding: 0.375rem 1.188rem;
  align-items: center;
  & p {
    margin: 0;
  }
  .icon_update{
    color: #575757;
    height: 32px;
    width: 32px;
    margin-left: 1px;
  }
`

export const Divider = styled.hr`
  padding: 0 1.875rem;
  margin: 0;
`