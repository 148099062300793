import { GET_INFO_PAD, RESET_INFO_PAD } from "../constants/actionsTypes";

export const getInfoPad = (payload) => {
  return {
    type: GET_INFO_PAD,
    payload,
  };
};

export const resetInfoPad = (payload) => ({
  type: RESET_INFO_PAD,
  payload,
});
