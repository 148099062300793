import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Grid, TextField, FormHelperText } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  Head,
  Buton,
  StyledForm,
  Title,
  FormGroup,
  Divider,
  GridItem,
  ButtonContainer,
} from "./Styles";

// import { KeyboardDatePicker } from '@material-ui/pickers';

/* Iconos */
import { ReactComponent as HomeIcon } from "../../../assets/img/homeIcon.svg";

/* componentes */
import { Inputs } from "../../Input/Inputs";
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

import { editarEtapas } from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  fecha_inicio: yup.string().required("Este campo es requerido"),
  hora_salida: yup.string().required("Este campo es requerido"),
  cajas_M3070: yup.object({
    caja_1: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_2: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_3: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_4: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_5: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_6: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_7: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_8: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_9: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_10: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_11: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_12: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_13: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_14: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_15: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_16: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_17: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_18: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
  }),
  cajas_M100: yup.object({
    caja_1: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_2: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_3: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_4: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
    caja_5: yup
      .string()
      .matches(/^([A-Z]){2}([0-9]){4}$/g, "Código de caja invalido"),
  }),
});

export const EtapaForm = () => {
  const dataEtapa = useSelector((state) => state.consultarEtapas);
  const history = useHistory();
  const dispatch = useDispatch();

  const [editDate, setEditDate] = useState(
    dataEtapa?.accionEtapa?.fecha_creacion
  );
  const [editTime, setEditTime] = useState(
    dataEtapa?.accionEtapa?.hora_creacion
  );
  const [idCajasM100, setIdCajasM100] = useState([]);
  const [idCajasM3070, setIdCajasM370] = useState([]);

  const formik = useFormik({
    initialValues: {
      fecha_inicio: dataEtapa?.accionEtapa?.fecha_creacion,
      hora_salida: dataEtapa?.accionEtapa?.hora_creacion,
      cajas_M3070: {
        caja_1: "",
        caja_2: "",
        caja_3: "",
        caja_4: "",
        caja_5: "",
        caja_6: "",
        caja_7: "",
        caja_8: "",
        caja_9: "",
        caja_10: "",
        caja_11: "",
        caja_12: "",
        caja_13: "",
        caja_14: "",
        caja_15: "",
        caja_16: "",
        caja_17: "",
        caja_18: "",
      },
      cajas_M100: {
        caja_1: "",
        caja_2: "",
        caja_3: "",
        caja_4: "",
        caja_5: "",
      },
    },
    validationSchema: validationSchema,

    onSubmit: () => {
      if (dataEtapa.accionEtapa.action === "Editar") {
        dispatch(
          editarEtapas({
            fecha_inicio_etapa_original:
              dataEtapa.accionEtapa.fecha_inicio_etapa_original,
            fecha_inicio_etapa: `${editDate}T${editTime}`,
            fecha_creacion: editDate,
            hora_creacion: editTime,
            id_box_m3070: idCajasM3070.join("-"),
            id_box_m100: idCajasM100.join("-"),
            id_box: "id",
          })
        );
      }
    },
  });

  const [valueText, setValueTex] = useState(formik);

  const onChange = (event) => {
    const name = event.target.name;
    const id = event.target.id;
    const value = event.target.value;

    if (id.slice(0, 11) === "cajas_M3070") {
      Object.assign(formik.values.cajas_M3070, {
        [name]: value,
      });
    }

    if (id.slice(0, 10) === "cajas_M100") {
      Object.assign(formik.values.cajas_M100, {
        [name]: value,
      });
    }

    setValueTex((state) => Object.assign({}, state, formik));
    currentCajas(formik);
  };

  function cancelar() {
    history.push("/home");
  }

  function home() {
    history.push("/home");
  }

  useEffect(() => {
    const fecha = formik.values.fecha_inicio;
    const hora = formik.values.hora_salida;

    if (formik.values.fecha_inicio && formik.values.hora_salida) {
      setEditDate(fecha);
      setEditTime(hora);
    }
  }, [setEditDate, setEditTime, formik]);

  useEffect(() => {
    const cm100 = dataEtapa?.accionEtapa?.id_box_m100?.split("-");
    const cm3070 = dataEtapa?.accionEtapa?.id_box_m3070?.split("-");

    if (cm100.length > 0) {
      cm100.forEach((item, index) => {
        formik.values.cajas_M100[`caja_${index + 1}`] = item;
      });

      setValueTex((state) => Object.assign({}, state, formik));
      currentCajas(formik);
    }

    if (cm3070.length > 0) {
      cm3070.forEach((item, index) => {
        formik.values.cajas_M3070[`caja_${index + 1}`] = item;
      });

      setValueTex((state) => Object.assign({}, state, formik));
      currentCajas(formik);
    }
  }, []);

  const currentCajas = useCallback(() => {
    const cajasM3070 = Object.values(formik.values.cajas_M3070);
    const cajasM100 = Object.values(formik.values.cajas_M100);

    const resultCajasM100 = cajasM100
      .map((itemCajas) => {
        return `${itemCajas}`;
      })
      .filter((itemCajas) => itemCajas !== "");

    const resultCajasM3070 = cajasM3070
      .map((itemCajas) => {
        return `${itemCajas}`;
      })
      .filter((itemCajas) => itemCajas !== "");

    setIdCajasM100((state) => resultCajasM100);
    setIdCajasM370((state) => resultCajasM3070);
  }, [formik, setIdCajasM100, setIdCajasM370]);

  return (
    <Container>
      <Grid item md={12} sm={12}>
        <Head>
          <Buton variant="outlined" color="primary" onClick={home}>
            <HomeIcon />
          </Buton>
        </Head>
      </Grid>
      <StyledForm onSubmit={formik.handleSubmit}>
        <Grid item xs={12} md={12} sm={12}>
          <Title>
            Información de etapa #{dataEtapa.accionEtapa.nombre_etapa}
          </Title>
        </Grid>
        <Grid container style={{ paddingTop: "20px" }}>
          <GridItem item xs={12} md={6} sm={6} style={{ margin: 0 }}>
            <FormGroup>
              <TextField
                id="fecha_inicio"
                name="fecha_inicio"
                label="Fecha de inicio de etapa*"
                type="date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={editDate}
                error={!!formik.errors.fecha_inicio}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormHelperText>{formik.errors?.fecha_inicio}</FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <TextField
                id="hora_salida"
                name="hora_salida"
                label="Hora de salida*"
                placeholder="Hora de salida*"
                type="time"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={editTime}
                error={!!formik.errors?.hora_salida}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormHelperText>{formik.errors?.hora_salida}</FormHelperText>
            </FormGroup>
          </GridItem>
        </Grid>
        <Divider />
        <Grid item md={12} sm={12}>
          <Title>Ingresar cajas M3070</Title>
        </Grid>
        <Grid container>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-1"
                autoComplete="off"
                type="text"
                name="caja_1"
                label="Caja 1"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values?.cajas_M3070?.caja_1}
                error={formik.errors?.cajas_M3070?.caja_1}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_1}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-2"
                autoComplete="off"
                name="caja_2"
                label="Caja 2"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values?.cajas_M3070?.caja_2}
                error={formik.errors?.cajas_M3070?.caja_2}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_2}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-3"
                autoComplete="off"
                name="caja_3"
                label="Caja 3"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values?.cajas_M3070?.caja_3}
                error={formik.errors?.cajas_M3070?.caja_3}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_3}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-4"
                autoComplete="off"
                name="caja_4"
                label="Caja 4"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values?.cajas_M3070?.caja_4}
                error={formik.errors?.cajas_M3070?.caja_4}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_4}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-5"
                autoComplete="off"
                name="caja_5"
                label="Caja 5"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values?.cajas_M3070?.caja_5}
                error={formik.errors?.cajas_M3070?.caja_5}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_5}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-6"
                autoComplete="off"
                name="caja_6"
                label="Caja 6"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values?.cajas_M3070?.caja_6}
                error={formik.errors?.cajas_M3070?.caja_6}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_6}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-7"
                autoComplete="off"
                name="caja_7"
                label="Caja 7"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M3070.caja_7}
                error={formik.errors?.cajas_M3070?.caja_7}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_7}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-8"
                autoComplete="off"
                name="caja_8"
                label="Caja 8"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M3070.caja_8}
                error={formik.errors?.cajas_M3070?.caja_8}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_8}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-9"
                autoComplete="off"
                name="caja_9"
                label="Caja 9"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M3070.caja_9}
                error={formik.errors?.cajas_M3070?.caja_9}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_9}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-10"
                autoComplete="off"
                name="caja_10"
                label="Caja 10"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M3070.caja_10}
                error={formik.errors?.cajas_M3070?.caja_10}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_10}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-11"
                autoComplete="off"
                name="caja_11"
                label="Caja 11"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M3070.caja_11}
                error={formik.errors?.cajas_M3070?.caja_11}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_11}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-12"
                autoComplete="off"
                name="caja_12"
                label="Caja 12"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M3070.caja_12}
                error={formik.errors?.cajas_M3070?.caja_12}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_12}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-13"
                autoComplete="off"
                name="caja_13"
                label="Caja 13"
                onChange={onChange}
                nBlur={formik.handleBlur}
                value={valueText.values.cajas_M3070.caja_13}
                error={formik.errors?.cajas_M3070?.caja_13}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_13}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-14"
                autoComplete="off"
                name="caja_14"
                label="Caja 14"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M3070.caja_14}
                error={formik.errors?.cajas_M3070?.caja_14}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_14}
              </FormHelperText>
            </FormGroup>
          </GridItem>

          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-15"
                autoComplete="off"
                name="caja_15"
                label="Caja 15"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M3070.caja_15}
                error={formik.errors?.cajas_M3070?.caja_15}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_15}
              </FormHelperText>
            </FormGroup>
          </GridItem>

          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-16"
                autoComplete="off"
                name="caja_16"
                label="Caja 16"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M3070.caja_16}
                error={formik.errors?.cajas_M3070?.caja_16}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_16}
              </FormHelperText>
            </FormGroup>
          </GridItem>

          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-17"
                autoComplete="off"
                name="caja_17"
                label="Caja 17"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M3070.caja_17}
                error={formik.errors?.cajas_M3070?.caja_17}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_17}
              </FormHelperText>
            </FormGroup>
          </GridItem>

          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M3070-18"
                autoComplete="off"
                name="caja_18"
                label="Caja 18"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M3070.caja_18}
                error={formik.errors?.cajas_M3070?.caja_18}
              />
              <FormHelperText>
                {formik.errors?.cajas_M3070?.caja_18}
              </FormHelperText>
            </FormGroup>
          </GridItem>

          <Divider />
          <Grid item md={12} sm={12}>
            <Title>Ingresar cajas M100</Title>
          </Grid>
          <GridItem xs={12} item md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M100-1"
                autoComplete="off"
                name="caja_1"
                label="Caja 1"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M100.caja_1}
                error={formik.errors?.cajas_M100?.caja_1}
              />
              <FormHelperText>
                {formik.errors?.cajas_M100?.caja_1}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M100-2"
                autoComplete="off"
                name="caja_2"
                label="Caja 2"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M100.caja_2}
                error={formik.errors?.cajas_M100?.caja_2}
              />
              <FormHelperText>
                {formik.errors?.cajas_M100?.caja_2}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M100-3"
                autoComplete="off"
                name="caja_3"
                label="Caja 3"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M100.caja_3}
                error={formik.errors?.cajas_M100?.caja_3}
              />
              <FormHelperText>
                {formik.errors?.cajas_M100?.caja_3}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M100-4"
                autoComplete="off"
                name="caja_4"
                label="Caja 4"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M100.caja_4}
                error={formik.errors?.cajas_M100?.caja_4}
              />
              <FormHelperText>
                {formik.errors?.cajas_M100?.caja_4}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup>
              <Inputs
                id="cajas_M100-5"
                autoComplete="off"
                name="caja_5"
                label="Caja 5"
                onChange={onChange}
                onBlur={formik.handleBlur}
                value={valueText.values.cajas_M100.caja_5}
                error={formik.errors?.cajas_M100?.caja_5}
              />
              <FormHelperText>
                {formik.errors?.cajas_M100?.caja_5}
              </FormHelperText>
            </FormGroup>
          </GridItem>
          <Divider />
          <ButtonContainer container align="center">
            <Grid item xs={6} md={5} sm={6}>
              <ButtonPrimary
                type="button"
                textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                onClick={cancelar}
                variant="outlined"
                color="primary"
                minwidth="true"
              />
            </Grid>
            <Grid item xs={6} md={6} sm={6}>
              <ButtonPrimary
                type="submit"
                textArea="Guardar"
                disabled={!formik.isValid}
                variant="contained"
                color="primary"
                minwidth="true"
              />
            </Grid>
          </ButtonContainer>
        </Grid>
      </StyledForm>
    </Container>
  );
};
