import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  Menu,
  Cabecera,
  NameSelect,
  ToggleMenu,
  Options,
  Item,
  LinkItem,
  SubItem,
  Hr,
  ContentSelect,
  SubItems,
} from "./Styles";

import { PanelCamiones } from "../Camiones/PanelCamiones/PanelCamiones.js";
import { PanelPlantas } from "../Plantas/PanelPlantas/PanelPlantas.js";
import { Alert } from "../Alertas/Alert/Alert";
import {
  deshabilitarCamionGet,
  getAlerts,
  getEtapas,
  getHistoricoCamiones,
} from "../../Services";
// import { getFechaEtapa } from '../../actions/consultarEtapasActions';
import { currentDescargar, setCurrentTags } from "../../actions/alertasActions";

export const OptionsRight = ({
  openModalToggleHabilitarNuevoCamion,
  openModalToggleDeshabilitarCamion,
  openModalToggleHabilitarCamion,
  togglePad,
  toggleTransitoVacio,
  toggleCarga,
  toggleTransitoLleno,
  toggleSinGeotab,
  CamionPad,
  transitoVacio,
  cargaPlanta,
  transitoLleno,
  sinGeotab,
  formValue,
  setFormValue,
  showModalRecomended,
  setShowModalRecomended,
  openModalToggleRecomended,
  stepsRecomended,
  estadoCamionesData,
  Role,
  openMenuToggle,
  openAlertToggle,
  openCamionesToggle,
  openPlantasToggle,
  toAsignarPlanta,
  toHabilitarPlanta,
  toEditarPlanta,
  showCamiones,
  showAlert,
  showPlantas,
  showMenu,
  setShowMenu,
  plantas,
  setShowModalReporteCamionesDetenidos,
}) => {
  const { currentDescarga } = useSelector((state) => state.alertas);
  const [open] = useState();
  const [urlHistorico, setUrlHistorico] = useState("");
  const [urlAlerts, setUrlAlerts] = useState("");
  const [urlEtapas, setUrlEtapas] = useState("");
  let history = useHistory();
  const dispatch = useDispatch();
  const closeMenuReporteCamionesDetenidos = () => {
    setShowModalReporteCamionesDetenidos(true);
  };

  const handleHabilitarCamionClick = () => {
    openModalToggleHabilitarCamion();
    setShowMenu(false);
  };

  const handleHabilitarNuevoCamionClick = () => {
    openModalToggleHabilitarNuevoCamion();
    setShowMenu(false);
  };

  const handleDeshabilitarCamionClick = () => {
    openModalToggleDeshabilitarCamion();
    dispatch(deshabilitarCamionGet());
    setShowMenu(false);
  };

  useEffect(() => {
    if (Role === "SupplyChain" || Role === "CoordBrent") {
      getAlerts()
        .then((res) => {
          setUrlAlerts(res.data.url);
        })
        .catch((error) => {
          console.log(error.message);
        });

      getEtapas()
        .then((res) => {
          setUrlEtapas(res.data.url);
        })
        .catch((error) => {
          console.log(error.message);
        });

      getHistoricoCamiones()
        .then((res) => {
          setUrlHistorico(res.data.url);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }

    return () => {
      dispatch(currentDescargar(""));
    };
  }, [setUrlHistorico, setUrlAlerts, setUrlEtapas, Role, currentDescarga]);

  /* const getFechaDispat = (fecha) => dispatch(getFechaEtapa({
    capturasFechaEtapa: fecha,
    action: 'Ingresar'
  })) */

  const handleCurrent = (action) => dispatch(setCurrentTags(action));

  return (
    <div className={open ? "blackColor" : ""}>
      {/* <button onClick={() => setOpen(!open)}>Show</button> */}
      <Container container xs={12} md={3} className={open ? "open768" : ""}>
        <Menu>
          <Cabecera>
            {showAlert && (
              <NameSelect onClick={() => handleCurrent("todos")}>
                Alertas
              </NameSelect>
            )}
            {showCamiones && <NameSelect>Camiones</NameSelect>}
            {showPlantas && <NameSelect>Plantas</NameSelect>}
            <ToggleMenu onClick={() => openMenuToggle()}>
              {showMenu ? (
                <FontAwesomeIcon icon={faTimes} />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </ToggleMenu>
          </Cabecera>
        </Menu>
        {Role === "SupplyChain" && (
          <Options className={showMenu ? "open" : ""}>
            <LinkItem head onClick={() => openAlertToggle()}>
              Alertas
            </LinkItem>
            <SubItems>
              <a
                href={urlAlerts}
                onClick={() => dispatch(currentDescargar("alertas"))}
              >
                Descargar historial de alertas
              </a>
            </SubItems>
            <Hr />
            <LinkItem onClick={() => openCamionesToggle()}>Camiones</LinkItem>
            <SubItem onClick={() => handleHabilitarCamionClick()}>
              Asignar camiones
            </SubItem>
            <SubItem onClick={() => handleHabilitarNuevoCamionClick()}>
              Habilitar nuevo camión
            </SubItem>
            <SubItem onClick={() => handleDeshabilitarCamionClick()}>
              Deshabilitar camión
            </SubItem>
            <SubItems>
              <a
                href={urlHistorico}
                onClick={() => dispatch(currentDescargar("historico"))}
              >
                Descargar historial de camiones
              </a>
            </SubItems>
            <SubItem onClick={() => closeMenuReporteCamionesDetenidos()}>
              Descargar reporte de camiones detenidos
            </SubItem>
            <Hr />
            <LinkItem onClick={() => openPlantasToggle()}>Plantas</LinkItem>
            <SubItem onClick={() => toAsignarPlanta()}>Asignar plantas</SubItem>
            <SubItem onClick={() => toHabilitarPlanta()}>
              Habilitar nueva planta
            </SubItem>
            <SubItem onClick={() => toEditarPlanta()}>Editar planta</SubItem>
            <Hr />
            <LinkItem>Etapas</LinkItem>
            <SubItems>
              <a
                href={urlEtapas}
                onClick={() => dispatch(currentDescargar("etapas"))}
              >
                Descargar historial de etapas
              </a>
            </SubItems>
          </Options>
        )}
        {Role === "CoordBrent" && (
          <Options className={showMenu ? "open" : ""}>
            <LinkItem head onClick={() => openAlertToggle()}>
              Alertas
            </LinkItem>
            <SubItems>
              <a
                href={urlAlerts}
                onClick={() => dispatch(currentDescargar("alertas"))}
              >
                Descargar historial de alertas
              </a>
            </SubItems>
            <Hr />
            <LinkItem onClick={() => openCamionesToggle()}>Camiones</LinkItem>
            <SubItem onClick={() => handleHabilitarCamionClick()}>
              Asignar camiones
            </SubItem>
            <SubItem onClick={() => handleHabilitarNuevoCamionClick()}>
              Habilitar nuevo camión
            </SubItem>
            <SubItem onClick={() => handleDeshabilitarCamionClick()}>
              Deshabilitar camión
            </SubItem>
            <SubItem onClick={() => closeMenuReporteCamionesDetenidos()}>
              Descargar reporte de camiones detenidos
            </SubItem>
            <SubItems>
              <a
                href={urlHistorico}
                onClick={() => dispatch(currentDescargar("historico"))}
              >
                Descargar historial de camiones
              </a>
            </SubItems>
            <Hr />
            {/* <LinkItem onClick={() => openPlantasToggle()}>Plantas</LinkItem>
            <SubItem onClick={() => toHabilitarPlanta()}>
              Habilitar nueva planta
            </SubItem>
            <SubItem onClick={() => toEditarPlanta()}>Editar planta</SubItem>
            <Hr /> */}
            <LinkItem>Etapas</LinkItem>
            <SubItems>
              <a
                href={urlEtapas}
                onClick={() => dispatch(currentDescargar("etapas"))}
              >
                Descargar historial de etapas
              </a>
            </SubItems>
          </Options>
        )}
        {Role === "Brent" && (
          <Options className={showMenu ? "open" : ""}>
            <LinkItem onClick={() => openCamionesToggle()}>Camiones</LinkItem>
            <SubItem onClick={() => history.push("/editarTrayecto")}>
              Modificar trayecto
            </SubItem>
            <SubItem onClick={() => handleHabilitarCamionClick()}>
              Asignar camiones
            </SubItem>
            <SubItem onClick={() => handleHabilitarNuevoCamionClick()}>
              Habilitar nuevo camión
            </SubItem>
            <SubItem onClick={() => handleDeshabilitarCamionClick()}>
              Deshabilitar camión
            </SubItem>
            <SubItem onClick={() => closeMenuReporteCamionesDetenidos()}>
              Descargar reporte de camiones detenidos
            </SubItem>
            <Hr />
            <Item>Etapas</Item>
            {/* <SubItem onClick={() => {
                getFechaDispat('')
                history.push('/ingresarEtapa')
              }}>Ingresar una etapa</SubItem> */}
            <SubItem onClick={() => history.push("/editarEtapas")}>
              Editar etapas
            </SubItem>
          </Options>
        )}

        {showCamiones && (
          <ContentSelect>
            <PanelCamiones
              togglePad={togglePad}
              toggleTransitoVacio={toggleTransitoVacio}
              toggleCarga={toggleCarga}
              toggleTransitoLleno={toggleTransitoLleno}
              toggleSinGeotab={toggleSinGeotab}
              sinGeotab={sinGeotab}
              CamionPad={CamionPad}
              transitoVacio={transitoVacio}
              cargaPlanta={cargaPlanta}
              transitoLleno={transitoLleno}
              formValue={formValue}
              setFormValue={setFormValue}
              showModalRecomended={showModalRecomended}
              setShowModalRecomended={setShowModalRecomended}
              openModalToggleRecomended={openModalToggleRecomended}
              stepsRecomended={stepsRecomended}
              estadoCamionesData={estadoCamionesData}
              Role={Role}
            />
          </ContentSelect>
        )}
        {showAlert && (
          <ContentSelect>
            <Alert />
          </ContentSelect>
        )}
        {showPlantas && (
          <ContentSelect>
            <PanelPlantas plantas={plantas} />
          </ContentSelect>
        )}
      </Container>
    </div>
  );
};
