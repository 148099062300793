import styled from "styled-components";

export const Container = styled.div`
  display: flow-root;
  background-color: #c1c5c8;
  overflow: auto;
  position: relative;
  margin: 0;
  padding: 0;
  height: calc(100vh - 60px);
  overflow: auto;
`;
export const Plantas = styled.ul`
  display: block;
  width: 100%;
  margin: 0 0 60px 0;
  padding: 0;
  list-style: none;
`;
export const Planta = styled.li`
  display: block;
  width: calc(100% - 30px);
  height: auto;
  overflow: hidden;
  margin: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
`;
export const Cabecera = styled.p`
  display: block;
  width: 100%;
  min-height: 40px;
  overflow: hidden;
  margin: 0;
  padding: 9px 15px;
  color: #414042;
  font-size: 0.9rem;
  font-family: "Roboto-Medium", sans-serif;
  font-weight: 600;
`;
export const InfoPlanta = styled.div`
  display: block;
  width: 100%;
  min-height: 40px;
  overflow: hidden;
  margin: 0 0 15px 0;
  padding: 0 15px;
  color: #414042;
  font-size: 0.9rem;
  table {
    width: 100%;
    th {
      text-align: right;
    }
    td {
      text-align: right;
      border-top: solid 1px #c4c4c4;
      :first-child {
        text-align: left;
        font-weight: 600;
      }
    }
  }
`;
