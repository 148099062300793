import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Grid, FormHelperText } from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  GridItem,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../Input/Inputs";
import { addHours } from "../../utils/DateTimeConverter/DateTimeConverter";
import { EditarPad } from "../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  name: yup.string(),
  latitude: yup
    .number()
    .lessThan(-30, "El valor debe estar entre -30 y -40")
    .moreThan(-40, "El valor debe estar entre -30 y -40")
    .required("Campo requerido"),
  longitude: yup
    .number()
    .lessThan(-60, "El valor debe estar entre -60 y -70")
    .moreThan(-70, "El valor debe estar entre -60 y -70")
    .required("Campo requerido"),
  geothresh: yup.number(),
  box_full_m100: yup.number().moreThan(-1, "El valor debe ser positivo"),
  box_full_m3070: yup.number().moreThan(-1, "El valor debe ser positivo"),
  feed_rate_per_stage_m100: yup
    .number()
    .moreThan(-1, "El valor debe ser positivo"),
  feed_rate_per_stage_m3070: yup
    .number()
    .moreThan(-1, "El valor debe ser positivo"),
  stage_per_day: yup.number().moreThan(-1, "El valor debe ser positivo"),
  total_stages: yup.number().moreThan(-1, "El valor debe ser positivo"),
});

export const ModalEditarPad = ({ onClose }) => {
  const dispatch = useDispatch();
  const { infoPad } = useSelector((state) => state);
  const hoyArgentina = addHours(-3, new Date()).toISOString();
  const { Name, Role, Email } = useSelector((state) => state.dataUser.user);
  const formik = useFormik({
    initialValues: {
      name: infoPad?.pad?.name ? infoPad?.pad?.name : 0,
      latitude: infoPad?.pad?.latitude ? infoPad?.pad?.latitude : 0,
      longitude: infoPad?.pad?.longitude ? infoPad?.pad?.longitude : 0,
      geothresh: infoPad?.pad?.geothresh ? infoPad?.pad?.geothresh : 0,
      box_full_m100: infoPad?.pad?.box_full_m100
        ? infoPad?.pad?.box_full_m100
        : 0,
      box_full_m3070: infoPad?.pad?.box_full_m3070
        ? infoPad?.pad?.box_full_m3070
        : 0,
      feed_rate_per_stage_m100: infoPad?.pad?.feed_rate_per_stage_m100
        ? infoPad?.pad?.feed_rate_per_stage_m100
        : 0,
      feed_rate_per_stage_m3070: infoPad?.pad?.feed_rate_per_stage_m3070
        ? infoPad?.pad?.feed_rate_per_stage_m3070
        : 0,
      stage_per_day: infoPad?.pad?.stage_per_day
        ? infoPad?.pad?.stage_per_day
        : 0,
      total_stages: infoPad?.pad?.total_stages ? infoPad?.pad?.total_stages : 0,
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      dispatch(
        EditarPad({
          name: values.name,
          latitude: values.latitude,
          longitude: values.longitude,
          geothresh: values.geothresh,
          box_full_m100: values.box_full_m100,
          box_full_m3070: values.box_full_m3070,
          feed_rate_per_stage_m100: values.feed_rate_per_stage_m100,
          feed_rate_per_stage_m3070: values.feed_rate_per_stage_m3070,
          stage_per_day: values.stage_per_day,
          total_stages: values.total_stages,
          user_name: Name,
          user_email: Email,
          user_rol: Role,
          datetime: hoyArgentina,
        })
      );

      onClose();
    },
  });

  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Editar Pad {formik.values.name}</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="name"
                  autoComplete="off"
                  name="name"
                  label="Nombre del pad"
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
              </FormGroup>
            </GridItem>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="latitude"
                  autoComplete="off"
                  name="latitude"
                  label="Latitud (grados decimales)"
                  type="number"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.latitude}
                  error={formik.errors.latitude && formik.touched.latitude}
                />
                {formik.errors.latitude && formik.touched.latitude && (
                  <FormHelperText
                    error={formik.errors.latitude && formik.touched.latitude}
                  >
                    {formik.errors.latitude}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="longitude"
                  autoComplete="off"
                  name="longitude"
                  label="Longitud (grados decimales)"
                  type="number"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.longitude}
                  error={formik.errors.longitude}
                />
                {formik.errors.longitude && formik.touched.longitude && (
                  <FormHelperText
                    error={formik.errors.longitude && formik.touched.longitude}
                  >
                    {formik.errors.longitude}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="geothresh"
                  autoComplete="off"
                  name="geothresh"
                  label="Geocerca (km)"
                  type="number"
                  step="0.01"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.geothresh}
                  error={formik.errors.longitude}
                />
                {formik.errors.geothresh && formik.touched.geothresh && (
                  <FormHelperText
                    error={formik.errors.geothresh && formik.touched.geothresh}
                  >
                    {formik.errors.geothresh}
                  </FormHelperText>
                )}
              </FormGroup>
              <FormHelperText error={formik.errors.longitude}>
                {formik.errors.longitude}
              </FormHelperText>
            </GridItem>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="box_full_m100"
                  autoComplete="off"
                  name="box_full_m100"
                  label="Cajas llenas M100 (cantidad)"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.box_full_m100}
                  error={formik.errors.box_full_m100}
                />
                {formik.errors.box_full_m100 &&
                  formik.touched.box_full_m100 && (
                    <FormHelperText
                      error={
                        formik.errors.box_full_m100 &&
                        formik.touched.box_full_m100
                      }
                    >
                      {formik.errors.box_full_m100}
                    </FormHelperText>
                  )}
              </FormGroup>

              <FormHelperText error={formik.errors.box_full_m100}>
                {formik.errors.box_full_m100}
              </FormHelperText>
            </GridItem>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="box_full_m3070"
                  autoComplete="off"
                  name="box_full_m3070"
                  label="Cajas llenas M3070 (cantidad)"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.box_full_m3070}
                  error={formik.errors.box_full_m3070}
                />
                {formik.errors.box_full_m3070 &&
                  formik.touched.box_full_m3070 && (
                    <FormHelperText
                      error={
                        formik.errors.box_full_m3070 &&
                        formik.touched.box_full_m3070
                      }
                    >
                      {formik.errors.box_full_m3070}
                    </FormHelperText>
                  )}
              </FormGroup>
              <FormHelperText error={formik.errors.box_full_m3070}>
                {formik.errors.box_full_m3070}
              </FormHelperText>
            </GridItem>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="feed_rate_per_stage_m100"
                  autoComplete="off"
                  type="number"
                  name="feed_rate_per_stage_m100"
                  label="Consumo de cajas de arena M100 por etapa (cantidad)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.feed_rate_per_stage_m100}
                  error={
                    formik.errors.feed_rate_per_stage_m100 &&
                    formik.touched.feed_rate_per_stage_m100
                  }
                />
                {formik.errors.feed_rate_per_stage_m100 &&
                  formik.touched.feed_rate_per_stage_m100 && (
                    <FormHelperText
                      error={
                        formik.errors.feed_rate_per_stage_m100 &&
                        formik.touched.feed_rate_per_stage_m100
                      }
                    >
                      {formik.errors.feed_rate_per_stage_m100}
                    </FormHelperText>
                  )}
              </FormGroup>
            </GridItem>

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="feed_rate_per_stage_m3070"
                  autoComplete="off"
                  type="number"
                  name="feed_rate_per_stage_m3070"
                  label="Consumo de cajas de arena M3070 por etapa (cantidad)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.feed_rate_per_stage_m3070}
                  error={
                    formik.errors.feed_rate_per_stage_m3070 &&
                    formik.touched.feed_rate_per_stage_m3070
                  }
                />
                {formik.errors.feed_rate_per_stage_m3070 &&
                  formik.touched.feed_rate_per_stage_m3070 && (
                    <FormHelperText
                      error={
                        formik.errors.feed_rate_per_stage_m3070 &&
                        formik.touched.feed_rate_per_stage_m3070
                      }
                    >
                      {formik.errors.feed_rate_per_stage_m3070}
                    </FormHelperText>
                  )}
              </FormGroup>
            </GridItem>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="stage_per_day"
                  autoComplete="off"
                  name="stage_per_day"
                  label="Etapas por día (cantidad)"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.stage_per_day}
                  error={
                    formik.errors.stage_per_day && formik.touched.stage_per_day
                  }
                />
                {formik.errors.stage_per_day &&
                  formik.touched.stage_per_day && (
                    <FormHelperText
                      error={
                        formik.errors.stage_per_day &&
                        formik.touched.stage_per_day
                      }
                    >
                      {formik.errors.stage_per_day}
                    </FormHelperText>
                  )}
              </FormGroup>
            </GridItem>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="total_stages"
                  autoComplete="off"
                  name="total_stages"
                  label="Cantidad de etapas a ejecutar (cantidad)"
                  type="number"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.total_stages}
                  error={
                    formik.errors.total_stages && formik.touched.total_stages
                  }
                />
                {formik.errors.total_stages && formik.touched.total_stages && (
                  <FormHelperText
                    error={
                      formik.errors.total_stages && formik.touched.total_stages
                    }
                  >
                    {formik.errors.total_stages}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>
            <Grid container md={12} sm={8} justify="center">
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    type="button"
                    textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                    onClick={() => handleClose()}
                    variant="outlined"
                    color="primary"
                    minwidth="true"
                  />
                </ButtonContainer>
              </Grid>
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    // disabled={validated}
                    type="submit"
                    textArea="Guardar"
                    variant="contained"
                    color="primary"
                    mright="true"
                  />
                </ButtonContainer>
              </Grid>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
