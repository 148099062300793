import {
  GET_ESTADO_CAMIONES,
  RESET_ESTADO_CAMIONES,
  SHOW_CAR_TRANSITO_VACIO,
  SHOW_MAP_TRANSITO_VACIO,
  SHOW_CAR_PAD,
  SHOW_MAP_PAD,
  SHOW_CAR_CARGA_PLANTA,
  SHOW_MAP_CARGA_PLANTA,
  SHOW_CAR_TRANSITO_LLENO,
  SHOW_MAP_TRANSITO_LLENO,
  SHOW_CAR_SIN_GEOTAB,
  SHOW_MAP_SIN_GEOTAB,
} from "../constants/actionsTypes";

export const getEstadoCamiones = (payload) => {
  return {
    type: GET_ESTADO_CAMIONES,
    payload,
  };
};

export const resetEstadoCamiones = (payload) => ({
  type: RESET_ESTADO_CAMIONES,
  payload,
});

export const showCarTransitoVacio = (placa) => ({
  type: SHOW_CAR_TRANSITO_VACIO,
  payload: { placa },
});

export const showMapTransitoVacio = () => ({
  type: SHOW_MAP_TRANSITO_VACIO,
});

export const showCarPad = (placa) => ({
  type: SHOW_CAR_PAD,
  payload: { placa },
});

export const showMapPad = () => ({
  type: SHOW_MAP_PAD,
});

export const showCarCargaPlanta = (placa) => ({
  type: SHOW_CAR_CARGA_PLANTA,
  payload: { placa },
});

export const showMapCargaPlanta = () => ({
  type: SHOW_MAP_CARGA_PLANTA,
});

export const showCarTransitoLleno = (placa) => ({
  type: SHOW_CAR_TRANSITO_LLENO,
  payload: { placa },
});

export const showMapTransitoLleno = () => ({
  type: SHOW_MAP_TRANSITO_LLENO,
});

export const showCarSinGeotab = (placa) => ({
  type: SHOW_CAR_SIN_GEOTAB,
  payload: { placa },
});

export const showMapSinGeotab = () => ({
  type: SHOW_MAP_SIN_GEOTAB,
});
