import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Acordeon,
  Cabecera,
  Collapse,
  NameAcordeon,
} from "./Styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import { CardAlert } from "../CardAlert/CardAlert";
// import { alertas as alertasGet } from '../../../Services/index'
import { setCurrentTags } from "../../../actions/alertasActions";

export const Alert = ({ highAlert }) => {
  const dispatch = useDispatch();

  const { alertas } = useSelector((state) => state.alertas);
  const [toogleAltas, setToogleAltas] = useState(false);
  const [toogleMedias, setToogleMedias] = useState(false);
  const [toogleBajas, setToogleBajas] = useState(false);

  useEffect(() => {
    // dispatch(alertasGet())
  }, []);

  const handleCurrent = useCallback(
    (action) => dispatch(setCurrentTags(action)),
    [dispatch]
  );

  useEffect(() => {
    return () => handleCurrent("todos");
  }, [dispatch, handleCurrent]);

  return (
    <Container container md={3}>
      {/* Altas */}
      <Acordeon border={highAlert}>
        <Cabecera>
          <Collapse
            onClick={() => {
              setToogleAltas(!toogleAltas);
            }}
          >
            {toogleAltas ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </Collapse>
          <NameAcordeon>
            Altas: <span>{alertas?.alertasAltas?.length}</span>
          </NameAcordeon>
        </Cabecera>
        {toogleAltas && (
          <div style={{ backgroundColor: "" }}>
            {alertas?.alertasAltas?.map((item, index) => {
              Object.assign(item, { tipo: "Altas" });
              return <CardAlert key={index} highAlert data={item} />;
            })}
          </div>
        )}
      </Acordeon>
      {/* Medias */}
      <Acordeon>
        <Cabecera>
          <Collapse
            onClick={() => {
              setToogleMedias(!toogleMedias);
            }}
          >
            {toogleMedias ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </Collapse>
          <NameAcordeon>
            Medias: <span>{alertas?.alertasMedias?.length}</span>
          </NameAcordeon>
        </Cabecera>
        {toogleMedias && (
          <div style={{ backgroundColor: "" }}>
            {alertas?.alertasMedias?.map((item, index) => {
              Object.assign(item, { tipo: "Medias" });
              return <CardAlert key={index} highAlert={false} data={item} />;
            })}
          </div>
        )}
      </Acordeon>
      {/* Bajas */}
      <Acordeon>
        <Cabecera>
          <Collapse
            onClick={() => {
              setToogleBajas(!toogleBajas);
            }}
          >
            {toogleBajas ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </Collapse>
          <NameAcordeon>
            Bajas: <span>{alertas?.alertasBajas?.length}</span>
          </NameAcordeon>
        </Cabecera>
        {toogleBajas && (
          <div style={{ backgroundColor: "" }}>
            {alertas?.alertasBajas?.map((item, index) => {
              Object.assign(item, { tipo: "Bajas" });
              return <CardAlert key={index} highAlert={false} data={item} />;
            })}
          </div>
        )}
      </Acordeon>
    </Container>
  );
};
