import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import Indicadores from "../Components/Indicadores/Indicadores";
import Header from "../Components/Header/Header";
import HeaderPad from "../Components/HeaderPad/HeaderPad";
import { ModificarTrayecto } from "../Components/Camiones/ModificarTrayecto/ModificarTrayecto";
import { Sidebar } from "../Components/Sidebar/Sidebar";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  @media (max-width: 768px) {
    overflow: auto;
    display: block;
  }
`;
const Home = styled(Grid)`
  position: relative;
`;

export const EditarTrayecto = () => {
  const { Info_de_indicadores } = useSelector((state) => state.indicadores);
  const { Role } = useSelector((state) => state.dataUser.user);

  const [showGraph, setShowGraph] = useState(false);
  return (
    <Home container>
      <Grid item xs={12} md={3} sm={3}>
        <Sidebar />
        <HeaderPad />
      </Grid>
      <Grid item xs={12} md={9} sm={9}>
        <Header />
      </Grid>
      <Container>
        <Grid item xs={12} md={3}>
          <Indicadores
            showGraph={showGraph}
            setShowGraph={setShowGraph}
            Role={Role}
            Info_de_indicadores={Info_de_indicadores}
          />
        </Grid>
        <Grid item xs={12} md={9} sm={12}>
          <ModificarTrayecto />
        </Grid>
      </Container>
    </Home>
  );
};
