import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Container,
  Head,
  Buton,
  StyledForm,
  Div,
  Title,
  FormGroup,
  GridItem,
  ButtonContainer,
} from "./Styles";
import {
  Grid,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

/* Iconos */
import { ReactComponent as HomeIcon } from "../../../../assets/img/homeIcon.svg";

/* componentes */
import { Inputs } from "../../../Input/Inputs";
import ButtonPrimary from "../../../ButtonPrimary/ButtonPrimary";
// import { ModalPlantaApprove } from "../ModalPlantaApprove/ModalPlantaApprove";

/* dependencias */
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { editarPlantaCls } from "../../../../Services";
import { useEffect } from "react";
import { sort } from "../../../../utils";
import { addHours } from "../../../../utils/DateTimeConverter/DateTimeConverter";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  nombre: yup.string().required("Este campo es requerido"),
  latitud: yup
    .number()
    .required("Este campo es requerido")
    .negative("El valor debe ser negativo"),
  longitud: yup
    .number()
    .required("Este campo es requerido")
    .negative("El valor debe ser negativo"),
  geocerca: yup.number().required("Este campo es requerido"),
  tn_contratadas: yup.number(),
});

export const EditarPlantaForm = () => {
  const plantas = useSelector((state) => state.infoPlantasCls.plantas);
  const hoyArgentina = addHours(-3, new Date()).toISOString();
  const { Name, Role, Email } = useSelector((state) => state.dataUser.user);
  let history = useHistory();

  return (
    <Container>
      <Grid item md={12} sm={12}>
        <Head>
          <Buton
            variant="outlined"
            color="primary"
            textArea="Home"
            onClick={() => history.push("/aluvional")}
          >
            <HomeIcon />
          </Buton>
        </Head>
      </Grid>

      <GetFormik
        plantas={plantas}
        Name={Name}
        Role={Role}
        Email={Email}
        hoyArgentina={hoyArgentina}
      />
    </Container>
  );
};

function GetFormik({ plantas, Name, Role, Email, hoyArgentina }) {
  const [selectPlanta, setSelectPlanta] = useState(null);
  let history = useHistory();
  const dispatch = useDispatch();

  function cancelar() {
    history.push("/aluvional");
  }
  const initialValues = {
    nombre: "",
    longitud: "",
    latitud: "",
    geocerca: "",
    tn_contratadas: 0,
  };

  return (
    <Formik
      initialValues={selectPlanta || initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) =>
        dispatch(
          editarPlantaCls({
            nombre: values.nombre,
            longitud: values.longitud,
            latitud: values.latitud,
            geocerca: values.geocerca,
            tn_contratadas: values.tn_contratadas,
            user_name: Name,
            user_email: Email,
            user_rol: Role,
            datetime: hoyArgentina,
          })
        )
      }
      enableReinitialize
    >
      {(props) => (
        <VeiwFormik
          props={props}
          setSelectPlanta={setSelectPlanta}
          plantas={plantas}
          cancelar={cancelar}
        />
      )}
    </Formik>
  );
}

function VeiwFormik({ props, setSelectPlanta, plantas, cancelar }) {
  const [validated, setValidated] = useState(true);

  useEffect(() => {
    if (
      props.values.nombre ||
      props.values.longitud ||
      props.values.latitud ||
      props.values.geocerca ||
      props.values.tn_contratadas
    ) {
      setValidated(false);
    } else setValidated(true);
  }, [setValidated, props]);

  return (
    <Div onSubmit={props.handleSubmit}>
      {/* <ModalPlantaApprove /> */}
      <Grid item xs={12} md={12} sm={12}>
        <Title>Editar planta</Title>
      </Grid>
      <StyledForm>
        <Grid container>
          <Grid item xs={12} md={6} sm={6}>
            <FormGroup width>
              <InputLabel id="demo-simple-select-label">
                Seleccionar planta*
              </InputLabel>
              <Select
                required
                labelId="planta"
                id="nombre"
                name="nombre"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.nombre}
                error={props.errors.nombre}
                defaultValue=""
              >
                <MenuItem value="">Selecciona una planta</MenuItem>
                {plantas?.sort(sort("nombre")).map((planta, index) => (
                  <MenuItem
                    key={index}
                    onClick={() =>
                      setSelectPlanta({
                        longitud: planta.longitud,
                        latitud: planta.latitud,
                        geocerca: planta.geocerca,
                        tn_contratadas: planta.tn_contratadas,
                        ...planta,
                      })
                    }
                    value={`${planta.nombre}`}
                  >
                    {planta.nombre}
                  </MenuItem>
                ))}
              </Select>
              {props.errors?.nombre && props.touched?.nombre && (
                <FormHelperText
                  error={props.errors?.nombre && props.touched?.nombre}
                >
                  {props.errors?.nombre}
                </FormHelperText>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                required
                autoComplete="off"
                name="geocerca"
                id="geocerca"
                label="Geocerca"
                type="number"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.geocerca}
                error={props.errors.geocerca}
              />
              {props.errors?.geocerca && props.touched?.geocerca && (
                <FormHelperText
                  error={props.errors?.geocerca && props.touched?.geocerca}
                >
                  {props.errors?.geocerca}
                </FormHelperText>
              )}
            </FormGroup>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                required
                autoComplete="off"
                name="longitud"
                id="longitud"
                label="Longitud"
                type="number"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.longitud}
                error={props.errors.longitud}
              />
              {props.errors?.longitud && props.touched?.longitud && (
                <FormHelperText
                  error={props.errors?.longitud && props.touched?.longitud}
                >
                  {props.errors?.longitud}
                </FormHelperText>
              )}
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                required
                autoComplete="off"
                name="latitud"
                id="latitud"
                label="Latitud"
                type="number"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.latitud}
                error={props.errors.latitud}
              />
              {props.errors?.latitud && props.touched?.latitud && (
                <FormHelperText
                  error={props.errors?.latitud && props.touched?.latitud}
                >
                  {props.errors?.latitud}
                </FormHelperText>
              )}
            </FormGroup>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                required
                autoComplete="off"
                name="tn_contratadas"
                id="tn_contratadas"
                label="Tn contratadas"
                type="number"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.tn_contratadas}
                error={props.errors.tn_contratadas}
              />
              {props.errors?.tn_contratadas &&
                props.touched?.tn_contratadas && (
                  <FormHelperText
                    error={
                      props.errors?.tn_contratadas &&
                      props.touched?.tn_contratadas
                    }
                  >
                    {props.errors?.tn_contratadas}
                  </FormHelperText>
                )}
            </FormGroup>
          </Grid>
        </Grid>

        <ButtonContainer container align="center">
          <ButtonPrimary
            type="button"
            textArea="Cancelar" /* Acà se puede validar el tipo de boton */
            onClick={cancelar}
            variant="outlined"
            color="primary"
            minwidth="true"
          />
          <ButtonPrimary
            type="submit"
            textArea="Guardar" /* Acà se puede validar el tipo de boton */
            variant="contained"
            color="primary"
            minwidth="true"
            disabled={validated}
            // onClick={(event) => handleClick(event)}
          />
        </ButtonContainer>
      </StyledForm>
    </Div>
  );
}
