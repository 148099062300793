import { GET_PLANTAS_INACTIVOS, RESET_PLANTAS_INACTIVOS } from '../constants/actionsTypes'

const initialState = []

export const plantasInactivosReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_PLANTAS_INACTIVOS: {
      return { ...state, ...payload }
    }
    case RESET_PLANTAS_INACTIVOS:
      return { plantasInactivos: initialState }
    default:
      return state
  }
}