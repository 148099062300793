import React  from "react";
import ReactDOM from 'react-dom';
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as CarIcon } from "../../../../assets/img/truck.svg";
import useAlert from "../../../../Hooks/useAlert";
import ButtonPrimary from "../../../ButtonPrimary/ButtonPrimary";
import { resetResponse } from '../../../../actions/responseActions';

import {
  Head,
  Div,
  Divider,
  Background,
  ModalWrapper,
  ApproveMessage,
  ContainerButtons,
  IconContainer,
} from "./Styles";



export const ConfirmacioRecomendar = () => {
  const { response } = useAlert(5000);
  const dispatch = useDispatch();
  
  return ReactDOM.createPortal (
    response.show ? (
      <Background>
        <ModalWrapper>                              
          <Head>
            <p>Recomendación sugerida</p>
            <IconContainer>
              <FontAwesomeIcon icon={faTimes} onClick={() => dispatch(resetResponse())}/>
            </IconContainer>
          </Head>

          <ApproveMessage>
            {response.status === 200 && <FontAwesomeIcon icon={faCheckCircle} />}
            <span>
              La recomendación ha sido guardada correctamente
            </span>
          </ApproveMessage>

          <Divider />
          <Div container>
            <Grid item style={{ marginRight: "0.688rem" }}>
              <CarIcon />
            </Grid>
            <Grid item>
              <p>Placa</p>
              <span>{response?.data?.placa}</span>
            </Grid>
          </Div>
          <Divider />

          <ContainerButtons>
            <ButtonPrimary
              type="primary"
              textArea="Aceptar"
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(resetResponse())
                window.location.reload()
              }}
            />
          </ContainerButtons>
        </ModalWrapper>
      </Background>
    ) : (
      <div/>
    ), 
    document.getElementById('root')
  );
};
