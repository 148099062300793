import styled from "styled-components";

export const Background = styled.div``;

export const Container = styled.div`
  display: flow-root;
  background-color: #cbc3e3;
  position: relative;
  margin: 0;
  padding: 0;
  height: calc(100vh - 60px);
  overflow: hidden;
  @media (max-width: 768px) {
    display: block;
    position: relative;
  }
  &.open768 {
    @media (max-width: 768px) {
      display: block;
      position: absolute;
      z-index: 100;
      left: 434px;
    }
  }
`;
export const Menu = styled.div`
  margin: 0;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0;
  position: relative;
`;
export const Cabecera = styled.div`
  display: flex;
  width: 100%;
  border-bottom: solid 2px #4f4184;
  padding: 15px;
  position: relative;
`;
export const NameSelect = styled.span`
  font-family: "Roboto-Medium", sans-serif;
  font-size: 1.2rem;
  color: #4f4184;
  padding: 4px 0;
  line-height: 0;
`;
export const ToggleMenu = styled.div`
  color: #4f4184;
  font-size: 30px;
  position: absolute;
  top: -5px;
  right: 20px;
  cursor: pointer;
`;
export const Options = styled.ul`
  display: block;
  position: absolute;
  top: 40px;
  background: #a9a6e9;
  width: 100%;
  height: calc(100% - 40px);
  list-style: none;
  padding: 0;
  margin: 0;
  left: 100%;
  z-index: 9;
  transition: left 0.2s;
  &.open {
    left: 0;
    transition: left 0.2s;
  }
`;
export const Item = styled.li`
  font-family: "Roboto-Medium", sans-serif;
  font-size: 0.8rem;
  color: #414042;
  padding: 10px 15px;
  margin: 0;
`;
export const LinkItem = styled.li`
  font-family: "Roboto-Medium", sans-serif;
  font-size: 0.8rem;
  color: #414042;
  padding: 10px 15px;
  margin: 0;
  cursor: pointer;
  transition: background 0.5s, color 0.5s;
  &:hover {
    background: #4f4184;
    color: #ffffff;
    transition: background 0.5s, color 0.5s;
  }
`;

export const SubItem = styled.li`
  font-family: "Roboto-Regular", sans-serif;
  font-size: 0.8rem;
  color: #414042;
  padding: 10px 30px;
  margin: 0;
  cursor: pointer;
  transition: background 0.5s, color 0.5s;
  &:hover {
    background: #4f4184;
    color: #ffffff;
  }
  & a {
    display: flex;
    height: 100%;
    text-decoration: none;
    color: #414042;
    padding: 10px 0px;
    transition: background 0.5s, color 0.5s;
    box-sizing: border-box;
    &:hover {
      background: red;
      color: #ffffff;
    }
  }
`;

export const SubItems = styled.li`
  font-family: "Roboto-Regular", sans-serif;
  font-size: 0.8rem;
  color: #414042;
  padding-left: 30px;
  margin: 0;
  transition: background 0.5s, color 0.5s;
  &:hover {
    background: #4f4184;
    color: #ffffff;
  }
  & a {
    display: flex;
    height: 100%;
    text-decoration: none;
    color: #414042;
    padding: 10px 0px;
    transition: background 0.5s, color 0.5s;
    cursor: pointer;
    box-sizing: border-box;
    &:hover {
      background: transparent;
      color: #ffffff;
    }
  }
`;

export const Hr = styled.hr`
  border-bottom: solid 1px #4f4184;
  width: calc(100% - 30px);
  margin: 0 auto;
`;
export const ContentSelect = styled.div`
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
`;

export const Head = styled.div`
  background-color: #4f4184;
  padding: 10px 15px;
  color: #ffffff;
  font-family: "Roboto-Bold", sans-serif;
  font-weight: 500;
  font-size: 1rem;
`;

export const Title = styled.li`
  font-family: "Roboto-Bold", sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  color: #414042;
  padding: 10px 15px;
  margin: 0;
`;
