import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faChevronRight,
  faChevronDown,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { ConvertDateTime } from "../../../utils/DateTimeConverter/DateTimeConverter";
import {
  Container,
  Acordeon,
  Cabecera,
  Collapse,
  NameAcordeon,
  ShowMap,
  Contenido,
  Camiones,
  Camion,
  Name,
  Recomendacion,
  EnPad,
  Detalle,
  Placa,
  Destino,
  LlegadaPad,
  Arena,
  Estado,
} from "./Styles";

/*Componentes*/
import ModalReomendacion from "../Recomendacion/ModalRecomendacion/ModalReomendacion";
import {
  showCarCargaPlanta,
  showCarPad,
  showCarTransitoLleno,
  showCarTransitoVacio,
  showCarSinGeotab,
  showMapCargaPlanta,
  showMapPad,
  showMapTransitoLleno,
  showMapTransitoVacio,
  showMapSinGeotab,
} from "../../../actions/estadoCamionesActions";

import { formatHoras, sort } from "../../../utils";

export const PanelCamiones = ({
  togglePad,
  toggleTransitoVacio,
  toggleCarga,
  toggleTransitoLleno,
  toggleSinGeotab,
  CamionPad,
  transitoVacio,
  cargaPlanta,
  transitoLleno,
  sinGeotab,
  showModalRecomended,
  setShowModalRecomended,
  openModalToggleRecomended,
  stepsRecomended,
  Role,
}) => {
  const [isOpenPad, setIsOpenPad] = useState(true);
  const { camiones } = useSelector((state) => state.estadoCamiones);

  const [placa, setPlaca] = useState("");
  const dispatch = useDispatch();

  const numCamionesPad = camiones?.camiones_pad?.camiones.length;
  const numTransitoVacio = camiones?.camiones_transito_vacio?.camiones.length;
  const numCargaPlanta = camiones?.camiones_carga_planta?.camiones.length;
  const numTransitoLleno = camiones?.camiones_transito_lleno?.camiones.length;
  const numSinGeotab = camiones?.camiones_sin_geotab?.camiones.length;

  const handleTogglePad = () => {
    setIsOpenPad(!isOpenPad);
    dispatch(showMapPad());
    dispatch(showCarPad());
  };

  const handleClickButtonRecomended = (camionPad) => {
    setPlaca(camionPad.placa);
    setShowModalRecomended(true);
  };

  return (
    <Container container md={3}>
      {/* En pad */}
      <Acordeon>
        <Cabecera>
          <Collapse onClick={() => togglePad()}>
            {CamionPad ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </Collapse>
          <NameAcordeon>
            En pad: <span>{numCamionesPad}</span>
          </NameAcordeon>
          <ShowMap onClick={() => handleTogglePad()}>
            {isOpenPad ? (
              <FontAwesomeIcon icon={faEye} />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} />
            )}
          </ShowMap>
        </Cabecera>
        {/* Camiones en pad */}
        {CamionPad && (
          <Contenido>
            {Role === "Brent" && (
              <Camiones>
                {camiones &&
                  camiones.camiones_pad?.camiones.map((camionPad, index) => {
                    return (
                      <Camion key={index}>
                        <Name>{camionPad.placa}</Name>
                        <Recomendacion
                          onClick={() => handleClickButtonRecomended(camionPad)}
                        >
                          Recomendar
                        </Recomendacion>
                      </Camion>
                    );
                  })}
              </Camiones>
            )}
            {(Role === "SupplyChain" || Role === "CoordBrent") && (
              <Camiones>
                {camiones &&
                  camiones?.camiones_pad?.camiones
                    ?.sort(sort("placa"))
                    ?.map((camionPad, index) => {
                      return (
                        <Camion className="SC" key={index}>
                          <Name>{camionPad.placa}</Name>
                          <EnPad>Esperando recomendación</EnPad>
                        </Camion>
                      );
                    })}
              </Camiones>
            )}
          </Contenido>
        )}
      </Acordeon>

      {/* En transito Vacio */}
      <Acordeon>
        <Cabecera>
          <Collapse onClick={() => toggleTransitoVacio()}>
            {transitoVacio ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </Collapse>
          <NameAcordeon>
            En tránsito vacío: <span>{numTransitoVacio}</span>
          </NameAcordeon>
          <ShowMap onClick={() => dispatch(showMapTransitoVacio())}>
            {camiones?.camiones_transito_vacio?.showMap ? (
              <FontAwesomeIcon icon={faEye} />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} />
            )}
          </ShowMap>
        </Cabecera>
        {transitoVacio && (
          <Contenido>
            <Camiones>
              {camiones &&
                camiones?.camiones_transito_vacio?.camiones
                  ?.sort(sort("placa"))
                  ?.map((transitoVacio, index) => {
                    return (
                      <Camion align="true" key={index}>
                        <Detalle>
                          <Placa>{transitoVacio.placa}</Placa>
                          <Destino>
                            <b>Destino</b>
                            <span>{transitoVacio.destino}</span>
                          </Destino>

                          <LlegadaPad>
                            <b>Llegada al pad</b>
                            <span>
                              {transitoVacio.llegada_pad?.toFixed(0) > 60
                                ? `${formatHoras(
                                    transitoVacio.llegada_pad?.toFixed(0)
                                  )} Horas`
                                : `${formatHoras(
                                    transitoVacio.llegada_pad?.toFixed(0)
                                  )} Minutos`}
                            </span>
                          </LlegadaPad>

                          <Arena>
                            <b>Tipo de arena</b>
                            <span>
                              {transitoVacio.tipo_arena
                                ? transitoVacio.tipo_arena.toUpperCase()
                                : "No Especificado"}
                            </span>
                          </Arena>

                          <Arena>
                            <b>Última actualización</b>
                            <span>
                              {ConvertDateTime(
                                transitoVacio.fechaGps,
                                transitoVacio.horaGps
                              ).join(" - ")}
                            </span>
                          </Arena>
                        </Detalle>
                        <ShowMap
                          onClick={() =>
                            dispatch(showCarTransitoVacio(transitoVacio.placa))
                          }
                        >
                          {transitoVacio.showCar ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </ShowMap>
                      </Camion>
                    );
                  })}
            </Camiones>
          </Contenido>
        )}
      </Acordeon>

      {/* Carga en Planta */}
      <Acordeon>
        <Cabecera>
          <Collapse onClick={() => toggleCarga()}>
            {cargaPlanta ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </Collapse>
          <NameAcordeon>
            Carga en planta: <span>{numCargaPlanta}</span>
          </NameAcordeon>
          <ShowMap onClick={() => dispatch(showMapCargaPlanta())}>
            {camiones?.camiones_carga_planta?.showMap ? (
              <FontAwesomeIcon icon={faEye} />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} />
            )}
          </ShowMap>
        </Cabecera>
        {cargaPlanta && (
          <Contenido>
            <Camiones>
              {camiones &&
                camiones?.camiones_carga_planta?.camiones
                  ?.sort(sort("placa"))
                  ?.map((cargaPlanta, index) => {
                    return (
                      <Camion align="true" key={index}>
                        <Detalle>
                          <Placa>{cargaPlanta.placa}</Placa>
                          <Destino>
                            <b>Destino</b>
                            <span>{cargaPlanta.destino}</span>
                          </Destino>

                          <LlegadaPad>
                            <b>Llegada al pad</b>
                            <span>
                              {cargaPlanta.llegada_pad?.toFixed(0) > 60
                                ? `${formatHoras(
                                    cargaPlanta.llegada_pad?.toFixed(0)
                                  )} Horas`
                                : `${formatHoras(
                                    cargaPlanta.llegada_pad?.toFixed(0)
                                  )} Minutos`}
                            </span>
                          </LlegadaPad>

                          <Arena>
                            <b>Tipo de arena</b>
                            <span>
                              {cargaPlanta.tipo_arena
                                ? cargaPlanta.tipo_arena.toUpperCase()
                                : "No Especificado"}
                            </span>
                          </Arena>

                          <Arena>
                            <b>Última actualización</b>
                            <span>
                              {ConvertDateTime(
                                cargaPlanta.fechaGps,
                                cargaPlanta.horaGps
                              ).join(" - ")}
                            </span>
                          </Arena>
                        </Detalle>
                        <ShowMap
                          onClick={() =>
                            dispatch(showCarCargaPlanta(cargaPlanta.placa))
                          }
                        >
                          {cargaPlanta.showCar ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </ShowMap>
                      </Camion>
                    );
                  })}
            </Camiones>
          </Contenido>
        )}
      </Acordeon>

      {/* En transito llenos */}
      <Acordeon>
        <Cabecera>
          <Collapse onClick={() => toggleTransitoLleno()}>
            {transitoLleno ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </Collapse>
          <NameAcordeon>
            En tránsito llenos: <span> {numTransitoLleno} </span>
          </NameAcordeon>
          <ShowMap onClick={() => dispatch(showMapTransitoLleno())}>
            {camiones?.camiones_transito_lleno?.showMap ? (
              <FontAwesomeIcon icon={faEye} />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} />
            )}
          </ShowMap>
        </Cabecera>
        {transitoLleno && (
          <Contenido>
            <Camiones>
              {camiones &&
                camiones?.camiones_transito_lleno?.camiones
                  ?.sort(sort("placa"))
                  ?.map((transitoLleno, index) => {
                    return (
                      <Camion align="true" key={index}>
                        <Detalle>
                          <Placa>{transitoLleno.placa}</Placa>
                          <Destino>
                            <b>Destino</b>
                            <span>{transitoLleno.destino}</span>
                          </Destino>

                          <LlegadaPad>
                            <b>Llegada al pad</b>
                            <span>
                              {transitoLleno.llegada_pad?.toFixed(0) > 60
                                ? `${formatHoras(
                                    transitoLleno.llegada_pad?.toFixed(0)
                                  )} Horas`
                                : `${formatHoras(
                                    transitoLleno.llegada_pad?.toFixed(0)
                                  )} Minutos`}
                            </span>
                          </LlegadaPad>

                          <Arena>
                            <b>Tipo de arena</b>
                            <span>
                              {transitoLleno.tipo_arena
                                ? transitoLleno.tipo_arena.toUpperCase()
                                : "No Especificado"}
                            </span>
                          </Arena>

                          <Arena>
                            <b>Última actualización</b>
                            <span>
                              {ConvertDateTime(
                                transitoLleno.fechaGps,
                                transitoLleno.horaGps
                              ).join(" - ")}
                            </span>
                          </Arena>
                        </Detalle>
                        <ShowMap
                          onClick={() =>
                            dispatch(showCarTransitoLleno(transitoLleno.placa))
                          }
                        >
                          {transitoLleno.showCar ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </ShowMap>
                      </Camion>
                    );
                  })}
            </Camiones>
          </Contenido>
        )}
      </Acordeon>

      {/* Sin Geotab */}
      <Acordeon marginbottom="true">
        <Cabecera>
          <Collapse onClick={() => toggleSinGeotab()}>
            {sinGeotab ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </Collapse>
          <NameAcordeon>
            Sin señal: <span> {numSinGeotab} </span>
          </NameAcordeon>
          <ShowMap onClick={() => dispatch(showMapSinGeotab())}>
            {camiones?.camiones_sin_geotab?.showMap ? (
              <FontAwesomeIcon icon={faEye} />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} />
            )}
          </ShowMap>
        </Cabecera>
        {sinGeotab && (
          <Contenido>
            <Camiones>
              {camiones &&
                camiones?.camiones_sin_geotab?.camiones
                  ?.sort(sort("placa"))
                  ?.map((sinGeotab, index) => {
                    return (
                      <Camion align="true" key={index}>
                        <Detalle>
                          <Placa>{sinGeotab.placa}</Placa>
                          <Estado>
                            <b>Estado</b>
                            <span>{sinGeotab.estado}</span>
                          </Estado>
                        </Detalle>
                        <ShowMap
                          onClick={() =>
                            dispatch(showCarSinGeotab(sinGeotab.placa))
                          }
                        >
                          {sinGeotab.showCar ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </ShowMap>
                      </Camion>
                    );
                  })}
            </Camiones>
          </Contenido>
        )}
      </Acordeon>

      {showModalRecomended && (
        <ModalReomendacion onClose={openModalToggleRecomended}>
          {stepsRecomended({ placa })}
        </ModalReomendacion>
      )}
    </Container>
  );
};
