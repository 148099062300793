import styled from 'styled-components'
import { Grid } from '@material-ui/core';

export const Background = styled.div`
  display: flex;
  justify-content:center;
  align-items:center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 900;
  top: 0;
  left:0;
  background: rgba(0, 0, 0, 0.4);
`;

export const ModalWrapper = styled.div`
  background: #ffffff;
  border: 0.0625rem solid #e5e5e5;
  box-shadow: 0rem 1.25rem 2.5rem -0.75rem rgba(196, 196, 196, 0.35);
  box-sizing: border-box;
  padding: 1.25rem 1.875rem;
  width: 31.25rem;
  z-index: 10;
`

export const Div = styled(Grid)`
  align-items: center;
  padding: 0.875rem 1.875rem;
  & p {
    margin: 0;
  }
`
export const ApproveMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & svg {
    color: #34A853;
    font-size: 3.125rem;
    margin-bottom: 0.5rem;
  }
  & span {
    text-align: center;
  }
`
export const ContainerModal = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  position: absolute;
  width: 30rem;
  padding: 1.25rem 1.125rem 0 1.125rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.75rem;
  & p {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
  }
`

export const Divider = styled.hr`
  padding: 0 1.875rem;
  margin: 0;
`

export const ButtonContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  margin: 1.875rem auto 1.875rem auto;
`