import styled from "styled-components";
import { ReactComponent as LogoMenu } from "../../assets/img/LogoMenu.svg";
import { ReactComponent as TimesLogo } from "../../assets/img/timesLogoWhite.svg";
import { ListItem } from "@material-ui/core";

export const Title = styled.p`
  padding: 5px;
  font-weight: 500;
  font-size: 1.1rem;
  margin: 10px 0 0 10px;
`;
export const ListItemAnchor = styled(ListItem)`
  cursor: pointer;
  width: 100%;
  &:hover {
    background: #201751;
  }
  & a {
    color: #ffffff;
    font-size: 1rem;
    padding-left: 2rem;
  }
  & a:hover {
    text-decoration: none;
  }
  @media (max-width: 768px) {
    & a {
      color: #ffffff;
      font-size: 0.9rem;
      padding-left: 0.5rem;
    }
    & a:hover {
      text-decoration: none;
    }
  }
`;

export const Head = styled.header`
  width: 100%;
  height: 3.75rem;
  padding: 1rem 1rem;
  margin-left: 75px;
  background: #201751;
  color: #ffffff;
  & span {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: bold;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
    display: fixed;
  }
`;

export const Drawer = styled.div`
  position: absolute;
  z-index: 1;
  top: 60px;
  left: 0px;
`;

export const DrawerContainer = styled.div`
  position: absolute;
  color: white;
  background: #414042;
  margin-left: 75px;
  height: calc(100vh - 60px);
  z-index: 100;
  min-width: 405px;
  width: calc (100% - 75px) @media (min-width: 768px) {
    width: 25%;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
    width: 100%;
  }
`;

export const MenuLogo = styled(LogoMenu)`
  cursor: pointer;
  margin-right: 1rem;
`;
export const TimesLogoWhite = styled(TimesLogo)`
  cursor: pointer;
  margin-right: 1rem;
`;
