import {GET_DESHABILITAR_CAMION, RESET_DESHABILITAR_CAMION } from '../constants/actionsTypes'

const initialState = []

export const deshabilitarCamionReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_DESHABILITAR_CAMION: {
      return { ...state, camiones: payload }
    }
    case RESET_DESHABILITAR_CAMION:
      return {camiones: initialState}
    default:
      return state
  }
}