import React, { useState } from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import {
  Head,
  DrawerContainer,
  MenuLogo,
  TimesLogoWhite,
  ListItemAnchor,
  Title,
} from "./Styles";
import { List } from "@material-ui/core";
import { Link } from "react-router-dom";

const HeaderPad = ({
  setShowModalEtapasDias,
  setShowModalCajasPad,
  setShowModalModificarCajasPad,
  setShowModalAltaPad,
  setShowModalEditarPad,
  setShowModalFinalizarPad,
  setShowModalReentrenamiento,
  toEscenariosProyeccion,
}) => {
  const [show, setShow] = useState(false);
  const Role = useSelector((state) => state?.dataUser?.user.Role);
  const { Info_de_indicadores } = useSelector((state) => state?.indicadores);
  const estado = useSelector((state) => state?.infoPad?.pad?.estado);

  const closeMenuCajasPad = () => {
    setShowModalCajasPad(true);
    setShow(false);
  };

  const closeMenuEtapasDia = () => {
    setShowModalEtapasDias(true);
    setShow(false);
  };

  const closeMenuModificarCajas = () => {
    setShowModalModificarCajasPad(true);
    setShow(false);
  };

  const closeMenuAltaPad = () => {
    setShowModalAltaPad(true);
    setShow(false);
  };

  const closeMenuEditarPad = () => {
    setShowModalEditarPad(true);
    setShow(false);
  };

  const closeMenuFinalizarPad = () => {
    setShowModalFinalizarPad(true);
    setShow(false);
  };
  const closeReentrenamiento = () => {
    setShowModalReentrenamiento(true);
    setShow(false);
  };
  return (
    <Grid>
      <Head>
        {show ? (
          <TimesLogoWhite onClick={() => setShow(!show)} />
        ) : (
          <MenuLogo onClick={() => setShow(!show)} />
        )}
        {estado && estado === "Activo"
          ? `Pad-${Info_de_indicadores?.nombre_pad}`
          : "Pad Inactivo"}
      </Head>
      {show && (
        <Grid container>
          <Grid item sx={12} md={12} style={{ backgroundColor: "red" }}>
            <DrawerContainer>
              <Title>Administracion de pad</Title>
              <List>
                {estado && estado !== "Activo" ? (
                  <>
                    <ListItemAnchor>
                      <Link to="#" onClick={() => closeMenuAltaPad()}>
                        Alta de Pad
                      </Link>
                    </ListItemAnchor>

                    <ListItemAnchor>
                      <Link to="#" onClick={() => closeReentrenamiento()}>
                        Reentrenamiento del modelo
                      </Link>
                    </ListItemAnchor>
                  </>
                ) : (
                  <>
                    {(Role === "SupplyChain" || Role === "CoordBrent") && (
                      <>
                        <ListItemAnchor>
                          <Link to="#" onClick={() => closeMenuCajasPad()}>
                            Ingreso de cajas de camiones inhabilitados
                          </Link>
                        </ListItemAnchor>
                      </>
                    )}
                    {Role === "SupplyChain" && (
                      <>
                        <ListItemAnchor>
                          <Link to="#" onClick={() => closeMenuEtapasDia()}>
                            Editar etapas por día
                          </Link>
                        </ListItemAnchor>
                      </>
                    )}
                    <ListItemAnchor>
                      <Link to="#" onClick={() => closeMenuModificarCajas()}>
                        Modificar cajas en pad
                      </Link>
                    </ListItemAnchor>
                    {Role === "SupplyChain" && (
                      <>
                        <ListItemAnchor>
                          <Link to="#" onClick={() => closeMenuEditarPad()}>
                            Editar Pad
                          </Link>
                        </ListItemAnchor>

                        <ListItemAnchor>
                          <Link to="#" onClick={() => closeMenuFinalizarPad()}>
                            Finalizar Pad
                          </Link>
                        </ListItemAnchor>
                      </>
                    )}
                    <ListItemAnchor>
                      <Link to="#" onClick={() => toEscenariosProyeccion()}>
                        Escenarios de proyección de cajas
                      </Link>
                    </ListItemAnchor>
                  </>
                )}
              </List>
            </DrawerContainer>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default HeaderPad;
