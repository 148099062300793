import React from 'react'
import { Input } from './Styles'
import './style.css'

export const Inputs = ({
  autoComplete,
  label,
  name,
  onChange,
  onBlur,
  value,
  type,
  error,
  InputProps,
  variant,
  select,
  SelectProps,
  id,
  maxLength
}) => {
  return (
    <Input
      // className="InputBase"
      autoComplete={autoComplete}
      label={label}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      type={type}
      error={error}
      variant={variant}
      InputProps={InputProps}
      select={select}
      SelectProps={SelectProps}
      id={id}
      inputProps={{ 
        maxLength: maxLength,
      }}
    />
  )
}
