import { GET_ESTADORT, RESET_ESTADORT } from "../constants/actionsTypes";

const initialState = {};

export const estadortReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ESTADORT: {
      // console.log(payload);
      return { ...state, status: payload };
    }
    case RESET_ESTADORT: {
      return { estadoRT: [], status: "" };
    }
    default:
      return state;
  }
};
