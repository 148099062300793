import {
  GET_ALERTAS,
  RESET_ALERTAS,
  CURRENT_DESCARGAR,
  SET_CURRENT_TAGS
} from '../constants/actionsTypes'

const initialState = {
  alertas: {
    alertasBajas: [],
    alertasMedias: [],
    alertasAltas: []
  },

  currentTags: 'todos',
  currentDescarga: ''
}

export const alertasReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_ALERTAS: {
      return { ...state, alertas: payload }
    }

    case CURRENT_DESCARGAR: {
      return { ...state, currentDescarga:  payload }
    }

    case SET_CURRENT_TAGS: {
      return { ...state, currentTags:  payload }
    }

    case RESET_ALERTAS: {
      return { ...initialState }
    }

    default:
      return state
  }
}