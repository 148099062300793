import styled from 'styled-components';
import { FormControl } from '@material-ui/core';
import { Form } from "react-bootstrap";
import bglogin from '../../assets/img/bg-login.png'


export const A = styled.p`
  display:table;
  margin: 0 auto 0;
  font-size: 14px;
  cursor: pointer;
  color: var(--purplePrimary);
  margin-top: 10px;
  text-decoration: underline var(--purplePrimary);
  font-family: "Roboto",sans-serif;
  font-weight:500;
  &:hover{
    color: var(--purplePrimary);
    font-weight:600;
  }
`

export const ContainerLogin = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image:url(${bglogin});
  background-size:cover;
  background-position:center;
`

export const LogoVista = styled.img`
  margin:  0 auto 10px auto;
  width: 3.625rem;
`

export const LogoInput = styled.img`
  width: ${props => props.large ? '1.5rem' : '1rem'};
`

export const IconContainer = styled.div`
  & svg {
    color: ${props => props.color ? '#575757' : 'var(--purplePrimary)'};
    font-size: ${props => props.fontSize ? '1.2rem' : '1.5rem'};
  }
`

export const Title = styled.h1`
  margin: 10px auto 20px auto;
  font-family: "Roboto";
  font-size: 20px;
`

export const StyledLogin = styled.div`
  background: rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  width: calc(100% - 60px);
  max-width:460px;
  display: flex;
  padding: 30px;
  flex-direction: column;
  height: auto;
  justify-content: center;
  align-items: flex-start;
`

export const StyledForm = styled(Form)`
  width: 100%;
  > div.MuiFormControl-root{
    margin: 15px 0;
    &:first-child{
      margin-top:0;
    }
  }
  .MuiInputBase-root{
    font-size:14px;
    div.icon-input{
      font-size: 14px;
      margin: 7px 6px 0 0px;
      line-height: 40px;
      &.eye-button{
        width:30px;
        margin: 0;
      }
      button{
        &:hover{
          background-color: rgba(0, 0, 0, 0);
        }
        svg{
          font-size:16px;
        }
      }
    }
    input:-webkit-autofill-selected {
      -webkit-background-color:none !important;
    }
  }
  .MuiInputLabel-filled{
    transform: translate(36px, 20px) scale(1);
    &.MuiInputLabel-shrink{
      transform: translate(36px, 5px) scale(0.75);
    }
  }
  .MuiFilledInput-input{
    padding: 20px 5px 5px;
  }
  .MuiFormHelperText-root{
    background: rgba(223,0,0,.2);
    margin: 0;
    padding: 0 10px;
    color:#A03434;
  }
`;

export const FormGroup = styled(FormControl)`
  width: 100%;
`;

export const ButtonLogin = styled.button`
    clear: both;
    display: table;
    padding: 10px 40px;
    margin: 15px auto 30px;
    font-family: "Roboto",sans-serif;
    background: var(--purplePrimary);
    border: none;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    background: var(--purplePrimary);
    &:hover {
      background: var(--purplePrimary);
      filter: opacity(90%);
    }
    &:active:hover:not([disabled]) {
      background: var(--purplePrimary);
    }
    &:disabled {
      background-color: #E0E0E0;
      color: #A6A6A6;
    }
`