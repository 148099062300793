import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Grid } from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  GridItem,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { addHours } from "../../utils/DateTimeConverter/DateTimeConverter";
import { Reentrenamiento } from "../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  comentario: yup.string(),
});

export const ModalReentrenamiento = ({ onClose }) => {
  const dispatch = useDispatch();
  const hoyArgentina = addHours(-3, new Date()).toISOString();
  const { Name, Role, Email } = useSelector((state) => state.dataUser.user);

  const formik = useFormik({
    initialValues: {
      comentario: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      dispatch(
        Reentrenamiento({
          reentrenar: true,
          user_name: Name,
          user_email: Email,
          user_rol: Role,
          datetime: hoyArgentina,
        })
      );

      onClose();
    },
  });

  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Reentrenamiento del modelo</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container>
            <GridItem>
              <p>
                El pad actual ha finalizado correctamente, ¿Desea reentrenar con
                los datos históricos?
              </p>
            </GridItem>

            <Grid container md={12} sm={8} justify="center">
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    type="button"
                    textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                    onClick={() => handleClose()}
                    variant="outlined"
                    color="primary"
                    minwidth="true"
                  />
                </ButtonContainer>
              </Grid>
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    // disabled={validated}
                    type="submit"
                    textArea="Aceptar"
                    variant="contained"
                    color="primary"
                    mright="true"
                    minwidth="true"
                  />
                </ButtonContainer>
              </Grid>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
