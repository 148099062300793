import styled from "styled-components";
import { TextField } from "@material-ui/core";
// import { Form } from "react-bootstrap";

export const Input = styled(TextField)`
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1rem;
  /* padding: 0.625rem 0; */
  font-family: roboto;
  &&::after {
    border-bottom: 2px solid red;
  }
  &::placeholder {
    color: #8f8f8f;
  }
  &:focus {
    border: 1px solid #18a0fb;
    box-shadow: none;
  }
`;
