import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { resetResponse } from '../actions/responseActions';
/*
  Este Hook ejecuta y cierra el modal de exito o de error de las respuestas de los servicios
 */
const useAlert = (time) => {
  const history = useHistory();
  // const dispatch = useDispatch();
  const response = useSelector((state) => state.response);
  let timeAlert;

  useEffect(() => {
    if (response.show) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeAlert = window.setTimeout(() => {
        if (response.redirect) {
          history.push(response.redirect);
        }
        // dispatch(resetResponse());
      }, time || 4000);
    }
    return () => window.clearTimeout(timeAlert);
  }, [response]);
  
  return {
    response,
    history
  };
};
export default useAlert;
