import React, { useState } from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import {
  Head,
  DrawerContainer,
  MenuLogo,
  TimesLogoWhite,
  ListItemAnchor,
  Title,
} from "./Styles";
import { List } from "@material-ui/core";
import { Link } from "react-router-dom";

const HeaderPad = ({ setShowModalPlanDespacho }) => {
  const [show, setShow] = useState(false);
  const Role = useSelector((state) => state?.dataUser?.user.Role);

  const closeMenuPlanDespacho = () => {
    setShowModalPlanDespacho(true);
    setShow(false);
  };

  return (
    <Grid>
      <Head>
        {show ? (
          <TimesLogoWhite onClick={() => setShow(!show)} />
        ) : (
          <MenuLogo onClick={() => setShow(!show)} />
        )}
      </Head>
      {show && (
        <Grid container>
          <Grid item sx={12} md={12} style={{ backgroundColor: "red" }}>
            <DrawerContainer>
              <Title>Administracion de Aluvional</Title>
              <List>
                {(Role === "SupplyChain" ||
                  Role === "SupervAluvional" ||
                  Role === "CoordAluvional") && (
                  <ListItemAnchor>
                    <Link to="#" onClick={() => closeMenuPlanDespacho()}>
                      Registrar plan de despacho
                    </Link>
                  </ListItemAnchor>
                )}
              </List>
            </DrawerContainer>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default HeaderPad;
