import styled from "styled-components";

export const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  display: flex;
  justify-content:center;
  align-items:center;
  width: 100%;
  z-index: 1000;
  top: 0;
  bottom: 0;
`;


export const ModalWrapper = styled.div`
  background: #ffffff;
  border: 0.0625rem solid #e5e5e5;
  box-shadow: 0rem 1.25rem 2.5rem -0.75rem rgba(196, 196, 196, 0.35);
  box-sizing: border-box;
  padding: 1.25rem 1.125rem 0 1.125rem;
  width: 30rem;
  z-index: 10;
  
`