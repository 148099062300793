import {
  GET_ESTADO_CAMIONES,
  RESET_ESTADO_CAMIONES,
  SHOW_MAP_TRANSITO_VACIO,
  SHOW_CAR_TRANSITO_VACIO,
  SHOW_MAP_PAD,
  SHOW_CAR_PAD,
  SHOW_CAR_CARGA_PLANTA,
  SHOW_MAP_CARGA_PLANTA,
  SHOW_CAR_TRANSITO_LLENO,
  SHOW_MAP_TRANSITO_LLENO,
  // SHOW_CAR_SIN_GEOTAB,
  // SHOW_MAP_SIN_GEOTAB,
} from "../constants/actionsTypes";

const initialState = {
  camiones: {
    camiones_pad: {
      showMap: true,
      camiones: [],
    },
    camiones_transito_vacio: {
      showMap: true,
      camiones: [],
    },
    camiones_carga_planta: {
      showMap: true,
      camiones: [],
    },
    camiones_transito_lleno: {
      showMap: true,
      camiones: [],
    },
    camiones_sin_geotab: {
      showMap: false,
      camiones: [],
    },
  },
};

export const estadoCamionesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ESTADO_CAMIONES: {
      return { ...state, ...payload };
    }
    case RESET_ESTADO_CAMIONES: {
      return { estadoCamiones: {} };
    }
    /* Muestra o no en el mapa todos los camiones en transito vacio */
    case SHOW_MAP_TRANSITO_VACIO: {
      let showMap_camiones_transito_vacio =
        state.camiones.camiones_transito_vacio.camiones.map((camion) => {
          camion.showCar = !state.camiones.camiones_transito_vacio.showMap;
          return camion;
        });
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_transito_vacio: {
            ...state.camiones.camiones_transito_vacio,
            showMap: !state.camiones.camiones_transito_vacio.showMap,
            camiones: showMap_camiones_transito_vacio,
          },
        },
      };
    }
    /* Muestra o no en el mapa todos los camiones carga en planta */
    case SHOW_MAP_CARGA_PLANTA: {
      let showMap_camiones_carga_planta =
        state.camiones.camiones_carga_planta.camiones.map((camion) => {
          camion.showCar = !state.camiones.camiones_carga_planta.showMap;
          return camion;
        });
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_carga_planta: {
            ...state.camiones.camiones_carga_planta,
            showMap: !state.camiones.camiones_carga_planta.showMap,
            camiones: showMap_camiones_carga_planta,
          },
        },
      };
    }

    /* Muestra o no en el mapa todos los camiones carga en planta */
    case SHOW_MAP_TRANSITO_LLENO: {
      let showMap_camiones_transito_lleno =
        state.camiones.camiones_transito_lleno.camiones.map((camion) => {
          camion.showCar = !state.camiones.camiones_transito_lleno.showMap;
          return camion;
        });
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_transito_lleno: {
            ...state.camiones.camiones_transito_lleno,
            showMap: !state.camiones.camiones_transito_lleno.showMap,
            camiones: showMap_camiones_transito_lleno,
          },
        },
      };
    }

    /* Muestra o no en el mapa todos los camiones en pad */
    case SHOW_MAP_PAD: {
      let showMap_camiones_pad = state.camiones.camiones_pad.camiones.map(
        (camion) => {
          camion.showCar = !state.camiones.camiones_pad.showMap;
          return camion;
        }
      );
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_pad: {
            ...state.camiones.camiones_pad,
            showMap: !state.camiones.camiones_pad.showMap,
            camiones: showMap_camiones_pad,
          },
        },
      };
    }

    // /* Muestra o no en el mapa todos los camiones sin geotab */
    // case SHOW_MAP_SIN_GEOTAB: {
    //   let showMap_camiones_sin_geotab =
    //     state.camiones.camiones_sin_geotab.camiones.map((camion) => {
    //       camion.showCar = !state.camiones.camiones_sin_geotab.showMap;
    //       return camion;
    //     });
    //   return {
    //     ...state,

    //     camiones: {
    //       ...state.camiones,
    //       camiones_sin_geotab: {
    //         ...state.camiones.camiones_sin_geotab,
    //         showMap: !state.camiones.camiones_sin_geotab.showMap,
    //         camiones: showMap_camiones_sin_geotab,
    //       },
    //     },
    //   };
    // }

    /* Muestra o no en el mapa todos los camiones en transito vacio */
    case SHOW_CAR_TRANSITO_VACIO: {
      let newCamiones_transito_vacio =
        state.camiones.camiones_transito_vacio.camiones.map((camion) => {
          if (camion.placa === payload.placa) {
            camion.showCar = !camion.showCar;
          }
          return camion;
        });
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_transito_vacio: {
            ...state.camiones.camiones_transito_vacio,
            camiones: newCamiones_transito_vacio,
          },
        },
      };
    }
    /* Muestra o no en el mapa todos los camiones en pad */
    case SHOW_CAR_PAD: {
      let newCamiones_pad = state.camiones.camiones_pad.camiones.map(
        (camion) => {
          if (camion.placa === payload.placa) {
            camion.showCar = !camion.showCar;
          }
          return camion;
        }
      );
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_pad: {
            ...state.camiones.camiones_pad,
            camiones: newCamiones_pad,
          },
        },
      };
    }

    /* Muestra o no en el mapa todos los camiones en pad */
    case SHOW_CAR_CARGA_PLANTA: {
      let newCamiones_carga_planta =
        state.camiones.camiones_carga_planta.camiones.map((camion) => {
          if (camion.placa === payload.placa) {
            camion.showCar = !camion.showCar;
          }
          return camion;
        });
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_carga_planta: {
            ...state.camiones.camiones_carga_planta,
            camiones: newCamiones_carga_planta,
          },
        },
      };
    }

    /* Muestra o no en el mapa todos los camiones en transito llenos */
    case SHOW_CAR_TRANSITO_LLENO: {
      let newCamiones_transito_lleno =
        state.camiones.camiones_transito_lleno.camiones.map((camion) => {
          if (camion.placa === payload.placa) {
            camion.showCar = !camion.showCar;
          }
          return camion;
        });
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_transito_lleno: {
            ...state.camiones.camiones_transito_lleno,
            camiones: newCamiones_transito_lleno,
          },
        },
      };
    }

    /* Muestra o no en el mapa todos los camiones sin geotab */
    // case SHOW_CAR_SIN_GEOTAB: {
    //   let newCamiones_sin_geotab =
    //     state.camiones.camiones_sin_geotab.camiones.map((camion) => {
    //       if (camion.placa === payload.placa) {
    //         camion.showCar = !camion.showCar;
    //       }
    //       return camion;
    //     });
    //   return {
    //     ...state,

    //     camiones: {
    //       ...state.camiones,
    //       camiones_sin_geotab: {
    //         ...state.camiones.camiones_sin_geotab,
    //         camiones: newCamiones_sin_geotab,
    //       },
    //     },
    //   };
    // }

    default:
      return state;
  }
};
