import {
  GET_ESCENARIOS,
  RESET_ESCENARIOS,
  GET_PROYECCIONESESCENARIOS,
} from "../constants/actionsTypes";

const initialState = {
  proyeccionCajas: {
    graficoProyeccion: {
      cajasM100: [
        { name: "+1H", value: 0 },
        { name: "+1H", value: 0 },
        { name: "+1H", value: 0 },
        { name: "+1H", value: 0 },
      ],

      cajasM3070: [
        { name: "+0H", value: 0 },
        { name: "+0H", value: 0 },
        { name: "+0H", value: 0 },
        { name: "+0H", value: 0 },
      ],

      numStage: [
        { name: "+0H", value: 0 },
        { name: "+0H", value: 0 },
        { name: "+0H", value: 0 },
        { name: "+0H", value: 0 },
      ],

      transitoM100: [
        { name: "+0H", value: 0 },
        { name: "+0H", value: 0 },
        { name: "+0H", value: 0 },
        { name: "+0H", value: 0 },
      ],

      transitoM3070: [
        { name: "+0H", value: 0 },
        { name: "+0H", value: 0 },
        { name: "+0H", value: 0 },
        { name: "+0H", value: 0 },
      ],
    },
  },
};

export const escenariosReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ESCENARIOS: {
      return { ...state, ...payload };
    }

    case GET_PROYECCIONESESCENARIOS: {
      return Object.assign({}, state, {
        proyeccionCajas: payload,
      });
    }

    case RESET_ESCENARIOS: {
      return initialState;
    }
    default:
      return state;
  }
};
