import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import Indicadores from "../Components/Indicadores/Indicadores";
import Header from "../Components/Header/Header";
import HeaderPad from "../Components/HeaderPad/HeaderPad";
import { EtapaForm } from "../Components/Etapas/EtapaForm/EtapaForm";
import ModalApprove from "../utils/ModalApprove/ModalApprove";
import FullPageLoader from "../utils/FullPageLoader/FullPageLoader";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  @media (max-width: 768px) {
    overflow: auto;
    display: block;
  }
`;
const Home = styled(Grid)`
  position: relative;
`;

export const IngresarEtapa = () => {
  const [showModalEtapasDia, setShowModalEtapasDias] = useState(false);
  const [showModalCajasPad, setShowModalCajasPad] = useState(false);
  const [showModalModificarCajasPad, setShowModalModificarCajasPad] =
    useState(false);
  const [showModalAltaPad, setShowModalAltaPad] = useState(false);
  const [showModalEditarPad, setShowModalEditarPad] = useState(false);
  const [showModalFinalizarPad, setShowModalFinalizarPad] = useState(false);
  const [showModalReentrenamiento, setShowModalReentrenamiento] =
    useState(false);
  const [showModalEstadoRTForm, setShowModalEstadoRTForm] = useState(false);
  const [showModalEscenarios, setShowModalEscenarios] = useState(false);

  const { Info_de_indicadores } = useSelector((state) => state.indicadores);
  const { Role } = useSelector((state) => state.dataUser.user);
  const isLoading = useSelector((state) => state.loading);
  const [showGraph, setShowGraph] = useState(false);

  const openModalToggleEtapasDia = () => {
    setShowModalEtapasDias(!showModalEtapasDia);
  };

  const openModalToggleCajasPad = () => {
    setShowModalCajasPad(!showModalCajasPad);
  };

  const openModalToggleModificarCajasPad = () => {
    setShowModalModificarCajasPad(!showModalModificarCajasPad);
  };

  const openModalToggleAltaPad = () => {
    setShowModalAltaPad(!showModalAltaPad);
  };

  const openModalToggleEditarPad = () => {
    setShowModalEditarPad(!showModalEditarPad);
  };
  const openModalToggleFinalizarPad = () => {
    setShowModalFinalizarPad(!showModalFinalizarPad);
  };
  const openModalToggleEscenarios = () => {
    setShowModalEscenarios(!showModalEscenarios);
  };
  const openModalToggleReentrenamiento = () => {
    setShowModalReentrenamiento(!showModalReentrenamiento);
  };
  const openModalToggleEstadoRTForm = () => {
    setShowModalEstadoRTForm(!showModalEstadoRTForm);
  };

  return (
    <Home container>
      <Grid item xs={12} md={3} sm={3}>
        <HeaderPad
          setShowModalEtapasDias={setShowModalEtapasDias}
          setShowModalCajasPad={setShowModalCajasPad}
          setShowModalModificarCajasPad={setShowModalModificarCajasPad}
          setShowModalAltaPad={setShowModalAltaPad}
          setShowModalEditarPad={setShowModalEditarPad}
          setShowModalFinalizarPad={setShowModalFinalizarPad}
          setShowModalEscenarios={setShowModalEscenarios}
        />
      </Grid>
      <Grid item xs={12} md={9} sm={9}>
        <Header />
      </Grid>
      <Container>
        <Grid item xs={12} md={3}>
          <Indicadores
            showGraph={showGraph}
            setShowGraph={setShowGraph}
            Role={Role}
            Info_de_indicadores={Info_de_indicadores}
          />
        </Grid>
        <Grid item xs={12} md={9} sm={12} style={{ overflow: "auto" }}>
          <EtapaForm />
        </Grid>

        <ModalApprove />
      </Container>

      <FullPageLoader isLoading={isLoading} />
    </Home>
  );
};
