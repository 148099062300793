import React from "react";
import ReactApexChart from "react-apexcharts";
import { Box } from "@material-ui/core";
import { Container, Header, Title, CloseIcon, Divide } from "./Styles.js";
import { useSelector } from "react-redux";
import FullPageLoader from "../../utils/FullPageLoader/FullPageLoader";

const GraficoEscenarios = ({ showGraph, setShowGraph, data }) => {
  const isLoading = useSelector((state) => state.loading);
  const optionsArena = {
    annotations: {
      position: "back",
      yaxis: [
        {
          label: {
            text: " ",
          },
          y: 0,
          y2: 2,
          fillColor: "#A03434",
        },
        {
          label: {
            text: " ",
          },
          y: 2,
          y2: 4,
          fillColor: "#9F7A00",
        },
        {
          label: {
            text: " ",
          },
          y: 4,
          y2: 6,
          fillColor: "#138B33",
        },
      ],
    },
    chart: {
      type: "area",
    },
    colors: ["#18A0FB", "#5200FF"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    xaxis: {
      categories: data?.cajasM100.map((item) => item.name.toString()),
      title: {
        text: "Horas proyectadas",
        style: {
          fontWeight: "light",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 6,
      tickAmount: 6,
      decimalsInFloat: 0,
      title: {
        text: "Etapas",
        style: {
          fontWeight: "light",
        },
      },
    },
    legend: {
      position: "top",
    },
  };

  const optionsNumStage = {
    annotations: {
      position: "back",
    },
    chart: {
      type: "area",
    },
    colors: ["#18A0FB"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    xaxis: {
      categories: data?.numStage.map((item) => item.name.toString()),

      title: {
        text: "Horas proyectadas",
        style: {
          fontWeight: "light",
        },
      },
    },
    yaxis: {
      title: {
        text: "Etapas acumuladas",
        style: {
          fontWeight: "light",
        },
      },
    },
  };
  const optionsTransito = {
    annotations: {
      position: "back",
    },
    chart: {
      type: "area",
    },
    colors: ["#18A0FB", "#5200FF"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    xaxis: {
      categories: data?.transitoM100.map((item) => item.name.toString()),

      title: {
        text: "Horas proyectadas",
        style: {
          fontWeight: "light",
        },
      },
    },
    yaxis: {
      title: {
        text: "Camiones",
        style: {
          fontWeight: "light",
        },
      },
    },
    legend: {
      position: "top",
    },
  };
  const seriesArena = [
    {
      name: "Arena M100",
      data: data?.cajasM100.map((item) => item.value),
    },
    {
      name: "Arena M3070",
      data: data?.cajasM3070.map((item) => item.value),
    },
  ];

  const seriesNumStage = [
    {
      name: "Etapa",
      data: data?.numStage.map((item) => item.value),
    },
  ];

  const seriesTransito = [
    {
      name: "Camiones M100",
      data: data?.transitoM100.map((item) => item.value),
    },
    {
      name: "Camiones M3070",
      data: data?.transitoM3070.map((item) => item.value),
    },
  ];

  return (
    <Container>
      <Header item>
        <CloseIcon onClick={() => setShowGraph(!showGraph)} />
      </Header>
      <div>
        <Box pl="10px" mb="10px">
          <Title
            style={{
              fontSize: "12px",
            }}
          >
            Etapas cubiertas para arena M100 y M3070
          </Title>
        </Box>
        <Box>
          <ReactApexChart
            height={280}
            options={optionsArena}
            series={seriesArena}
          />
        </Box>
      </div>

      <Divide />

      <div>
        <Box pl="10px" mb="10px">
          <Title
            style={{
              fontSize: "12px",
            }}
          >
            Etapas Acumuladas
          </Title>
        </Box>
        <Box>
          <ReactApexChart
            height={200}
            options={optionsNumStage}
            series={seriesNumStage}
          />
        </Box>
      </div>

      <Divide />

      <div>
        <Box pl="10px" mb="10px">
          <Title
            style={{
              fontSize: "12px",
            }}
          >
            Camiones en tránsito
          </Title>
        </Box>
        <Box>
          <ReactApexChart
            height={200}
            options={optionsTransito}
            series={seriesTransito}
          />
        </Box>
      </div>
      <FullPageLoader isLoading={isLoading} />
    </Container>
  );
};

export default GraficoEscenarios;
