import styled from 'styled-components'
import { Form } from "react-bootstrap";
import { FormControl, Grid } from '@material-ui/core';

export const Div = styled.div`
  margin: 0 auto;
  height: 100%;
  background: #ffffff;
  box-sizing: border-box;
  padding: 1.25rem 1.875rem;
  width: 31.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`

export const Container = styled.div`
  margin: 0 auto;
  background: #ffffff;
  border: 0.0625rem solid #e5e5e5;
  box-shadow: 0rem 1.25rem 2.5rem -0.75rem rgba(196, 196, 196, 0.35);
  box-sizing: border-box;
  padding: 1.25rem 1.875rem;
  width: 31.25rem;
  flex: none;

  @media(max-width: 768px) {
    width: 90%;
    padding: 10px;
  }
`

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  & p {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
  }
`

export const StyledFormm = styled(Form)`
  width: 90%;
  margin: 0 auto;
  margin-top: 1.375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
`;

export const FormGroup = styled(FormControl)`
  width: 15.625rem;
  height: 4.375rem;
  @media(max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: calc(40px - 1.813rem) auto 1.875rem auto;
`;