import React from "react";
import ReactDOM from "react-dom";
import { Grid, FormHelperText, InputLabel, Input } from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  GridItem,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
// import { addHours } from "../../../utils/DateTimeConverter/DateTimeConverter";
import { postReporteCamionesDetenidos } from "../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  fecha_inicio: yup.string().required("La fecha de inicio es requerida"),
  fecha_fin: yup.string().required("La fecha de fin es requerida"),
});

export const ModalReporteCamionesDetenidos = ({ onClose }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      fecha_inicio: "dd/mm/aaaa",
      fecha_fin: "dd/mm/aaaa",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      console.log(values);
      dispatch(postReporteCamionesDetenidos(values));
      onClose();
    },
  });

  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Reporte de Camiones Detenidos</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="fecha_inicio">Periodo de inicio</InputLabel>
                <Input
                  id="fecha_inicio"
                  name="fecha_inicio"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fecha_inicio}
                  error={
                    formik.errors.fecha_inicio && formik.touched.fecha_inicio
                  }
                />

                {formik.errors.fecha_inicio && formik.touched.fecha_inicio && (
                  <FormHelperText
                    error={
                      formik.errors.fecha_inicio && formik.touched.fecha_inicio
                    }
                  >
                    {formik.errors?.fecha_inicio}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <InputLabel htmlFor="fecha_fin">Periodo de cierre</InputLabel>
                <Input
                  id="fecha_fin"
                  name="fecha_fin"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fecha_fin}
                  error={formik.errors.fecha_fin && formik.touched.fecha_fin}
                />

                {formik.errors.fecha_fin && formik.touched.fecha_fin && (
                  <FormHelperText error>
                    {formik.errors?.fecha_fin}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>
          </Grid>
          <Grid container md={12} sm={8} justify="center">
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Descargar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
