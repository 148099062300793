import { GET_DESHABILITAR_CAMION, RESET_DESHABILITAR_CAMION } from '../constants/actionsTypes';

export const getDeshabilitarCamion = (payload) => {
  return {
    type: GET_DESHABILITAR_CAMION,
    payload
  };
};


export const resetDeshabilitarCamion = (payload) => (
  {
    type: RESET_DESHABILITAR_CAMION,
    payload
  }
)