import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import { ReactComponent as Close } from "../../assets/img/times.svg";

export const Container = styled(Grid)`
  width: 300%;
  height: 100%;
  padding: 1.1rem 0.5rem;
  background: #f0f0f0;
  position: absolute;
  z-index: 100;
  top: 0;
  -webkit-box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;

  @media (min-width: 1024px) and (max-width: 1920px) {
    left: calc(100vw - 75vw);
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 15px;
`;

export const Header = styled(Grid)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;

export const Title = styled.p`
  margin: 0;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1.1rem")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "600")};
  @media (min-width: 1024px) and (max-width: 1919px) {
    font-size: 0.9rem;
  }
`;

export const Divide = styled.hr`
  background-color: #c4c4c4;
  width: 100%;
  margin-top: ${(props) => (props.mtop ? "1.106rem" : "0")};
  margin-bottom: 1.106rem;
`;
