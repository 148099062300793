import React from "react";

import ReactDOM from "react-dom";
import { Grid, FormLabel } from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  GridItem,
  StyledForm,
  CustomCheckbox,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { Inputs } from "../Input/Inputs";
import { addHours } from "../../utils/DateTimeConverter/DateTimeConverter";
import { FinalizarPad } from "../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  comentario: yup.string(),
  usar_datos: yup.boolean(),
});

export const ModalFinalizarPad = ({ onClose }) => {
  const dispatch = useDispatch();
  const hoyArgentina = addHours(-3, new Date()).toISOString();
  const { Name, Role, Email } = useSelector((state) => state.dataUser.user);

  const formik = useFormik({
    initialValues: {
      comentario: "",
      usar_datos: false,
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      if (window.confirm("¿Estás seguro que querés finalizar el Pad?")) {
        dispatch(
          FinalizarPad({
            comentario: values.comentario,
            user_name: Name,
            user_email: Email,
            user_rol: Role,
            datetime: hoyArgentina,
            usar_datos: values.usar_datos,
          })
        );

        onClose();
      }
    },
  });

  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Finalizar Pad</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container>
            <GridItem item xs={12} md={10}>
              <FormGroup>
                <Inputs
                  id="comentario"
                  autoComplete="off"
                  type="text"
                  name="comentario"
                  label="Dejá un comentario (opcional)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.comentario}
                />
              </FormGroup>
            </GridItem>
            <GridItem item xs={12} md={8}>
              <FormGroup>
                <div>
                  <CustomCheckbox
                    type="checkbox"
                    id="usar_datos"
                    name="usar_datos"
                    checked={formik.values.usar_datos}
                    onChange={formik.handleChange}
                  />
                  <FormLabel htmlFor="usar_datos">
                    Usar Pad para reentrenamiento
                  </FormLabel>
                </div>
              </FormGroup>
            </GridItem>
            <Grid container md={12} sm={8} justify="center">
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    type="button"
                    textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                    onClick={() => handleClose()}
                    variant="outlined"
                    color="primary"
                    minwidth="true"
                  />
                </ButtonContainer>
              </Grid>
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    // disabled={validated}
                    type="submit"
                    textArea="Finalizar Pad"
                    variant="contained"
                    color="primary"
                    mright="true"
                    minwidth="true"
                  />
                </ButtonContainer>
              </Grid>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
