import {
  GET_INFO_PLANTAS_CLS,
  RESET_INFO_PLANTAS_CLS,
} from "../../constants/actionsTypes";

export const getInfoPlantasCls = (payload) => {
  return {
    type: GET_INFO_PLANTAS_CLS,
    payload,
  };
};

export const resetInfoPlantasCls = (payload) => ({
  type: RESET_INFO_PLANTAS_CLS,
  payload,
});
