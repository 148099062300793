import { GET_CAMIONES_INACTIVOS, RESET_CAMIONES_INACTIVOS } from '../constants/actionsTypes';

export const getCamionesInactivos = (payload) => {
  return {
    type: GET_CAMIONES_INACTIVOS,
    payload
  };
};


export const resetCamionesInactivos = (payload) => (
  {
    type: RESET_CAMIONES_INACTIVOS,
    payload
  }
)