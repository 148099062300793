import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import Indicadores from "../Components/Indicadores/Indicadores";
import Header from "../Components/Header/Header";
import HeaderPad from "../Components/HeaderPad/HeaderPad";
import { EditarEtapa } from "../Components/Etapas/EditarEtapa/EditarEtapa";
import { consultarEtapas } from "../Services";
import ModalApprove from "../utils/ModalApprove/ModalApprove";
import FullPageLoader from "../utils/FullPageLoader/FullPageLoader";
import { Sidebar } from "../Components/Sidebar/Sidebar";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  @media (max-width: 768px) {
    overflow: auto;
    display: block;
  }
`;
const Home = styled(Grid)`
  position: relative;
`;

export const EditarEtapas = () => {
  const dispatch = useDispatch();
  const { etapas } = useSelector((state) => state.consultarEtapas);
  const isLoading = useSelector((state) => state.loading);

  const { Info_de_indicadores } = useSelector((state) => state.indicadores);
  const { Role } = useSelector((state) => state.dataUser.user);
  const [showGraph, setShowGraph] = useState(false);

  useEffect(() => {
    dispatch(consultarEtapas());
  }, [dispatch]);

  return (
    <Home container>
      <Grid item xs={12} md={3} sm={3}>
        <Sidebar />
        <HeaderPad />
      </Grid>
      <Grid item xs={12} md={9}>
        <Header />
      </Grid>
      <Container>
        <Grid item xs={12} md={3}>
          <Indicadores
            showGraph={showGraph}
            setShowGraph={setShowGraph}
            Role={Role}
            Info_de_indicadores={Info_de_indicadores}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <EditarEtapa etapas={etapas} />
        </Grid>
        <ModalApprove />
        <FullPageLoader isLoading={isLoading} />
      </Container>
    </Home>
  );
};
