import {
  GET_PLANTAS,
  RESET_PLANTAS,
  SET_CURRENT_DATA,
} from "../constants/actionsTypes";

export const getPlantas = (payload) => {
  return {
    type: GET_PLANTAS,
    payload,
  };
};

export const setCurrenData = (payload) => {
  return {
    type: SET_CURRENT_DATA,
    payload,
  };
};
export const resetPlantas = (payload) => ({
  type: RESET_PLANTAS,
  payload,
});
