import React from "react";
import { useSelector } from "react-redux";
import {
  Container,
  ItemContainer,
  ItemContainer2,
  Wrapper,
  Indicador,
  State,
  Hr,
  Table,
} from "./Styles";
import { sort } from "../../../utils";
/* Componentes */
import { ReactComponent as Clock } from "../../../assets/img/LogoClock.svg";
import { ReactComponent as Camion } from "../../../assets/img/truck.svg";

const Indicadores = ({ indicadoresCls }) => {
  const {
    camiones_circulando,
    monitoreo_de_tiempos,
    toneladas_movidas,
    semaforo,
  } = indicadoresCls ? indicadoresCls : "";

  const groupedData = {};
  toneladas_movidas?.forEach((item) => {
    const key = `${item.origen}-${item.destino}`;
    if (!groupedData[key]) {
      groupedData[key] = [];
    }
    groupedData[key].push(item);
  });

  const { Role } = useSelector((state) => state?.dataUser?.user);

  return (
    <Container container>
      <div className="content_info">
        <h2>Proyección de arena diario</h2>
        <ItemContainer2 className="proyeccion" justify="space-evenly">
          {semaforo?.diario
            ?.sort(sort("nombre"))
            ?.reverse()
            .map((item, index) => {
              return (
                <div key={index}>
                  {item.estado === "bueno" && (
                    <State green greenCard>
                      <span>{item.tn}</span>
                      <span>Tn.</span>
                      <span>{item.nombre}</span>
                    </State>
                  )}

                  {item.estado === "regular" && (
                    <State yellow yellowCard>
                      <span>{item.tn}</span>
                      <span>Tn.</span>
                      <span>{item.nombre}</span>
                    </State>
                  )}

                  {item.estado === "malo" && (
                    <State red redCard>
                      <span>{item.tn}</span>
                      <span>Tn.</span>
                      <span>{item.nombre}</span>
                    </State>
                  )}

                  {item.estado === "inexistente" && (
                    <State grey greyCard>
                      <span>{item.tn}</span>
                      <span>Tn.</span>
                      <span>{item.nombre}</span>
                    </State>
                  )}
                </div>
              );
            })}
        </ItemContainer2>
        <h2>Proyección de arena mensual</h2>
        <ItemContainer2 className="proyeccion" justify="space-evenly">
          {semaforo?.mensual
            ?.sort(sort("nombre"))
            ?.reverse()
            .map((item, index) => {
              return (
                <div key={index}>
                  {item.estado === "bueno" && (
                    <State green greenCard>
                      <span>{item.tn}</span>
                      <span>Tn.</span>
                      <span>{item.nombre}</span>
                    </State>
                  )}

                  {item.estado === "regular" && (
                    <State yellow yellowCard>
                      <span>{item.tn}</span>
                      <span>Tn.</span>
                      <span>{item.nombre}</span>
                    </State>
                  )}

                  {item.estado === "malo" && (
                    <State red redCard>
                      <span>{item.tn}</span>
                      <span>Tn.</span>
                      <span>{item.nombre}</span>
                    </State>
                  )}

                  {item.estado === "inexistente" && (
                    <State grey greyCard>
                      <span>{item.tn}</span>
                      <span>Tn.</span>
                      <span>{item.nombre}</span>
                    </State>
                  )}
                </div>
              );
            })}
        </ItemContainer2>
        <Hr />
        <h2>Camiones circulando</h2>
        {camiones_circulando?.map((camion, index) => (
          <ItemContainer key={index}>
            <Wrapper>
              <Camion style={{ width: "20px", height: "20px" }} />
              <p>{`Total entre ${camion.origen} y ${camion.destino}`}</p>
            </Wrapper>
            <Indicador>{camion.cantidad}</Indicador>
          </ItemContainer>
        ))}
        {(Role === "CoordAluvional" ||
          Role === "SupervAluvional" ||
          Role === "SupplyChain") && (
          <>
            <Hr />
            <h2>Monitoreo de tiempos</h2>
            <ItemContainer>
              <Wrapper>
                <Clock />
                <p> Tiempo de espera en planta de lavado (mins)</p>
              </Wrapper>
              <Indicador>
                {monitoreo_de_tiempos?.tiempo_promedio_en_lavado}
              </Indicador>
            </ItemContainer>
            <ItemContainer>
              <Wrapper>
                <Clock />
                <p>Roundtrip entre cantera y planta de lavado (hs)</p>
              </Wrapper>
              <Indicador>
                {(monitoreo_de_tiempos?.roundtrip_cantera_lavado / 60).toFixed(
                  1
                )}
              </Indicador>
            </ItemContainer>
          </>
        )}
        <Hr />
        {Object.entries(groupedData).map(([key, items], index) => {
          const [origen, destino] = key.split("-");
          return (
            <div key={index}>
              <h2>{`De ${origen} a ${destino}`}</h2>
              <Table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Arena</th>
                    <th>Tns. diarias</th>
                    <th>Tns. acumuladas</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, itemIndex) => (
                    <tr key={itemIndex}>
                      <td>{item.empresa}</td>
                      <td>{item.material}</td>
                      <td>{item.tn_dia}</td>
                      <td>{item.tn_mes}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default Indicadores;
