import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { resetResponse } from '../actions/responseActions';
import { LoginUser } from '../Components/Login/LoginUser'
import FullPageLoader from '../utils/FullPageLoader/FullPageLoader';
import { ModalErrorLogin, ModalForgotPassword } from '../utils/ModalErrorLogin/ModalErrorLogin';

export const Login = () => {
  const [closeModal, setCloseModal] = useState(false)
  const [showForgot, setShowForgot] = useState(false)
  
  const response = useSelector((state) => state.response);
  const isLoading = useSelector(state => state.loading)
  const dispatch = useDispatch()

  const openModalForgotPassword = () => {
    setShowForgot(!showForgot)
  }
  const closeModalErrorLogin = () => {
    setCloseModal(!closeModal)
    dispatch(resetResponse())
  }

  // useEffect(() => {
  //   dispatch(resetResponse())
  // }, [])

  return (
    <>
      <FullPageLoader isLoading={isLoading} />

      <LoginUser 
        openModalForgotPassword={openModalForgotPassword}
      />
      {
        response.status === 401
        && <ModalErrorLogin
            response={response}
            onClose={closeModalErrorLogin}
          />
      }
      {
        showForgot ? <ModalForgotPassword 
          onClose={openModalForgotPassword}
          showForgot={showForgot}
        />
        : null
      }
      
    </>
  )
}