import { GET_INFO_PAD, RESET_INFO_PAD } from "../constants/actionsTypes";

const initialState = {};

export const InfoPadReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INFO_PAD: {
      return { ...state, ...payload };
    }
    case RESET_INFO_PAD: {
      return { infoPad: [] };
    }
    default:
      return state;
  }
};
