import styled from "styled-components";
import { FormControl, Grid } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { Input } from "../Input/Styles";

export const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1000;
  top: 0;
  bottom: 0;
`;

export const ModalWrapper = styled.div`
  background: #ffffff;
  border: 0.0625rem solid #e5e5e5;
  box-shadow: 0rem 1.25rem 2.5rem -0.75rem rgba(196, 196, 196, 0.35);
  box-sizing: border-box;
  padding: 1.25rem 1.875rem;
  width: 38.313rem;
  z-index: 10;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.75rem;
  & p {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  & svg {
    font-size: 1.5rem;
    color: #4f4184;
  }
`;

export const FormGroup = styled(FormControl)`
  width: 95%;
  height: 4.375rem;
  margin: 0 10px;
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  margin: 1.875rem auto 0 auto;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Span = styled.span`
  font-weight: 600;
  margin: 5px 0;
`;

export const GridItem = styled(Grid)`
  display: flex;
  justify-content: ${(props) => (props.justify ? "flex-start" : "flex-end")};
  align-items: center;
`;

export const Inputs = styled.input`
  width: 95%;
  display: flex;
  justify-content: space-evenly;
`;

export const CustomCheckbox = styled.input`
  /* Hide the default checkbox */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  /* Set the custom checkbox size */
  width: 16px;
  height: 16px;
  /* Define the custom checkbox appearance */
  border: 2px solid #4f4184;
  border-radius: 3px;
  margin-right: 4px;
  // cursor: pointer;
  /* Change the background color when the checkbox is checked */
  &:checked {
    background-color: #4f4184;
  }
`;
