import styled from "styled-components";
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core';

export const IconContainer = styled.div`
  & svg {
    font-size: 1.2rem;
    color: #575757;
  }
`

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #FFFFFF;
  overflow: auto;
`;

export const Head = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0.813rem auto 15px auto;
  padding: 0 1rem 0 1rem;
`

export const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.875rem auto calc(1.875rem - 1.25rem) auto;
  padding: 0 1rem 0 1rem;
`

export const TableContainer = styled.div`
  width: 50.625rem;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 1.313rem 1.875rem;
  margin: 30px auto;

  @media(max-width: 768px) {
    width: 98%;
    padding: 10px;
  }
`
export const Buton = styled(Button)`
  font-family: "Roboto", sans-serif;
  background: none;
  border: 0.063rem solid var(--purplePrimary);
  border-radius: 5px;
  font-weight: 700;
  font-size: 0.875rem;
  width: ${props => props.width ? '11.188rem' : '6.25rem'};
  height: 2.5rem;
  &:active:hover:not([disabled]) {
    background: var(--purplePrimary);
    border-color: var(--purplePrimary);
  }
  &:disabled {
    background-color: #c0dcf5;
    border-color: #c0dcf5;
    opacity: 1;
  }
`

export const StyledTableCell = styled.th`
  background-color: #C8C8C8;
  color: #FFFFFF;
  font-size: 14px;
`

export const StyledTableRow = styled.td`
  &:nth-child(even){
    background-color: #F4F4F4;
  }
  &:hover {
    background-color: #ddd;
  }
`
export const ButtonEdit = styled(Link)`
  background: none;
  border: none;
  text-decoration: underline;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

export const Title = styled.p`
  font-family: "Roboto";
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1.563rem;
`

export const Paragraph = styled.p`
  font-family: "Roboto";
  margin-bottom: 1.375rem;
  
`
