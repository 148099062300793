import styled from "styled-components";
import { Grid, FormControl } from "@material-ui/core";
import { ReactComponent as Close } from "../../../../assets/img/times.svg";
import { Form } from "react-bootstrap";

export const CloseLogo = styled(Close)`
  cursor: pointer;
  /* margin-right: 1rem; */
`;

export const IconContainer = styled.div`
  cursor: pointer;
  & svg {
    font-size: 1.5rem;
    color: #4f4184;
  }
`;

export const FormGroup = styled(FormControl)`
  width: 100%;
  height: 4.375rem;
`;

export const StyledForm = styled(Form)`
  width: 90%;
  margin: 0 auto;
`;

export const ContainerModal = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  position: absolute;
  width: 30rem;
  padding: 1.25rem 1.125rem 0 1.125rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.75rem;
  & p {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
  }
`;

export const Divider = styled.hr`
  padding-left: 1rem;
  margin: 0 auto;
  width: 90%;
`;

export const ButtonContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  margin: 1.875rem auto 1.875rem auto;
`;

export const Div = styled(Grid)`
  align-items: center;
  padding: 0.875rem 1.875rem;
  & p {
    margin: 0;
  }
`;
