import {
  GET_INDICADORES_CLS,
  RESET_INDICADORES_CLS,
} from "../../constants/actionsTypes";

export const getIndicadoresCls = (payload) => {
  return {
    type: GET_INDICADORES_CLS,
    payload,
  };
};

export const resetIndicadoresCls = (payload) => ({
  type: RESET_INDICADORES_CLS,
  payload,
});
