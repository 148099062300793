import {
  GET_ESTADO_CAMIONES_CLS,
  RESET_ESTADO_CAMIONES_CLS,
  SHOW_CAR_TRANSITO_CLS,
  SHOW_MAP_TRANSITO_CLS,
  SHOW_CAR_CANTERA_CLS,
  SHOW_MAP_CANTERA_CLS,
  SHOW_CAR_LAVADO_CLS,
  SHOW_MAP_LAVADO_CLS,
  SHOW_CAR_SECADO_CLS,
  SHOW_MAP_SECADO_CLS,
  SHOW_CAR_WS_CLS,
  SHOW_MAP_WS_CLS,
} from "../../constants/actionsTypes";

const initialState = {
  camiones: {
    camiones_en_cantera: {
      showMap: true,
      camiones: [],
    },
    camiones_transito: {
      showMap: true,
      camiones: [],
    },
    camiones_en_lavado: {
      showMap: true,
      camiones: [],
    },
    camiones_en_secado: {
      showMap: true,
      camiones: [],
    },
    camiones_en_ws: {
      showMap: true,
      camiones: [],
    },
  },
};

export const estadoCamionesClsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ESTADO_CAMIONES_CLS: {
      return { ...state, ...payload };
    }
    case RESET_ESTADO_CAMIONES_CLS: {
      return { estadoCamiones: {} };
    }
    /* Muestra o no en el mapa todos los camiones en transito */
    case SHOW_MAP_TRANSITO_CLS: {
      let showMap_camiones_transito =
        state.camiones.camiones_transito.camiones.map((camion) => {
          camion.showCar = !state.camiones.camiones_transito.showMap;
          return camion;
        });
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_transito: {
            ...state.camiones.camiones_transito,
            showMap: !state.camiones.camiones_transito.showMap,
            camiones: showMap_camiones_transito,
          },
        },
      };
    }
    /* Muestra o no en el mapa todos los camiones en cantera */
    case SHOW_MAP_CANTERA_CLS: {
      let showMap_camiones_en_cantera =
        state.camiones.camiones_en_cantera.camiones.map((camion) => {
          camion.showCar = !state.camiones.camiones_en_cantera.showMap;
          return camion;
        });
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_en_cantera: {
            ...state.camiones.camiones_en_cantera,
            showMap: !state.camiones.camiones_en_cantera.showMap,
            camiones: showMap_camiones_en_cantera,
          },
        },
      };
    }

    /* Muestra o no en el mapa todos los camiones en planta de secado */
    case SHOW_MAP_SECADO_CLS: {
      let showMap_camiones_en_secado =
        state.camiones.camiones_en_secado.camiones.map((camion) => {
          camion.showCar = !state.camiones.camiones_en_secado.showMap;
          return camion;
        });
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_en_secado: {
            ...state.camiones.camiones_en_secado,
            showMap: !state.camiones.camiones_en_secado.showMap,
            camiones: showMap_camiones_en_secado,
          },
        },
      };
    }

    /* Muestra o no en el mapa todos los camiones en planta de lavado */
    case SHOW_MAP_LAVADO_CLS: {
      let showMap_camiones_en_lavado =
        state.camiones.camiones_en_lavado.camiones.map((camion) => {
          camion.showCar = !state.camiones.camiones_en_lavado.showMap;
          return camion;
        });
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_en_lavado: {
            ...state.camiones.camiones_en_lavado,
            showMap: !state.camiones.camiones_en_lavado.showMap,
            camiones: showMap_camiones_en_lavado,
          },
        },
      };
    }
    /* Muestra o no en el mapa todos los camiones en transito */
    case SHOW_CAR_TRANSITO_CLS: {
      let newCamiones_transito = state.camiones.camiones_transito.camiones.map(
        (camion) => {
          if (camion.placa === payload.placa) {
            camion.showCar = !camion.showCar;
          }
          return camion;
        }
      );
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_transito: {
            ...state.camiones.camiones_transito,
            camiones: newCamiones_transito,
          },
        },
      };
    }
    /* Muestra o no en el mapa todos los camiones en cantera */
    case SHOW_CAR_CANTERA_CLS: {
      let newCamiones_en_cantera =
        state.camiones.camiones_en_cantera.camiones.map((camion) => {
          if (camion.placa === payload.placa) {
            camion.showCar = !camion.showCar;
          }
          return camion;
        });
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_en_cantera: {
            ...state.camiones.camiones_en_cantera,
            camiones: newCamiones_en_cantera,
          },
        },
      };
    }

    /* Muestra o no en el mapa todos los camiones en planta de secado */
    case SHOW_CAR_SECADO_CLS: {
      let newCamiones_en_secado =
        state.camiones.camiones_en_secado.camiones.map((camion) => {
          if (camion.placa === payload.placa) {
            camion.showCar = !camion.showCar;
          }
          return camion;
        });
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_en_secado: {
            ...state.camiones.camiones_en_secado,
            camiones: newCamiones_en_secado,
          },
        },
      };
    }

    /* Muestra o no en el mapa todos los camiones en planta de lavado */
    case SHOW_CAR_LAVADO_CLS: {
      let newCamiones_en_lavado =
        state.camiones.camiones_en_lavado.camiones.map((camion) => {
          if (camion.placa === payload.placa) {
            camion.showCar = !camion.showCar;
          }
          return camion;
        });
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_en_lavado: {
            ...state.camiones.camiones_en_lavado,
            camiones: newCamiones_en_lavado,
          },
        },
      };
    }
    /* Muestra o no en el mapa todos los camiones en planta de WS Brent */
    case SHOW_CAR_WS_CLS: {
      let newCamiones_en_ws = state.camiones.camiones_en_ws.camiones.map(
        (camion) => {
          if (camion.placa === payload.placa) {
            camion.showCar = !camion.showCar;
          }
          return camion;
        }
      );
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_en_ws: {
            ...state.camiones.camiones_en_ws,
            camiones: newCamiones_en_ws,
          },
        },
      };
    }

    /* Muestra o no en el mapa todos los camiones en planta de WS Brent */
    case SHOW_MAP_WS_CLS: {
      let showMap_camiones_en_ws = state.camiones.camiones_en_ws.camiones.map(
        (camion) => {
          camion.showCar = !state.camiones.camiones_en_ws.showMap;
          return camion;
        }
      );
      return {
        ...state,
        camiones: {
          ...state.camiones,
          camiones_en_ws: {
            ...state.camiones.camiones_en_ws,
            showMap: !state.camiones.camiones_en_ws.showMap,
            camiones: showMap_camiones_en_ws,
          },
        },
      };
    }

    default:
      return state;
  }
};
