import {GET_RECOMENDADOR, RESET_RECOMENDADOR } from '../constants/actionsTypes'

const initialState = {
  recomendacion: {}
}

export const recomendadorReducer = (state = initialState, action) => {
  const { type, payload } = action
  
  switch (type) {
    case GET_RECOMENDADOR: {
      return { ...state, ...payload }
    }

    case RESET_RECOMENDADOR:
      return { ...initialState }

    default:
      return state
  }
}