import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Grid, FormHelperText } from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  GridItem,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { Inputs } from "../Input/Inputs";
import { addHours } from "../../utils/DateTimeConverter/DateTimeConverter";
import { habilitarPad } from "../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  nombre_pad: yup.string().required("Campo requerido"),
  lat: yup
    .number()
    .lessThan(-30, "El valor debe estar entre -30 y -40")
    .moreThan(-40, "El valor debe estar entre -30 y -40")
    .required("Campo requerido"),
  long: yup
    .number()
    .lessThan(-60, "El valor debe estar entre -60 y -70")
    .moreThan(-70, "El valor debe estar entre -60 y -70")
    .required("Campo requerido"),
  geo_cerca: yup
    .number()
    .moreThan(0, "Debe ser mayor a 0")
    .lessThan(1, "Debe ser menor que 1")
    .required("Campo requerido"),
  cajasLLenasM100: yup
    .number()
    .moreThan(0, "Debe ser mayor a 0")
    .required("Campo requerido"),
  cajasLLenasM3070: yup
    .number()
    .moreThan(0, "Debe ser mayor a 0")
    .required("Campo requerido"),
  consumo_arenaM100: yup
    .number()
    .moreThan(0, "Debe ser mayor a 0")
    .required("Campo requerido"),
  consumo_arenaM3070: yup
    .number()
    .moreThan(0, "Debe ser mayor a 0")
    .required("Campo requerido"),
  etapas_dia: yup
    .number()
    .moreThan(0, "Debe ser mayor a 0")
    .required("Campo requerido"),
  total_etapas: yup
    .number()
    .moreThan(0, "Debe ser mayor a 0")
    .required("Campo requerido"),
  // total_camiones: yup
  //   .number()
  //   .moreThan(0, "Debe ser mayor a 0")
  //   .required("Campo requerido"),
});

export const ModalAltaPad = ({ onClose }) => {
  const dispatch = useDispatch();
  const hoyArgentina = addHours(-3, new Date()).toISOString();
  const { Name, Role, Email } = useSelector((state) => state.dataUser.user);
  // const [validated, setValidated] = useState(false);

  const formik = useFormik({
    initialValues: {
      nombre_pad: "",
      lat: 0,
      long: 0,
      geo_cerca: 0,
      cajasLLenasM100: 0,
      cajasLLenasM3070: 0,
      consumo_arenaM100: 0,
      consumo_arenaM3070: 0,
      etapas_dia: 0,
      total_etapas: 0,
      // total_camiones: 0,
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      if (window.confirm("¿Está seguro que desea dar de alta el pad?")) {
        dispatch(
          habilitarPad({
            name: values.nombre_pad,
            latitude: values.lat,
            longitude: values.long,
            geothresh: values.geo_cerca,
            box_full_m100: values.cajasLLenasM100,
            box_full_m3070: values.cajasLLenasM3070,
            feed_rate_per_stage_m100: values.consumo_arenaM100,
            feed_rate_per_stage_m3070: values.consumo_arenaM3070,
            stage_per_day: values.etapas_dia,
            total_stages: values.total_etapas,
            // total_camiones: values.total_camiones,
            user_name: Name,
            user_email: Email,
            user_rol: Role,
            datetime: hoyArgentina,
          })
        );
        onClose();
      }
    },
  });

  const handleClose = () => {
    onClose();
  };

  // useEffect(() => {
  //   if (
  //     formik.errors.nombre_pad ||
  //     formik.errors.lat ||
  //     formik.errors.long ||
  //     formik.errors.geo_cerca ||
  //     formik.errors.cajasLLenasM100 ||
  //     formik.errors.cajasLLenasM3070 ||
  //     formik.errors.consumo_arenaM100 ||
  //     formik.errors.consumo_arenaM3070 ||
  //     formik.errors.etapas_dia ||
  //     formik.errors.total_etapas ||
  //     formik.errors.total_camiones
  //   ) {
  //     setValidated(true);
  //   }
  // }, [
  //   formik.errors.nombre_pad,
  //   formik.errors.lat,
  //   formik.errors.long,
  //   formik.errors.geo_cerca,
  //   formik.errors.cajasLLenasM100,
  //   formik.errors.cajasLLenasM3070,
  //   formik.errors.consumo_arenaM100,
  //   formik.errors.consumo_arenaM3070,
  //   formik.errors.etapas_dia,
  //   formik.errors.total_etapas,
  //   formik.errors.total_camiones,
  // ]);

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Habilitar Pad</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="nombre_pad"
                  autoComplete="off"
                  type="text"
                  name="nombre_pad"
                  label="Nombre del pad"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nombre_pad}
                  error={formik.errors.nombre_pad && formik.touched.nombre_pad}
                />
                {formik.errors.nombre_pad && formik.touched.nombre_pad && (
                  <FormHelperText
                    error={
                      formik.errors.nombre_pad && formik.touched.nombre_pad
                    }
                  >
                    {formik.errors.nombre_pad}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="lat"
                  autoComplete="off"
                  type="number"
                  name="lat"
                  label="Latitud (grados decimales)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lat}
                  error={formik.errors.lat && formik.touched.lat}
                />
                {formik.errors.lat && formik.touched.lat && (
                  <FormHelperText
                    error={formik.errors.lat && formik.touched.lat}
                  >
                    {formik.errors.lat}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="long"
                  autoComplete="off"
                  type="number"
                  name="long"
                  label="Longitud (grados decimales)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.long}
                  error={formik.errors.long && formik.touched.long}
                />
                {formik.errors.long && formik.touched.long && (
                  <FormHelperText
                    error={formik.errors.long && formik.touched.long}
                  >
                    {formik.errors.long}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="geo_cerca"
                  autoComplete="off"
                  type="number"
                  name="geo_cerca"
                  label="Geocerca (km)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.geo_cerca}
                  error={formik.errors.geo_cerca && formik.touched.geo_cerca}
                />
                {formik.errors.geo_cerca && formik.touched.geo_cerca && (
                  <FormHelperText
                    error={formik.errors.geo_cerca && formik.touched.geo_cerca}
                  >
                    {formik.errors.geo_cerca}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="cajasLLenasM100"
                  autoComplete="off"
                  type="number"
                  name="cajasLLenasM100"
                  label="Cajas llenas M100 (cantidad)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cajasLLenasM100}
                  error={
                    formik.errors.cajasLLenasM100 &&
                    formik.touched.cajasLLenasM100
                  }
                />
                {formik.errors.cajasLLenasM100 &&
                  formik.touched.cajasLLenasM100 && (
                    <FormHelperText
                      error={
                        formik.errors.cajasLLenasM100 &&
                        formik.touched.cajasLLenasM100
                      }
                    >
                      {formik.errors.cajasLLenasM100}
                    </FormHelperText>
                  )}
              </FormGroup>
            </GridItem>

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="cajasLLenasM3070"
                  autoComplete="off"
                  type="number"
                  name="cajasLLenasM3070"
                  label="Cajas llenas M3070 (cantidad)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cajasLLenasM3070}
                  error={
                    formik.errors.cajasLLenasM3070 &&
                    formik.touched.cajasLLenasM3070
                  }
                />
                {formik.errors.cajasLLenasM3070 &&
                  formik.touched.cajasLLenasM3070 && (
                    <FormHelperText
                      error={
                        formik.errors.cajasLLenasM3070 &&
                        formik.touched.cajasLLenasM3070
                      }
                    >
                      {formik.errors.cajasLLenasM3070}
                    </FormHelperText>
                  )}
              </FormGroup>
            </GridItem>

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="consumo_arenaM100"
                  autoComplete="off"
                  type="number"
                  name="consumo_arenaM100"
                  label="Consumo de cajas M100 por etapa (cantidad)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.consumo_arenaM100}
                  error={
                    formik.errors.consumo_arenaM100 &&
                    formik.touched.consumo_arenaM100
                  }
                />
                {formik.errors.consumo_arenaM100 &&
                  formik.touched.consumo_arenaM100 && (
                    <FormHelperText
                      error={
                        formik.errors.consumo_arenaM100 &&
                        formik.touched.consumo_arenaM100
                      }
                    >
                      {formik.errors.consumo_arenaM100}
                    </FormHelperText>
                  )}
              </FormGroup>
            </GridItem>

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="consumo_arenaM3070"
                  autoComplete="off"
                  type="number"
                  name="consumo_arenaM3070"
                  label="Consumo de cajas M3070 por etapa (cantidad)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.consumo_arenaM3070}
                  error={
                    formik.errors.consumo_arenaM3070 &&
                    formik.touched.consumo_arenaM3070
                  }
                />
                {formik.errors.consumo_arenaM3070 &&
                  formik.touched.consumo_arenaM3070 && (
                    <FormHelperText
                      error={
                        formik.errors.consumo_arenaM3070 &&
                        formik.touched.consumo_arenaM3070
                      }
                    >
                      {formik.errors.consumo_arenaM3070}
                    </FormHelperText>
                  )}
              </FormGroup>
            </GridItem>

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="etapas_dia"
                  autoComplete="off"
                  type="number"
                  name="etapas_dia"
                  label="Etapas por día (cantidad)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.etapas_dia}
                  error={formik.errors.etapas_dia && formik.touched.etapas_dia}
                />
                {formik.errors.etapas_dia && formik.touched.etapas_dia && (
                  <FormHelperText
                    error={
                      formik.errors.etapas_dia && formik.touched.etapas_dia
                    }
                  >
                    {formik.errors.etapas_dia}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="total_etapas"
                  autoComplete="off"
                  type="number"
                  name="total_etapas"
                  label="Cantidad de etapas a ejecutar (cantidad)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.total_etapas}
                  error={
                    formik.errors.total_etapas && formik.touched.total_etapas
                  }
                />
                {formik.errors.total_etapas && formik.touched.total_etapas && (
                  <FormHelperText
                    error={
                      formik.errors.total_etapas && formik.touched.total_etapas
                    }
                  >
                    {formik.errors.total_etapas}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>

            {/* <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="total_camiones"
                  autoComplete="off"
                  type="number"
                  name="total_camiones"
                  label="Número total de camiones (cantidad)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.total_camiones}
                  error={
                    formik.errors.total_camiones &&
                    formik.touched.total_camiones
                  }
                />
                {formik.errors.total_camiones &&
                  formik.touched.total_camiones && (
                    <FormHelperText
                      error={
                        formik.errors.total_camiones &&
                        formik.touched.total_camiones
                      }
                    >
                      {formik.errors.total_camiones}
                    </FormHelperText>
                  )}
              </FormGroup>
            </GridItem> */}

            <Grid container md={12} sm={8} justify="center">
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    type="button"
                    textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                    onClick={() => handleClose()}
                    variant="outlined"
                    color="primary"
                    minwidth="true"
                  />
                </ButtonContainer>
              </Grid>
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    // disabled={validated}
                    type="submit"
                    textArea="Iniciar Pad"
                    variant="contained"
                    color="primary"
                    mright="true"
                    minwidth="true"
                  />
                </ButtonContainer>
              </Grid>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
