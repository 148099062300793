import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import reducers from '../reducers'
import { loadState, saveState } from './localstorage';

export const emptyState = {
  dataUser: {}
}


const initialState = loadState() || emptyState
const middleware = [thunk]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);
store.subscribe(() => saveState(store.getState()))