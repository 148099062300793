import React from "react";
import { Grid } from "@material-ui/core";
import { ContainToltip, Divider } from "./Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* Iconos */
import { ReactComponent as CarIcon } from "../../../assets/img/truck.svg";
import { ReactComponent as DestinoIcon } from "../../../assets/img/destino.svg";
import { ReactComponent as Clock } from "../../../assets/img/clockRecomended.svg";
import { ReactComponent as SubstractIcon } from "../../../assets/img/substract.svg";
import { formatHoras } from "../../../utils";
import {
  faHistory,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { ConvertDateTime } from "../../../utils/DateTimeConverter/DateTimeConverter";

export const TooltipCamiones = ({
  placa,
  tipo_arena,
  destino,
  llegada_pad,
  fechaGps,
  horaGps,
  alerta,
}) => {
  const [fechaGpsFixed, horaGpsFixed] = ConvertDateTime(fechaGps, horaGps);

  return (
    <>
      <ContainToltip container>
        <Grid item style={{ marginRight: "0.688rem" }}>
          <CarIcon width="34px" />
        </Grid>
        <Grid item>
          <span>{placa}</span>
        </Grid>
      </ContainToltip>
      <Divider />
      <ContainToltip container>
        <Grid item style={{ marginRight: "0.688rem" }}>
          <DestinoIcon width="34px" />
        </Grid>
        <Grid item>
          <span>{destino}</span>
        </Grid>
      </ContainToltip>
      <Divider />
      <ContainToltip container>
        <Grid item style={{ marginRight: "0.688rem" }}>
          <Clock width="34px" />
        </Grid>
        <Grid item>
          <span>
            {llegada_pad.toFixed(2) > 60
              ? `${formatHoras(llegada_pad.toFixed(0))} Horas`
              : `${formatHoras(llegada_pad.toFixed(0))} Minutos`}
          </span>
        </Grid>
      </ContainToltip>
      <Divider />
      <ContainToltip container>
        <Grid item style={{ marginRight: "0.688rem" }}>
          <SubstractIcon width="34px" />
        </Grid>
        <Grid item>
          <span>
            {tipo_arena ? tipo_arena?.toUpperCase() : "Sin Especificar"}
          </span>
        </Grid>
      </ContainToltip>
      <Divider />
      <ContainToltip container>
        <Grid item style={{ marginRight: "0.688rem" }}>
          <FontAwesomeIcon className="icon_update" icon={faHistory} />
        </Grid>
        <Grid item>
          <span>
            {fechaGpsFixed} - {horaGpsFixed}{" "}
          </span>
        </Grid>
      </ContainToltip>
      {alerta.mensaje !== "" && (
        <>
          <Divider />
          <ContainToltip container>
            <Grid item style={{ marginRight: "0.688rem" }}>
              <FontAwesomeIcon
                className="icon_update"
                icon={faExclamationTriangle}
              />
            </Grid>
            <Grid item>
              <span>{alerta.mensaje}</span>
            </Grid>
          </ContainToltip>
        </>
      )}
    </>
  );
};

export const Tooltip = ({ placa, fechaGps, horaGps, alerta }) => {
  const [fechaGpsFixed, horaGpsFixed] = ConvertDateTime(fechaGps, horaGps);

  return (
    <>
      <ContainToltip container>
        <Grid item style={{ marginRight: "0.688rem" }}>
          <CarIcon width="34px" />
        </Grid>
        <Grid item>
          <span>{placa}</span>
        </Grid>
      </ContainToltip>
      <Divider />
      <ContainToltip container>
        <Grid item style={{ marginRight: "0.688rem" }}>
          <FontAwesomeIcon className="icon_update" icon={faHistory} />
        </Grid>
        <Grid item>
          <span>
            {fechaGpsFixed} - {horaGpsFixed}{" "}
          </span>
        </Grid>
      </ContainToltip>
      {alerta.mensaje !== "" && (
        <>
          <Divider />
          <ContainToltip container>
            <Grid item style={{ marginRight: "0.688rem" }}>
              <FontAwesomeIcon
                className="icon_update"
                icon={faExclamationTriangle}
              />
            </Grid>
            <Grid item>
              <span>{alerta.mensaje}</span>
            </Grid>
          </ContainToltip>
        </>
      )}
    </>
  );
};

export const TooltipPlanta = ({ nombre, alerta }) => {
  return (
    <>
      <ContainToltip container>
        <Grid item style={{ marginRight: "0.688rem" }}>
          <DestinoIcon width="34px" />
        </Grid>
        <Grid item>
          <span>{nombre}</span>
        </Grid>
      </ContainToltip>
      {alerta.mensaje !== "" && (
        <>
          <Divider />
          <ContainToltip container>
            <Grid item style={{ marginRight: "0.688rem" }}>
              <FontAwesomeIcon
                className="icon_update"
                icon={faExclamationTriangle}
              />
            </Grid>
            <Grid item>
              <span>{alerta.mensaje}</span>
            </Grid>
          </ContainToltip>
        </>
      )}
    </>
  );
};

export const TooltipPad = ({ nombre, alerta }) => {
  return (
    <>
      <ContainToltip container>
        <Grid item>
          <span>{nombre}</span>
        </Grid>
      </ContainToltip>
      {alerta.mensaje !== "" && (
        <>
          <Divider />
          <ContainToltip container>
            <Grid item style={{ marginRight: "0.688rem" }}>
              <FontAwesomeIcon
                className="icon_update"
                icon={faExclamationTriangle}
              />
            </Grid>
            <Grid item>
              <span>{alerta.mensaje}</span>
            </Grid>
          </ContainToltip>
        </>
      )}
    </>
  );
};
