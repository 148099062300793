import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  Head,
  Buton,
  StyledForm,
  Title,
  Subtitle,
  CustomCheckbox,
  FormGroup,
  GridItem,
  ButtonContainer,
} from "./Styles";
import { Grid, FormHelperText } from "@material-ui/core";

/* Iconos */
import { ReactComponent as HomeIcon } from "../../../assets/img/homeIcon.svg";

/* componentes */
import { Inputs } from "../../Input/Inputs";
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";
// import { ModalPlantaApprove } from "../ModalPlantaApprove/ModalPlantaApprove";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { postPlantasHistoricas } from "../../../Services";
import { addHours } from "../../../utils/DateTimeConverter/DateTimeConverter";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  selected_quarries: yup.array().of(
    yup.object().shape({
      quarry_name: yup.string(),
      quarry_id: yup.string(),
      contrato_M100: yup.object().shape({
        toneladas_contratadas: yup
          .number("Debe ser un número")
          .integer("El valor debe ser un número entero")
          .moreThan(-1, "El valor debe ser positivo")
          .required("No puede estar vacío"),
        toneladas_diarias: yup
          .number("Debe ser un número")
          .integer("El valor debe ser un número entero")
          .moreThan(-1, "El valor debe ser positivo")
          .required("No puede estar vacío"),
      }),
      contrato_M3070: yup.object().shape({
        toneladas_contratadas: yup
          .number("Debe ser un número")
          .integer("El valor debe ser un número entero")
          .moreThan(-1, "El valor debe ser positivo")
          .required("No puede estar vacío"),
        toneladas_diarias: yup
          .number("Debe ser un número")
          .integer("El valor debe ser un número entero")
          .moreThan(-1, "El valor debe ser positivo")
          .required("No puede estar vacío"),
      }),
      camiones_M100: yup
        .number("Debe ser un número")
        .integer("El valor debe ser un número entero")
        .moreThan(-1, "El valor debe ser positivo")
        .required("No puede estar vacío"),
      camiones_M3070: yup
        .number("Debe ser un número")
        .integer("El valor debe ser un número entero")
        .moreThan(-1, "El valor debe ser positivo")
        .required("No puede estar vacío"),
    })
  ),
});

export const AsignarPlantaForm = () => {
  let history = useHistory();
  const toHabilitarPlanta = () => {
    history.push("/habilitarPlanta");
  };
  const dispatch = useDispatch();
  const plantasHistoricas = useSelector(
    (state) => state.plantasHistoricas.plantas
  );
  const [selectedCheckboxIndices, setSelectedCheckboxIndices] = useState([]);
  const [validated, setValidated] = useState(true);
  const hoyArgentina = addHours(-3, new Date()).toISOString();
  const { Name, Role, Email } = useSelector((state) => state.dataUser.user);

  function cancelar() {
    history.push("/home");
  }
  const selectedQuarries = plantasHistoricas.filter((planta, index) =>
    selectedCheckboxIndices.includes(index)
  );
  const formik = useFormik({
    initialValues: {
      selected_quarries: plantasHistoricas.map((planta) => ({
        quarry_name: planta.nombre_planta,
        quarry_id: planta.id_planta,
        contrato_M100: {
          toneladas_contratadas: 0,
          toneladas_diarias: 0,
        },
        contrato_M3070: {
          toneladas_contratadas: 0,
          toneladas_diarias: 0,
        },
        camiones_M100: 0,
        camiones_M3070: 0,
      })),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        selected_quarries: selectedQuarries.map((planta) => ({
          quarry_name: planta.nombre_planta,
          quarry_id: planta.id_planta,
          contrato_M100: {
            toneladas_contratadas: values.selected_quarries.find(
              (selectedQuarry) => selectedQuarry.quarry_id === planta.id_planta
            ).contrato_M100.toneladas_contratadas,
            toneladas_diarias: values.selected_quarries.find(
              (selectedQuarry) => selectedQuarry.quarry_id === planta.id_planta
            ).contrato_M100.toneladas_diarias,
          },
          contrato_M3070: {
            toneladas_contratadas: values.selected_quarries.find(
              (selectedQuarry) => selectedQuarry.quarry_id === planta.id_planta
            ).contrato_M3070.toneladas_contratadas,
            toneladas_diarias: values.selected_quarries.find(
              (selectedQuarry) => selectedQuarry.quarry_id === planta.id_planta
            ).contrato_M3070.toneladas_diarias,
          },
          camiones_M100: values.selected_quarries.find(
            (selectedQuarry) => selectedQuarry.quarry_id === planta.id_planta
          ).camiones_M100,
          camiones_M3070: values.selected_quarries.find(
            (selectedQuarry) => selectedQuarry.quarry_id === planta.id_planta
          ).camiones_M3070,
        })),
        user_name: Name,
        user_email: Email,
        user_rol: Role,
        datetime: hoyArgentina,
      };
      // console.log(payload);
      dispatch(postPlantasHistoricas(payload));
    },
  });

  useEffect(() => {
    if (selectedCheckboxIndices.length) {
      setValidated(false);
    } else setValidated(true);
  }, [setValidated, selectedCheckboxIndices]);
  return (
    <Container>
      <Grid item md={12} sm={12}>
        <Head>
          <Buton
            variant="outlined"
            color="primary"
            textArea="Home"
            onClick={() => history.push("/home")}
          >
            <HomeIcon />
          </Buton>
        </Head>
      </Grid>
      <StyledForm onSubmit={formik.handleSubmit}>
        <Grid item xs={12} md={12} sm={12}>
          <Title>Asignar plantas</Title>
        </Grid>
        <Grid container spacing={2}>
          {plantasHistoricas.map((planta, index) => (
            <Grid item xs={12} key={planta.id}>
              <label>
                <CustomCheckbox
                  type="checkbox"
                  name={`selected_quarries[${index}].quarry_name`}
                  className="custom-checkbox"
                  onChange={() => {
                    const selectedIndex =
                      selectedCheckboxIndices.indexOf(index);
                    if (selectedIndex === -1) {
                      setSelectedCheckboxIndices([
                        ...selectedCheckboxIndices,
                        index,
                      ]);
                    } else {
                      setSelectedCheckboxIndices(
                        selectedCheckboxIndices.filter((i) => i !== index)
                      );
                    }
                  }}
                  value={planta.quarry_id}
                />
                <span style={{ marginLeft: "5px" }}>
                  {planta.nombre_planta}
                </span>
              </label>

              {selectedCheckboxIndices.includes(index) && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Subtitle>Arena M100</Subtitle>
                    <Inputs
                      name={`selected_quarries[${index}].contrato_M100.toneladas_diarias`}
                      label="Toneladas diarias"
                      type="number"
                      value={
                        formik.values?.selected_quarries[index]?.contrato_M100
                          .toneladas_diarias
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.selected_quarries &&
                        formik.errors.selected_quarries[index] &&
                        formik.errors.selected_quarries[index].contrato_M100 &&
                        formik.errors.selected_quarries[index].contrato_M100
                          .toneladas_diarias
                      }
                    />
                    <FormHelperText>
                      {formik.errors.selected_quarries &&
                        formik.errors.selected_quarries[index] &&
                        formik.errors.selected_quarries[index].contrato_M100 &&
                        formik.errors.selected_quarries[index].contrato_M100
                          .toneladas_diarias}
                    </FormHelperText>

                    <Inputs
                      name={`selected_quarries[${index}].contrato_M100.toneladas_contratadas`}
                      label="Toneladas contratadas"
                      type="number"
                      value={
                        formik.values?.selected_quarries[index]?.contrato_M100
                          .toneladas_contratadas
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.selected_quarries &&
                        formik.errors.selected_quarries[index] &&
                        formik.errors.selected_quarries[index].contrato_M100 &&
                        formik.errors.selected_quarries[index].contrato_M100
                          .toneladas_contratadas
                      }
                    />
                    <FormHelperText>
                      {formik.errors.selected_quarries &&
                        formik.errors.selected_quarries[index] &&
                        formik.errors.selected_quarries[index].contrato_M100 &&
                        formik.errors.selected_quarries[index].contrato_M100
                          .toneladas_contratadas}
                    </FormHelperText>
                    <Inputs
                      name={`selected_quarries[${index}].camiones_M100`}
                      label="Camiones asignados"
                      type="number"
                      value={
                        formik.values?.selected_quarries[index]?.camiones_M100
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.selected_quarries &&
                        formik.errors.selected_quarries[index] &&
                        formik.errors.selected_quarries[index].camiones_M100
                      }
                    />
                    <FormHelperText>
                      {formik.errors.selected_quarries &&
                        formik.errors.selected_quarries[index] &&
                        formik.errors.selected_quarries[index].camiones_M100}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Subtitle>Arena M3070</Subtitle>
                    <Inputs
                      name={`selected_quarries[${index}].contrato_M3070.toneladas_diarias`}
                      label="Toneladas diarias"
                      type="number"
                      value={
                        formik.values?.selected_quarries[index]?.contrato_M3070
                          .toneladas_diarias
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.selected_quarries &&
                        formik.errors.selected_quarries[index] &&
                        formik.errors.selected_quarries[index].contrato_M3070 &&
                        formik.errors.selected_quarries[index].contrato_M3070
                          .toneladas_diarias
                      }
                    />
                    <FormHelperText>
                      {formik.errors.selected_quarries &&
                        formik.errors.selected_quarries[index] &&
                        formik.errors.selected_quarries[index].contrato_M3070 &&
                        formik.errors.selected_quarries[index].contrato_M3070
                          .toneladas_diarias}
                    </FormHelperText>
                    <Inputs
                      name={`selected_quarries[${index}].contrato_M3070.toneladas_contratadas`}
                      label="Toneladas contratadas"
                      type="number"
                      value={
                        formik.values?.selected_quarries[index]?.contrato_M3070
                          .toneladas_contratadas
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.selected_quarries &&
                        formik.errors.selected_quarries[index] &&
                        formik.errors.selected_quarries[index].contrato_M3070 &&
                        formik.errors.selected_quarries[index].contrato_M3070
                          .toneladas_contratadas
                      }
                    />
                    <FormHelperText>
                      {formik.errors.selected_quarries &&
                        formik.errors.selected_quarries[index] &&
                        formik.errors.selected_quarries[index].contrato_M3070 &&
                        formik.errors.selected_quarries[index].contrato_M3070
                          .toneladas_contratadas}
                    </FormHelperText>
                    <Inputs
                      name={`selected_quarries[${index}].camiones_M3070`}
                      label="Camiones asignados"
                      type="number"
                      value={
                        formik.values?.selected_quarries[index]?.camiones_M3070
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.selected_quarries &&
                        formik.errors.selected_quarries[index] &&
                        formik.errors.selected_quarries[index].camiones_M3070
                      }
                    />
                    <FormHelperText>
                      {formik.errors.selected_quarries &&
                        formik.errors.selected_quarries[index] &&
                        formik.errors.selected_quarries[index].camiones_M3070}
                    </FormHelperText>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>

        <ButtonContainer container align="center">
          <ButtonPrimary
            type="button"
            textArea="Cancelar"
            onClick={cancelar}
            variant="outlined"
            color="primary"
            minwidth="true"
          />
          <ButtonPrimary
            type="button"
            textArea="Nueva planta"
            onClick={() => toHabilitarPlanta()}
            variant="outlined"
            color="primary"
            minwidth="true"
          />
          <ButtonPrimary
            type="submit"
            disabled={validated}
            textArea="Guardar"
            variant="contained"
            color="primary"
            minwidth="true"
          />
        </ButtonContainer>
      </StyledForm>
    </Container>
  );
};
