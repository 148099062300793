import styled from "styled-components";

export const Head = styled.header`
  width: 100%;
  background-color: #4f4184;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;

  & h1 {
    font-size: 1.1rem;
    margin: 0;
    &.tituloApp {
      font-size: 1.5rem;
    }
  }

  @media (min-width: 420px) {
    height: 3.75rem;
    padding: 1.25rem;
  }

  @media (max-width: 420px) {
    padding: 10px;
  }
`;

export const ContainerUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  & svg {
    font-size: 1.5rem;
  }
`;

export const Div = styled.div`
  text-align: end;
  margin-right: 0.75rem;
  & p {
    margin-bottom: -5px;
  }
  & button {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 0.75rem;
    margin: 0;
    padding: 0;
  }
  & button:hover {
    /* color: var(--purplePrimary); */
  }
`;
