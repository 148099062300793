import { GET_INDICADORES, RESET_INDICADORES, GET_PROYECCIONES} from '../constants/actionsTypes';

const initialState = {
  proyeccionCajas: {
   semaforo: [
    {hora: 0, estado: 'bueno'},
    {hora: 0, estado: 'bueno'},
    {hora: 0, estado: 'bueno'},
    {hora: 0, estado: 'bueno'},
   ],
   graficoProyeccion: {
     cajasM100:  [
      {name: '+1H', value: 0},
      {name: '+1H', value: 0},
      {name: '+1H', value: 0},
      {name: '+1H', value: 0 },
     ],

     cajasM3070:  [
      {name: '+0H', value: 0 },
      {name: '+0H', value: 0 },
      {name: '+0H', value: 0 },
      {name: '+0H', value: 0 },
     ]
   }
  },

  Info_de_indicadores: {
    cajas_disponibles: "",
    estado_cajas: {
      pad_llenas: {
        M100: '', 
        M3070: ''
      },
      pad_vacias: "",
      transito_llenas: {
        M100: "", 
        M3070: ""
      },
      transito_vacias: {
        M100: "", 
        M3070: ""
      },
      etapas_cubiertas: "",
      etapas_pad: {
        acumuladas: "", 
        total_etapas_pad: "", 
        etapas_dia: "", 
        inicio_ultima_etapa: "", 
        total_etapas_dia: ""
      },
      nombre_pad: "",
      proyeccion_cajas_horas: {
        estado1: {tiempo: "", estado: ""}, 
        estado2: {tiempo: "", estado: ""},
        estado3: {tiempo: "", estado: ""},
        estado4: {tiempo: "", estado: ""},
        grafico_proyeccion: {}
      },
      tiempo_cubierto: "",
      total_cajas_pad: ""
    }
  }
}

export const indicadoresReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INDICADORES: {
      return { ...state, ...payload };
    }

    case GET_PROYECCIONES: {
      return Object.assign({}, state, {
        proyeccionCajas: payload
      })
    }

    case RESET_INDICADORES: {
      return initialState;
    }
    default:
      return state;
  }
};