import React  from "react";
import ReactDOM from 'react-dom';
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as CarIcon } from "../../../assets/img/truck.svg";
import useAlert from "../../../Hooks/useAlert";
import ButtonPrimary from "../../../Components/ButtonPrimary/ButtonPrimary";
import { resetResponse } from '../../../actions/responseActions';

import {
  Head,
  Div,
  Divider,
  Background,
  ModalWrapper,
  ApproveMessage,
  ContainerButtons,
  IconContainer,
} from "./Styles";



export const HabilitarCamionApprove = () => {
  const { response } = useAlert(5000);
  const dispatch = useDispatch();
  
  return ReactDOM.createPortal (
    response.show ? (
      <Background>
        <ModalWrapper>                              
          <Head>
            <p>Añadir Camión</p>
            <IconContainer>
              <FontAwesomeIcon icon={faTimes} onClick={() => dispatch(resetResponse())}/>
            </IconContainer>
          </Head>

          <ApproveMessage>
            {response.status === 200 && <FontAwesomeIcon icon={faCheckCircle} />}
            <span>
              El camión ha sido agregado correctamente
            </span>
          </ApproveMessage>

          <Divider />
          <Div container>
            <Grid item style={{ marginRight: "0.688rem" }}>
              <CarIcon />
            </Grid>
            <Grid item>
              <p>Placa</p>
              <span>{response?.data?.plate}</span>
            </Grid>
          </Div>
          <Divider />

          <ContainerButtons>
            <ButtonPrimary
              type="primary"
              textArea="Aceptar"
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(resetResponse())
                window.location.reload()
              }}
            />
          </ContainerButtons>
        </ModalWrapper>
      </Background>
    ) : (
      <div/>
    ), 
    document.getElementById('root')
  );
};

/* import React from 'react';
import ReactDOM from 'react-dom';
import useAlert from '../../../Hooks/useAlert';
import { Grid } from "@material-ui/core";
import { useDispatch } from 'react-redux';
import { ReactComponent as Success } from '../../../assets/img/check-circle.svg';
import { ReactComponent as Error } from '../../../assets/img/error-triangle.svg';
import { Background, ModalWrapper, ContainerButtons, Head, Div, Divider, ApproveMessage } from './Styles';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as CarIcon } from "../../../assets/img/truck.svg";

import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";
import { resetResponse } from '../../../actions/responseActions'; 
*/ 

/* Modal de aprobacion cuando todo sucede bien o mal */
/* const ModalCamionApprove = () => {
  const { response } = useAlert();
  const dispatch = useDispatch()
  return ReactDOM.createPortal(
    response.show ? (
      <Background>
        <ModalWrapper>
          {response.status === 200 && <Success />}
          {response.status >= 400 && <Error />}
          <p style={{ textAlign: 'center' }}>si funciono</p>
          <ContainerButtons>
            <ButtonPrimary
              type="primary"
              textArea="Aceptar"
              variant="contained"
              color="primary"
              onClick={() => dispatch(resetResponse())}
            />
          </ContainerButtons>
        </ModalWrapper>
      </Background>
    ) : (
      <div />
    ),
    document.getElementById('root')
  );
};

export default ModalCamionApprove;
*/