import { GET_ETAPAS, RESET_CONSULTAR_ETAPAS, GET_FECHA_ETAPA } from '../constants/actionsTypes';

export const getConsultarEtapa = (payload) => {
  return {
    type: GET_ETAPAS,
    payload
  };
};

export const getFechaEtapa = (payload) => {
  return {
    type: GET_FECHA_ETAPA,
    payload
  };
};

export const resetEtapas = (payload) => (
  {
    type: RESET_CONSULTAR_ETAPAS,
    payload
  }
)