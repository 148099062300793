import { GET_INFO_PLANTAS, RESET_INFO_PLANTAS } from '../constants/actionsTypes'

const initialState = {}

export const infoPlantasReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_INFO_PLANTAS: {
      return { ...state, ...payload }
    }
    case RESET_INFO_PLANTAS: {
      return { plantas: [] }
    }
    default:
      return state
  }
}