import { GET_INFO_PLANTAS, RESET_INFO_PLANTAS } from '../constants/actionsTypes';

export const getInfoPlantas = (payload) => {
  return {
    type: GET_INFO_PLANTAS,
    payload
  };
};

export const resetInfoPlantas = (payload) => (
  {
    type: RESET_INFO_PLANTAS,
    payload
  }
)