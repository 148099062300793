import React, { useEffect } from "react";
import {
  Container,
  ItemContainer,
  ItemContainer2,
  Wrapper,
  Indicador,
  Hr,
  State,
  State2,
  ButtonGraph,
  Item,
  Value,
  Table,
} from "./Styles";

import { ReactComponent as CajasDisponibles } from "../../assets/img/cajasDisponibles.svg";
import { ReactComponent as ListIcon } from "../../assets/img/list.svg";
import { ReactComponent as Clock } from "../../assets/img/LogoClock.svg";
import { ReactComponent as ChartLine } from "../../assets/img/chart-line.svg";

/* Componentes */
import GraficoCajas from "../GraficoCajas/GraficoCajas";
import { useSelector, useDispatch } from "react-redux";
import { sort } from "../../utils";
import { infoPad } from "../../Services";

const Indicadores = ({
  showGraph,
  setShowGraph,
  Role,
  Info_de_indicadores,
}) => {
  // const [open, setOpen] = useState()
  const {
    total_cajas_pad,
    cajas_disponibles,
    etapas_cubiertas,
    tiempo_cubierto,
    // proyeccion_cajas_horas,
    etapas_pad,
    estado_cajas,
    tiempo_en_pad,
    tiempo_ent_sal,
  } = Info_de_indicadores ? Info_de_indicadores : "";
  const { proyeccionCajas } = useSelector((state) => state.indicadores);
  const dispatch = useDispatch();

  const partes_fecha = etapas_pad
    ? etapas_pad?.inicio_ultima_fecha.split("-")
    : ["", "", ""];
  const inicio_ultima_fecha = etapas_pad
    ? new Date(
        partes_fecha[0],
        partes_fecha[1] - 1,
        partes_fecha[2]
      ).toLocaleDateString()
    : "";

  useEffect(() => {
    proyeccionCajas?.semaforo?.sort(sort("hora"))?.reverse();
  }, [proyeccionCajas]);

  useEffect(() => {
    if (Role === "Brent" || "SupplyChain" || "CoordBrent") {
      dispatch(infoPad());
    }
  }, []);

  return (
    <Container container>
      <div className="content_info">
        {Role === "Brent" || "SupplyChain" || "CoordBrent" ? (
          <>
            <h2>Proyección de cajas</h2>

            {/* Cajas disponibles */}
            <ItemContainer>
              <Wrapper>
                <CajasDisponibles />
                <p>Cajas disponibles</p>
              </Wrapper>
              <Indicador>
                {cajas_disponibles} / {total_cajas_pad}
              </Indicador>
            </ItemContainer>

            {/* Etapas Cubiertas */}
            <ItemContainer>
              <Wrapper>
                <ListIcon />
                <p>Etapas cubiertas</p>
              </Wrapper>
              <Indicador mright="true">{etapas_cubiertas}</Indicador>
            </ItemContainer>

            {/* Horas Cubiertas */}
            <ItemContainer>
              <Wrapper>
                <Clock />
                <p>Horas cubiertas</p>
              </Wrapper>
              <Indicador>
                {tiempo_cubierto?.toFixed(2) ? tiempo_cubierto?.toFixed(2) : 0}
                {tiempo_cubierto?.toFixed(2) > 1 ? " Horas" : " Hora"}{" "}
              </Indicador>
            </ItemContainer>

            <Hr />
            <h2>Proyección de horas operativas</h2>
            <ItemContainer2 className="proyeccion">
              {proyeccionCajas?.semaforo.map((item, index) => {
                return (
                  <div>
                    {item.estado === "bueno" && (
                      <State key={index * 1} green greenCard>
                        <span> {item.hora} </span>
                        <span>Horas</span>
                        <span>{item.tipo_arena}</span>
                      </State>
                    )}

                    {item.estado === "regular" && (
                      <State key={index * 2} yellow yellowCard>
                        <span> {item.hora} </span>
                        <span>Horas</span>
                        <span>{item.tipo_arena}</span>
                      </State>
                    )}

                    {item.estado === "malo" && (
                      <State key={index * 2} red redCard>
                        <span> {item.hora} </span>
                        <span>Horas</span>
                        <span>{item.tipo_arena}</span>
                      </State>
                    )}
                  </div>
                );
              })}

              <ButtonGraph onClick={() => setShowGraph(!showGraph)}>
                <ChartLine />
              </ButtonGraph>
            </ItemContainer2>

            <Hr />
            <h2>Tiempo promedio de camiones</h2>
            <ItemContainer2 className="proyeccion2">
              {tiempo_en_pad?.estado === "bueno" && (
                <State2 green greenCard>
                  <span>{tiempo_en_pad?.minutos}</span>
                  <span>min.</span>
                  <span>En Pad</span>
                </State2>
              )}

              {tiempo_en_pad?.estado === "regular" && (
                <State2 yellow yellowCard>
                  <span>{tiempo_en_pad?.minutos}</span>
                  <span>min.</span>
                  <span>En Pad</span>
                </State2>
              )}

              {tiempo_en_pad?.estado === "malo" && (
                <State2 red redCard>
                  <span>{tiempo_en_pad?.minutos}</span>
                  <span>min.</span>
                  <span>En Pad</span>
                </State2>
              )}
              {tiempo_ent_sal?.estado === "bueno" && (
                <State2 green greenCard>
                  <span>{tiempo_ent_sal?.minutos}</span>
                  <span>min.</span>
                  <span>Ritmo de Entrada al Pad</span>
                </State2>
              )}
              {tiempo_ent_sal?.estado === "regular" && (
                <State2 yellow yellowCard>
                  <span>{tiempo_ent_sal?.minutos}</span>
                  <span>min.</span>
                  <span>Ritmo de Entrada al Pad</span>
                </State2>
              )}
              {tiempo_ent_sal?.estado === "malo" && (
                <State2 red redCard>
                  <span>{tiempo_ent_sal?.minutos}</span>
                  <span>min.</span>
                  <span>Ritmo de Entrada al Pad</span>
                </State2>
              )}
            </ItemContainer2>
            <Hr />
            <>
              <h2>Etapas del pad</h2>
              <Item>
                <p>Acumuladas</p>
                <Value>
                  {etapas_pad?.acumuladas} / {etapas_pad?.total_etapas_pad}
                </Value>
              </Item>
              <Item>
                <p>Etapas del día</p>
                <Value>
                  {etapas_pad?.etapas_dia} / {etapas_pad?.etepas_por_dia}
                </Value>
              </Item>
              <Item>
                <p>Fecha inicio última etapa</p>
                <Value>{inicio_ultima_fecha}</Value>
              </Item>
              <Item>
                <p>Hora inicio última etapa</p>
                <Value>{etapas_pad?.inicio_ultima_hora}</Value>
              </Item>
              <Hr mtop />
            </>

            <h2>Estado de cajas</h2>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>M100</th>
                  <th>M3070</th>
                </tr>
              </thead>
              <tbody>
                <tr className="pad-vacias">
                  <td>
                    <b>Pad vacías </b>
                  </td>
                  <td>
                    <b>{estado_cajas?.pad_vacias_m100} </b>
                  </td>
                  <td>
                    <b>{estado_cajas?.pad_vacias_m3070} </b>
                  </td>
                </tr>
                <tr>
                  <td>Tránsito vacías</td>
                  <td>{estado_cajas?.transito_vacias?.M100}</td>
                  <td>{estado_cajas?.transito_vacias?.M3070}</td>
                </tr>
                <tr>
                  <td>Cargando en planta</td>
                  <td>{estado_cajas?.carga_planta?.M100}</td>
                  <td>{estado_cajas?.carga_planta?.M3070}</td>
                </tr>
                <tr>
                  <td>Tránsito llenas</td>
                  <td>{estado_cajas?.transito_llenas?.M100}</td>
                  <td>{estado_cajas?.transito_llenas?.M3070}</td>
                </tr>
                <tr className="pad-llenas">
                  <td>
                    <b>Pad llenas</b>
                  </td>
                  <td>
                    <b>{estado_cajas?.pad_llenas?.M100}</b>
                  </td>
                  <td>
                    <b>{estado_cajas?.pad_llenas?.M3070}</b>
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    {" "}
                    <b>Total cajas</b>
                  </td>
                  <td>
                    <b>
                      {parseInt(estado_cajas?.pad_llenas?.M100) +
                        parseInt(estado_cajas?.transito_llenas?.M100) +
                        parseInt(estado_cajas?.carga_planta?.M100) +
                        parseInt(estado_cajas?.transito_vacias?.M100) +
                        parseInt(estado_cajas?.pad_vacias_m100)}
                    </b>
                  </td>
                  <td>
                    <b>
                      {parseInt(estado_cajas?.pad_llenas?.M3070) +
                        parseInt(estado_cajas?.transito_llenas?.M3070) +
                        parseInt(estado_cajas?.carga_planta?.M3070) +
                        parseInt(estado_cajas?.transito_vacias?.M3070) +
                        parseInt(estado_cajas?.pad_vacias_m3070)}
                    </b>
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : null}
      </div>
      {showGraph && (
        <GraficoCajas
          setShowGraph={setShowGraph}
          showGraph={showGraph}
          data={proyeccionCajas?.graficoProyeccion}
        />
      )}
    </Container>
  );
};

export default Indicadores;
