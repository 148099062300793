import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

/* Componentes */
import Indicadores from "../Components/Indicadores/Indicadores";
import Header from "../Components/Header/Header";
import HeaderPad from "../Components/HeaderPad/HeaderPad";
import { EditarPlantaForm } from "../Components/Plantas/EditarPlantaForm/EditarPlantaForm";
import ModalApprove from "../utils/ModalApprove/ModalApprove";
import { ModalEtapasDias } from "../Components/Etapas/ModalEtapasDia/ModalEtapasDias";
import { ModalCajasDisponiblesPad } from "../Components/ModalCajasDisponiblesPad/ModalCajasDisponiblesPad";
import { ModalModificarCajasPad } from "../Components/ModalModificarCajasPad/ModalModificarCajasPad";
import { ModalAltaPad } from "../Components/ModalAltaPad/ModalAltaPad";
import { ModalEditarPad } from "../Components/ModalEditarPad/ModalEditarPad";
import { ModalFinalizarPad } from "../Components/ModalFinalizarPad/ModalFinalizarPad";
import { ModalReentrenamiento } from "../Components/ModalReentrenamiento/ModalReentrenamiento";
import FullPageLoader from "../utils/FullPageLoader/FullPageLoader";
import { Sidebar } from "../Components/Sidebar/Sidebar";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  @media (max-width: 768px) {
    overflow: auto;
    display: block;
  }
`;
const Home = styled(Grid)`
  position: relative;
`;

export const EditaPlanta = () => {
  const [showModalEtapasDia, setShowModalEtapasDias] = useState(false);
  const [showModalCajasPad, setShowModalCajasPad] = useState(false);
  const [showModalModificarCajasPad, setShowModalModificarCajasPad] =
    useState(false);
  const [showModalAltaPad, setShowModalAltaPad] = useState(false);
  const [showModalEditarPad, setShowModalEditarPad] = useState(false);
  const [showModalFinalizarPad, setShowModalFinalizarPad] = useState(false);
  const [showModalReentrenamiento, setShowModalReentrenamiento] =
    useState(false);
  // const [showModalEstadoRTForm, setShowModalEstadoRTForm] = useState(false);
  const isLoading = useSelector((state) => state.loading);

  const { Info_de_indicadores } = useSelector((state) => state.indicadores);
  const { Role } = useSelector((state) => state.dataUser.user);
  const [showGraph, setShowGraph] = useState(false);
  const history = useHistory();

  const openModalToggleEtapasDia = () => {
    setShowModalEtapasDias(!showModalEtapasDia);
  };

  const openModalToggleCajasPad = () => {
    setShowModalCajasPad(!showModalCajasPad);
  };

  const openModalToggleModificarCajasPad = () => {
    setShowModalModificarCajasPad(!showModalModificarCajasPad);
  };
  const openModalToggleAltaPad = () => {
    setShowModalAltaPad(!showModalAltaPad);
  };

  const openModalToggleEditarPad = () => {
    setShowModalEditarPad(!showModalEditarPad);
  };

  const openModalToggleFinalizarPad = () => {
    setShowModalFinalizarPad(!showModalFinalizarPad);
  };
  // const openModalToggleEstadoRTForm = () => {
  //   setShowModalEstadoRTForm(!showModalEstadoRTForm);
  // };
  const openModalToggleReentrenamiento = () => {
    setShowModalReentrenamiento(!showModalReentrenamiento);
  };
  const toEscenariosProyeccion = () => {
    history.push("/escenariosProyeccion");
  };

  return (
    <Home container>
      <Grid item xs={12} md={3} sm={3}>
        <Sidebar />
        <HeaderPad
          setShowModalEtapasDias={setShowModalEtapasDias}
          setShowModalCajasPad={setShowModalCajasPad}
          setShowModalModificarCajasPad={setShowModalModificarCajasPad}
          setShowModalAltaPad={setShowModalAltaPad}
          setShowModalEditarPad={setShowModalEditarPad}
          setShowModalFinalizarPad={setShowModalFinalizarPad}
          setShowModalReentrenamiento={setShowModalReentrenamiento}
          toEscenariosProyeccion={toEscenariosProyeccion}
        />
      </Grid>
      <Grid item xs={12} md={9} sm={9}>
        <Header />
      </Grid>
      <Container>
        <Grid item xs={12} md={3}>
          <Indicadores
            showGraph={showGraph}
            setShowGraph={setShowGraph}
            Role={Role}
            Info_de_indicadores={Info_de_indicadores}
          />
        </Grid>
        <Grid item xs={12} md={9} sm={12}>
          <EditarPlantaForm />
        </Grid>
      </Container>
      {/* Modal generico */}
      <ModalApprove />
      <FullPageLoader isLoading={isLoading} />
      {showModalEtapasDia && (
        <ModalEtapasDias onClose={openModalToggleEtapasDia} />
      )}
      {showModalCajasPad && (
        <ModalCajasDisponiblesPad onClose={openModalToggleCajasPad} />
      )}
      {showModalModificarCajasPad && (
        <ModalModificarCajasPad onClose={openModalToggleModificarCajasPad} />
      )}
      {showModalAltaPad && <ModalAltaPad onClose={openModalToggleAltaPad} />}

      {showModalEditarPad && (
        <ModalEditarPad onClose={openModalToggleEditarPad} />
      )}
      {showModalFinalizarPad && (
        <ModalFinalizarPad onClose={openModalToggleFinalizarPad} />
      )}
      {showModalReentrenamiento && (
        <ModalReentrenamiento onClose={openModalToggleReentrenamiento} />
      )}

      {/* {showModalEstadoRTForm && (
        <showModalEstadoRTForm onClose={openModalToggleEstadoRTForm} />
      )} */}
    </Home>
  );
};
