import React from "react";
import { Grid, FormHelperText } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  StyledForm,
  Head,
  FormGroup,
  ButtonContainer,
  CloseLogo,
} from "./Styles";


/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import { Inputs } from "../../../Input/Inputs";
import ButtonPrimary from "../../../ButtonPrimary/ButtonPrimary";
import { recomendadorPost } from "../../../../Services";

/* dependencias */
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  codigo_1: Yup.string(),
  codigo_2: Yup.string()
});



export const CargarCajas = ({ onClose, type, formValue, data }) => {
  // const [showInput, setShowInput] = useState(false);
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      codigo_1: "",
      codigo_2: "",
    },

    validationSchema: validationSchema,
  });

  const handleSubmit = (event) => {
    dispatch(recomendadorPost({
      placa: data.placa,
      tipo_arena: formValue?.tipo_arena?.toLowerCase() || data?.tipo_arena?.toLowerCase(),
      destino: formValue.destino || data.destino,
      motivo_cambio: formValue.motivo_cambio ? formValue.motivo_cambio : 'sin motivo',
      hora_salida: `${data?.fecha}T${data?.hora}`,
      fecha: `${data?.fecha}T${data?.hora}`,
      cajas: `${formik.values.codigo_1}-${formik.values.codigo_2}`,
      estado_recomendacion: true
    }))

    onClose();
  }

  return (
    <>
      <Head>
        <p>Recomendación sugerida (agregar cajas) </p>
        <CloseLogo onClick={onClose} />
      </Head>
      <StyledForm>
        <Grid item md={12}>
          <FormGroup>
            <Inputs
              required
              autoComplete="off"
              name="codigo_1"
              label="Ingrese el codigo de la caja 1"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.codigo_1}
              error={!!formik.errors.codigo_1}
    
            />
            <FormHelperText>{formik.errors.codigo_1}</FormHelperText>
          </FormGroup>
        </Grid>
        <Grid item md={12}>
          <FormGroup>
            <Inputs
              required
              autoComplete="off"
              name="codigo_2"
              label="Ingrese el codigo de la caja 2"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.codigo_2}
              error={!!formik.errors.codigo_2}
              
            />
            <FormHelperText>{formik.errors.codigo_2}</FormHelperText>
          </FormGroup>
        </Grid>
        <ButtonContainer align="center">
          <ButtonPrimary
            type="button"
            textArea="cancelar" /* Acà se puede validar el tipo de boton */
            variant="outlined"
            color="primary"
            minwidth="true"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
          </ButtonPrimary>
          <ButtonPrimary
            textArea="Guardar" /* Acà se puede validar el tipo de boton */
            variant="contained"
            disabled={!formik.dirty}
            color="primary"
            mright="true"
            onClick={handleSubmit}
          />
        </ButtonContainer>
      </StyledForm>
    </>
  );
};
