import styled from "styled-components"
import { Col } from 'react-bootstrap';

export const Div = styled.div`
  margin: 50px auto 0 auto;

  width: 80%;
  /* margin-top: 2.5rem; */
`
export const SpanTitle = styled.span`
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
`

export const Column = styled(Col)`
  padding: 0;
  display: flex;
  align-items: center;
  & svg {
    width: 50px;
  }
`

export const ButtonIconMap = styled.button`
  background: none;
  border: none;
`

export const Toltip = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 0.3125rem;
  border: 0.0625rem solid #e5e5e5;
  box-shadow: 0rem 1.25rem 2.5rem -0.75rem rgba(196, 196, 196, 0.35);
  box-sizing: border-box;
  padding: 0.563rem 0.375rem;
  width: auto;
`

export const InfoWindow = styled.div`
  position: relative;
  bottom: 150;
  left: -45px;
  width: 220;
  background-color: white;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  padding: 10;
  font-size: 14;
  z-index: 100;
`

export const Box = styled.div`
  .gm-style-iw.gm-style-iw-c {
    padding: 0;
    .gm-style-iw-d {
      overflow: hidden !important;
    }
  }
  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    top: 0;
  }
`