import React, { useState } from "react";
import { ConvertDateTime } from "../../../utils/DateTimeConverter/DateTimeConverter";

import {
  Acordeon,
  Head,
  Div,
  Divider,
  IconArrow,
  TitlePriority,
  Panel,
  IconContainer,
  Title,
  PanelHead,
  PanelText,
} from "./Styles";

import "./style.css";

import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export const CardAlert = ({ highAlert, data }) => {
  const [open, setOpen] = useState(false);
  const [fecha, hora] = ConvertDateTime(data?.fecha, data?.hora);

  return (
    <>
      <Acordeon onClick={() => setOpen(!open)} border={highAlert}>
        <Head>
          <Div>
            <IconContainer color={highAlert}>
              <IconArrow icon={open ? faChevronDown : faChevronRight} />
            </IconContainer>
            <Title> {data?.tipoAlerta} </Title>
          </Div>
          <TitlePriority colorTitle={highAlert}> {data?.tipo} </TitlePriority>
        </Head>
        {open && (
          <>
            <Divider color={highAlert} />
            <Panel>
              <PanelText>
                {data?.placa && (
                  <p>
                    {" "}
                    Placa: <span> {data?.placa} </span>
                  </p>
                )}
                <p>
                  {" "}
                  Dia: <span> {fecha} </span>
                </p>
              </PanelText>
              <PanelText>
                <p>
                  {" "}
                  Hora: <span> {hora} </span>
                </p>
              </PanelText>
              <PanelText>
                <p>
                  {" "}
                  Mensaje: <span> {data?.mensaje} </span>{" "}
                </p>
              </PanelText>
            </Panel>
          </>
        )}
      </Acordeon>
    </>
  );
};
