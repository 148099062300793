import { SIDEBAR_TOGGLE } from "../constants/actionsTypes";

const INITIAL_STATE = {
  isExpanded: false,
  isMobile: false,
};

export const SidebarReducer = (state = INITIAL_STATE, action) => {
  const { type } = action;
  switch (type) {
    case SIDEBAR_TOGGLE:
      return {
        ...state,
        isExpanded: !state.isExpanded,
      };
    default:
      return state;
  }
};
