import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import {
  Head,
  StyledFormm,
  FormGroup,
  ButtonContainer,
  Div,
  Container
} from "./Styles";
import { Grid, MenuItem, Select, InputLabel, FormHelperText } from "@material-ui/core";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
// import { dispatch } from "d3";
import { modificarTrayecto } from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  destino: yup.string(),
  tipo_arena: yup.string()
});

export const ModificarTrayectoForm = ({ setApprove, setShowForm }) => {
  let history = useHistory();
  // let location = useLocation();
  const dispatch = useDispatch()
  const plantas = useSelector(state => state.plantas.plantas)
  const dataTrayecto = useSelector(state =>  state.plantas.currentData)
  // const placa = location.pathname.split(":" , 2)
  const [validated, setValidated] = useState(true)

  function cancelar() {
    history.push("/editarTrayecto");
  }

  const formik = useFormik({
    initialValues: {
      placa: dataTrayecto.placa,
      destino: dataTrayecto.destino,
      tipo_arena: dataTrayecto.tipo_arena.toUpperCase(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(modificarTrayecto({ ...values, tipo_arena: values.tipo_arena.toLowerCase() }));
    },
  })

  useEffect(() => {
    if (formik.values.tipo_arena && formik.values.destino) {
      setValidated(false)
    } else setValidated(true)
  }, [formik, setValidated])

  return (
    <Div>
      <Container>
        <Head>
          <p>Modificar Trayecto</p>
        </Head>
        <span>Se encuentra modificando el trayecto del camión <b>{dataTrayecto.placa}</b></span>
        <StyledFormm onSubmit={formik.handleSubmit}>
          <FormGroup>
            <InputLabel id="demo-simple-select-label">Destino</InputLabel>
            <Select
              labelId="Destino"
              id="destino"
              name="destino"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.destino}
              error={!!formik.errors.destino}
            >
              <MenuItem value="">Seleccionar un destino</MenuItem>
              {plantas &&
                plantas.map((planta, index) => (
                  <MenuItem key={index} value={`${planta.nombre_planta}`}>{planta.nombre_planta}</MenuItem>
                ) )
              }
            </Select>
            <FormHelperText>{formik.errors.destino}</FormHelperText>
          </FormGroup>
          <FormGroup>
            <InputLabel id="demo-simple-select-label">Tipo de arena</InputLabel>
            <Select
              labelId="Tipo de arena"
              id="tipo_arena"
              name="tipo_arena"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.tipo_arena}
              error={!!formik.errors.tipo_arena}
            >
              <MenuItem value="">Selecciona un tipo de arena</MenuItem>
              <MenuItem value={"M100"}>M100</MenuItem>
              <MenuItem value={"M3070"}>M3070</MenuItem>
            </Select>
            <FormHelperText>{formik.errors.tipo_arena}</FormHelperText>
          </FormGroup>
          <ButtonContainer container align="center">
            <Grid>
              <ButtonPrimary
                textArea="Cancelar"
                variant="outlined"
                color="primary"
                mright="true"
                onClick={cancelar}
              />
            </Grid>
            {/* Toca validar el formulario completo jeje aun falta */}
            <Grid>
              <ButtonPrimary
                type='submit'
                textArea="Guardar"
                disabled={validated}
                variant="contained"
                color="primary"
                // onClick={(event) => handleFormClick(event)}
              />
            </Grid>
          </ButtonContainer>
        </StyledFormm>
      </Container>
    </Div>
  );

};