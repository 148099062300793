import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid, FormHelperText } from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";
// import HabilitarCamionApprove from '../HabilitarCamionApprove/HabilitarCamionApprove';

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { camionesInactivos, habilitarCamion } from "../../../Services";
import { resetCamionesInactivos } from "../../../actions/camionesInactivosActions";
import { Inputs } from "../../Input/Inputs";
import { addHours } from "../../../utils/DateTimeConverter/DateTimeConverter";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  placa: yup.string().required("Este campo es requerido"),
  // .matches(/^([A-Z]){2}-([0-9]){3}-([A-Z]){2}$|^([A-Z]){3}-([0-9]){3}|^([A-Z]){3}-([0-9]){5}$/g , 'Placa no valida')
});

export const ModalHabilitarNuevoCamion = ({ onClose }) => {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(true);
  const hoyArgentina = addHours(-3, new Date()).toISOString();
  const { Name, Role, Email } = useSelector((state) => state.dataUser.user);
  const formik = useFormik({
    initialValues: {
      placa: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        habilitarCamion({
          ...values,
          user_name: Name,
          user_email: Email,
          user_rol: Role,
          datetime: hoyArgentina,
        })
      );
      onClose();
    },
  });

  const handleCloseModal = () => {
    onClose();
    dispatch(resetCamionesInactivos());
  };

  useEffect(() => {
    dispatch(camionesInactivos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik.values.placa !== "") {
      setValidated(false);
    } else setValidated(true);
  }, [formik, setValidated]);

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleCloseModal()}>
        <Head>
          <p>Habilitar nuevo camión</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid item md={12}>
            <FormGroup>
              <Inputs
                type="text"
                labelId="Destino"
                id="placa"
                name="placa"
                label="Ingrese placa*"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.placa}
                error={formik.errors.placa}
              />
              <FormHelperText>{formik.errors.placa}</FormHelperText>
            </FormGroup>
          </Grid>
          <ButtonContainer align="center">
            <Grid>
              <ButtonPrimary
                textArea="Cancelar"
                /* onClick={} */
                variant="outlined"
                color="primary"
                mright="true"
                onClick={() => handleCloseModal()}
              />
            </Grid>
            <Grid>
              <ButtonPrimary
                disabled={validated}
                type="submit"
                textArea="Guardar" /* Acà se puede validar el tipo de boton */
                variant="contained"
                color="primary"
                mright="true"
              />
            </Grid>
          </ButtonContainer>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
