import { setResponse, resetResponse } from '../actions/responseActions';

/* Valida cuando el llamado es succes y arroja un mensaje personalizado */
export const validateSuccess = (response, rute, type) => (dispatch) => {
  const { status, data } = response;
  const show = true;
  switch (status) {
    case 200: {
      dispatch(
        setResponse({
          status: 200,
          data: data,
          type: type || '',
          message: data.msg ? data.msg : 'Los cambios se han guardado satisfactoriamente.',
          show,
          redirect: rute
        })
      );
      break;
    }

    default:
      dispatch(resetResponse());
      break;
  }
};
