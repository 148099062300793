import {
  GET_INFO_FACTURACION_CLS,
  RESET_INFO_FACTURACION_CLS,
} from "../../constants/actionsTypes";

export const getPeriodoFacturacionCls = (payload) => {
  return {
    type: GET_INFO_FACTURACION_CLS,
    payload,
  };
};

export const resetPeriodoFacturacionCls = (payload) => ({
  type: RESET_INFO_FACTURACION_CLS,
  payload,
});
