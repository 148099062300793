import {
  GET_ALERTAS_CLS,
  RESET_ALERTAS_CLS,
  CURRENT_DESCARGAR_CLS,
  SET_CURRENT_TAGS_CLS,
} from "../../constants/actionsTypes";

const initialState = {
  alertas: {
    alertasBajas: [],
    alertasMedias: [],
    alertasAltas: [],
  },

  currentTags: "todos",
  currentDescarga: "",
};

export const alertasClsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALERTAS_CLS: {
      return { ...state, alertas: payload };
    }

    case CURRENT_DESCARGAR_CLS: {
      return { ...state, currentDescarga: payload };
    }

    case SET_CURRENT_TAGS_CLS: {
      return { ...state, currentTags: payload };
    }

    case RESET_ALERTAS_CLS: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
